import React, { useContext } from 'react'
import { SubOrgContext } from 'context/SubOrganizationContext';
import { DashboardCard, WorkflowRequired } from 'pages/Dashboard/AssistantsDashboard';
import { PageContent, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Button } from 'components/ui/button';

export default function AssistantsGallery() {

    const { workflow } = useContext(SubOrgContext)

    if (!workflow) {
        return <WorkflowRequired />;
    }

    return (
        <PageLayout>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Build your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Agentic Workforce
                    </PageSubTitle>
                    <PageDescription>
                        Empower your business with AI-driven assistants that work alongside your team. Automate tasks, enhance decision-making, and drive productivity with a workforce built for the future.
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button disabled={true} className='w-full'> Clone an Assistant </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent>
                <div className="mx-auto p-2 md:p-6 grid grid-cols-1 sm:grid-cols-2 lg-menu:grid-cols-3 gap-4">
                    {workflow.workflowType?.assistants?.map((item) => (
                        <DashboardCard key={item._id} {...item} />
                    ))}
                </div>
            </PageContent>
        </PageLayout>
    )
}
