import {Switch} from 'components/ui/switch';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Context } from 'context/GlobalState';
import { EyeIcon, EyeOffIcon, Upload, X } from 'lucide-react';
import React, { useContext, useState, useCallback } from 'react'
import { Controller } from 'react-hook-form';

const FilesUploading = ({setValue, control, watchedValues, requiredSpan}) => {
    const [fileStates, setFileStates] = useState({});
    const [dragActive, setDragActive] = useState(false);
    const {addNewNotifcation} = useContext(Context)
    
    const handleDeleteFile = (index) => {
        const updatedFiles = [...watchedValues.files];
        const fileDeleted = updatedFiles.find((file, i) => i === index)
        const newFiles = watchedValues.presignedUrls.filter(file=> file.name !== fileDeleted.name)
        updatedFiles.splice(index, 1); // Remove the selected file
        setValue("files", updatedFiles); // Update only the `files` field
        setValue("presignedUrls", newFiles); // Update only the `presignedUrls` field
    };

    const handleFilesChange = (newFiles, field) => {
        const currentFiles = field.value || []; // Get current files or initialize as an empty array
        // Find files with names that already exist
        const duplicateFiles = newFiles.filter((newFile) =>
          currentFiles.some((currentFile) => currentFile.name === newFile.name)
        );

        // Display a message if there are duplicate files
        if (duplicateFiles.length > 0) {
          const duplicateFileNames = duplicateFiles.map((file) => file.name).join(", ");
          addNewNotifcation(`The following files already exist: ${duplicateFileNames}`, "warning");
        }

        // Add only unique new files
        const uniqueNewFiles = newFiles.filter(
          (newFile) =>
            !currentFiles.some((currentFile) => currentFile.name === newFile.name)
        );

        const updatedFiles = [...currentFiles, ...uniqueNewFiles];

        field.onChange(updatedFiles);
    }
    
    const toggleViewer = (index) => {
        setFileStates((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const isPdfFile = (file) => {
        return file.name.endsWith('.pdf');
    };

    const isImageFile = (file) => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
        return imageExtensions.some((ext) => file.name.toLowerCase().endsWith(`.${ext}`));
    };

    const isWordFile = (file) => {
        const wordExtensions = ['doc', 'docx'];
        return wordExtensions.some((ext) => file.name.toLowerCase().endsWith(`.${ext}`));
    };

    const isExcelFile = (file) => {
        const excelExtensions = ['xls', 'xlsx'];
        return excelExtensions.some((ext) => file.name.toLowerCase().endsWith(`.${ext}`));
    };

    const getIframeSource = (file) => {
        if (!file) return '';
        const fileUrl = file?.URL || URL?.createObjectURL(file);

        if (isPdfFile(file)) {
            return fileUrl;
        }

        if (isWordFile(file) || isExcelFile(file)) {
            return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;
        }

        return '';
    };

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    }, []);

    const handleDrop = useCallback((e, field) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFilesChange(Array.from(e.dataTransfer.files), field);
        }
    }, [handleFilesChange]);

    return (
        <div>
            <Label htmlFor="">Dataset {requiredSpan()}</Label>
            <Controller
                name="files"
                control={control}
                render={({ field }) => (
                    <div
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={(e) => handleDrop(e, field)}
                    >
                        <Label
                            htmlFor="files"
                            className={`flex flex-col items-center justify-center w-full h-24 border-2 border-dashed rounded-lg cursor-pointer ${
                                dragActive
                                    ? "border-primary bg-primary/10"
                                    : "border-gray-300 bg-gray-50 hover:bg-gray-100"
                            }`}
                        >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <Upload className='mb-2 text-sm text-gray-500'/>
                                <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                </p>
                                {/* <p className="text-xs text-gray-500">CSV, JSON, or TXT (MAX. 10MB)</p> */}
                            </div>
                        </Label>
                        <Input
                            id="files"
                            type="file"
                            className='hidden'
                            multiple
                            onChange={(e) => handleFilesChange(Array.from(e.target.files), field)}
                        />
                    </div>
                )}
            />

            {watchedValues.files.length > 0 && (
                <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Selected files:</h4>
                    <ul className="space-y-2">
                        {watchedValues.files.map((file, index) => (
                            <React.Fragment key={index}>
                                <li className="flex items-center justify-between p-2 bg-gray-100 rounded">
                                    <div className="flex items-center gap-3">
                                        <div className="flex items-center space-x-2">
                                            <Switch
                                                onCheckedChange={() => toggleViewer(index)}
                                                checked={fileStates[index] || false}
                                            />
                                            {fileStates[index] ? (
                                                <EyeIcon className="h-4 w-4 text-gray-500" />
                                            ) : (
                                                <EyeOffIcon className="h-4 w-4 text-gray-500" />
                                            )}
                                        </div>
                                        <span className="text-sm text-gray-700 truncate">{file.name}</span>
                                    </div>
                                    <button
                                    type="button"
                                    onClick={() => handleDeleteFile(index)}
                                    className="text-red-500 hover:text-red-700"
                                    >
                                        <X size={16} />
                                    </button>
                                </li>
                                {fileStates[index] && (
                                    <div>
                                        {isImageFile(file) && (
                                            <img
                                                src={file?.URL || URL.createObjectURL(file)}
                                                alt={file.name}
                                                className="w-full h-auto rounded"
                                            />
                                        )}
                                        {!isImageFile(file) && (
                                            <iframe
                                                src={getIframeSource(file)}
                                                frameBorder="0"
                                                className="w-full h-[400px]"
                                            ></iframe>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
export default FilesUploading

