import axios from "axios";

const REACT_ENV = process.env.REACT_APP_MODE || 'development'
console.log('react env: ', REACT_ENV);

export const server = `${process.env.REACT_APP_BEX_SERVER_URL}/cm`

export const serverO =  process.env.REACT_APP_PROJECT_O_SERVER_URL

export const serverY = REACT_ENV === 'development' ? 'http://localhost:3000' : 'https://bex.coolriots.ai'
export const serverX = REACT_ENV === 'development' ? 'http://localhost:3002' : 'https://bex.coolriots.ai'

export const httpRequest = axios.create({
    withCredentials: true,
    baseURL: server
});

export const httpRequestO = axios.create({
    withCredentials: true,
    baseURL: serverO
});

export const httpRequestY = axios.create({
    withCredentials: true,
    baseURL: serverY
});
export const httpRequestX = axios.create({
    withCredentials: true,
    baseURL: serverX
});

export const fastApiCall = async (url, method, bodyObj = {}, headers = {}) => {
  try {
    const res = await httpRequest.post(
      "/bex-platform/opcodes-request",
      {
        url: `${serverO}${url}`,
        method: method,
        bodyObj: bodyObj,
      },
      { headers: headers }
    );
    return res;
  } catch (err) {
    console.log("error: ", err);
    throw new Error(err);
  }
};

