import React from 'react';
import { HOProvider } from './HotelOpsContext';
import HotelOpDemo from './Components/HotelOpDemo';
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout';

export default function HrAssistant() {

    return (
        <NestedPageLayout>
            <HOProvider>
                <HotelOpDemo/>
            </HOProvider>
        </NestedPageLayout>
    )
}