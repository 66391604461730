import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/textarea'
import React from 'react'
import StepIdSelector from './StepIdSelector'

const StepCondition = ({index, inputGroup, inputGroups, setInputGroups, getNextSteps}) => {

    const handleExpressionChange = (e) => {
        console.log(e.target.value)
        setInputGroups(inputGroups.map((group, i) => i === index? {...group, fixed_inputs:{expression: e.target.value}} : group ))
    }

    return (
        <div className='space-y-2'>
            <div>
                <Label htmlFor="expression">Expression</Label>
                <Textarea
                id="expression"
                placeholder="Enter condition expression (e.g., len(output['results']) > 5)"
                value={inputGroup.fixed_inputs.expression}
                className='bg-white'
                onChange={handleExpressionChange}
                />
            </div>

            <StepIdSelector
                label="True Branch"
                isTrue={true}
                selectedSteps={inputGroup.next_step.true_branch}
                availableSteps={getNextSteps(index)}
                index={index}
                inputGroup={inputGroup}
                // onChange={}
                setInputGroups={setInputGroups}
            />

            <StepIdSelector
                label="False Branch"
                isTrue={false}
                selectedSteps={inputGroup.next_step.false_branch}
                availableSteps={getNextSteps(index)}
                index={index}
                inputGroup={inputGroup}
                // onChange={}
                setInputGroups={setInputGroups}
                
            />
        </div>
    )
}   

export default StepCondition