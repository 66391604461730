import { MdGroups2, MdOutlineAccountCircle, MdSettings, MdPayment } from 'react-icons/md'
import { FaUsersLine } from 'react-icons/fa6'
import { FaEnvelopeOpenText, FaHotel, FaUsersCog } from 'react-icons/fa'
import { IoCheckmarkDoneCircleSharp } from 'react-icons/io5'
import { GoOrganization } from 'react-icons/go'
import { FileClock } from 'lucide-react'

export const settingsUsersList = (adminRole) => {
    const data = [
        { name: 'Dashboard', path: '/secure/dashboard', icon: <MdSettings /> },
        { name: 'Manage Profile', path: '/secure/profile', icon: <MdSettings /> },
        { name: 'Identity Access Management', path: '/secure/iam', icon: <MdGroups2 /> },

    ];

    // switch (adminRole.name){
    //     case "superadmin":
    //         data.push(
    //             { name: 'Admins', path: '/secure/admins', icon: <FaUsersLine /> }
    //         );
    //     case "admin":
    //         data.push(
    //             { name: 'Users', path: '/secure/users', icon: <FaUsersLine /> }
    //         );
    //     case "editor":
    //         break;
    //     default:
    //         break;
    // }

    return data
}

export const settingsPermissionsList = (adminRole) =>{
    const data = [
        { name: 'Identity Access Management', path: 'secure/iam', icon: <MdGroups2 />, nested: true, nestedlist:
            [
                
            ]
        },
    ]

    switch (adminRole.name){
        case "superadmin":
            data[0].nestedlist.push(
                
                { name: 'Admins', path: '/secure/permissions/admins', icon: <FaUsersLine /> }
            );
        case "admin":
            data[0].nestedlist.push(
                { name: 'Users', path: '/secure/permissions/users', icon: <FaUsersLine /> }
            );
        default:
            break;
    }
    return data;
}

export const settingsAdminAppsList = () => {
    const data = [
        { name: 'Email Extraction', path: '/secure/email', icon: <MdGroups2 />, nested: true, nestedlist: [
            { name: 'Hotels', path: '/secure/email/hotels', icon: <FaHotel /> },
        ] },
        { name: 'Smart Contract', path: '/secure/smart', icon: <FaEnvelopeOpenText />, nested: true, nestedlist: [] },
        { name: 'Legal Contract', path: '/secure/legal', icon: <IoCheckmarkDoneCircleSharp />, nested: true, nestedlist: [] },
    ]

    return data
}

export const settingsAdminUsersList = () => {
    const data = [
        { name: 'Users', path: '/secure/users', icon: <FaUsersCog /> },
        { name: 'Activity Logs', path: '/secure/activity-logs', icon: <FileClock /> },
        { name: 'Organizations', path: '/secure/organizations', icon: <GoOrganization /> },
    ]

    return data
}

export const settingsSubscriptionsList = () => {
    const data = [
        { name: 'Assistants', path: '/secure/assistants', icon: <MdPayment /> },
        { name: 'Workflow Types', path: '/secure/workflow-types', icon: <MdPayment /> }
    ]
    return data
}
// export const settingsOrgList = (organization)  => {
    
//     const data = [
//         { name: 'Organization', path: organization ? `/settings/organizations/organization/${organization}` : '/organizations', icon: <MdSettings /> },
//         { name: 'Users', path: organization? `/settings/organizations/organization/${organization}/users` : '/organizations', icon: <FaUsersLine /> },
//     ]

//     return data
// }

// export const settingsUsersList = (organization) => {
//     const data = [
//         { name: 'Admin', path: organization ? `/settings/organizations/organization/${organization}/config/email-extraction` : '/organizations', icon: <MdGroups2 />, nested: true, nestedlist:
//         [
//             { name: 'Hotels', path: organization ? `/settings/organizations/organization/${organization}/config/email-extraction/hotels`: '/organizations', icon: <FaHotel /> },
//         ] },
//         { name: 'Smart Contract', path: organization ? `/settings/organizations/organization/${organization}/config/smart-contract` : '/organizations', icon: <FaEnvelopeOpenText />, nested: true, nestedlist: [] },
//         { name: 'Legal Contract', path: organization ? `/settings/organizations/organization/${organization}/config/legal-contract` : '/organizations', icon: <IoCheckmarkDoneCircleSharp />, nested: true, nestedlist: [] },
//     ]

//     return data
// }