import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import React, { useEffect } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";


export default function CollectionForm({ form, setForm, isMilvusActivated, initialData=null }) {

    const SEARCH_TYPES = ['jina', 'openAI']
    const VECTOR_STORE_TYPES = ['MongoDB', 'Milvus']
    const DIMENSIONS_TYPES = {MongoDB: [1024], Milvus: [1024, 256]}

    const checkStoreTypeDisabled = (item) => {
        if (item === 'MongoDB') return false
        
        if (!isMilvusActivated) return true
    }

    useEffect(() => {
        if (initialData && DIMENSIONS_TYPES[form.vectorStoreType].includes(initialData.dimensions)) {
            setForm(prev => ({...prev, dimensions: initialData.dimensions}))
        } else {
            setForm(prev => ({...prev, dimensions: DIMENSIONS_TYPES[form.vectorStoreType][0]}))
        }
    }, [form.vectorStoreType])

    return (
        <div className="space-y-8 pt-4">
            <div className="space-y-3">
                <div>
                    <Label htmlFor='collection-name'> Name </Label>
                    <Input type='text' id='collection-name' value={form.name}
                    onChange={(e) => setForm(prev => ({...prev, name: e.target.value}))}
                    />
                </div>
                <div>
                    <Label htmlFor='vector-store-type'> Vector store type </Label>
                    <Select onValueChange={(value) => setForm(prev => ({...prev, vectorStoreType: value}))} id="vector-store-type" value={form.vectorStoreType}>
                        <SelectTrigger className="w-full capitalize">
                            <SelectValue className="capitalize" placeholder="Select vector store type" />
                        </SelectTrigger>
                        <SelectContent>
                            {VECTOR_STORE_TYPES.map(item => (
                                <TooltipProvider key={item}>
                                    <Tooltip className='z-[1000]'>
                                        <TooltipTrigger asChild>
                                            <div>
                                                <SelectItem disabled={checkStoreTypeDisabled(item)} className='capitalize'  value={item}>{item} </SelectItem>
                                            </div>
                                        </TooltipTrigger>
                                        {checkStoreTypeDisabled(item) && (
                                            <TooltipContent className="bg-gray-900 text-white w-fit z-[1000]">
                                                <p>Milvus is not activated yet</p>
                                            </TooltipContent>
                                        )}
                                    </Tooltip>
                                </TooltipProvider>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor='dimensions'> Dimensions </Label>
                    <Select onValueChange={(value) => setForm(prev => ({...prev, dimensions: value}))} id="dimensions" value={form.dimensions}>
                        <SelectTrigger className="w-full capitalize">
                            <SelectValue className="capitalize" placeholder="Select collection dimensions" />
                        </SelectTrigger>
                        <SelectContent>
                            {DIMENSIONS_TYPES[form.vectorStoreType].map(item => (
                                <SelectItem className='capitalize' key={item} value={item}>{item} </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor='embedding-model'> Embedding Model </Label>
                    <Select onValueChange={(value) => setForm(prev => ({...prev, embeddingModel: value}))} id="embedding-model" value={form.embeddingModel}>
                        <SelectTrigger className="w-full capitalize">
                            <SelectValue className="capitalize" placeholder="Select vector search type" />
                        </SelectTrigger>
                        <SelectContent>
                            {SEARCH_TYPES.map(item => (
                                <SelectItem disabled={item === 'openAI'} className='capitalize' key={item} value={item}>{item} </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor='chunking-strategy'> Chunking Strategy </Label>
                    <Select onValueChange={(value) => setForm(prev => ({...prev, chunkingStrategy: value}))} id="chunking-strategy" value={form.chunkingStrategy}>
                        <SelectTrigger className="w-full capitalize">
                            <SelectValue className="capitalize" placeholder="Select chunking strategy" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem disabled={true} className='capitalize' value={'by page'}> by page </SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
    )
}
