import React from 'react';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from 'components/ui/card';

export default function SetupCard({ 
    overviewTitle,
    overviewDescriptions, 
    onDemoClick,
}) {

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-20 space-y-8">  
            <Card className="w-full shadow-lg">
                <CardHeader>
                    <CardTitle className="text-3xl font-bold text-center">
                        {overviewTitle}
                    </CardTitle>
                </CardHeader>
                <CardContent className="text-center">
                    {Array.isArray(overviewDescriptions) ? (
                        overviewDescriptions.map((desc, index) => (
                            <p key={index} className="mt-4 text-xl text-gray-600 dark:text-gray-400 font-light text-justify">
                                {desc}
                            </p>
                        ))
                    ) : (
                        <p className="mt-4 text-xl text-gray-600 dark:text-gray-400 font-light text-justify">
                            {overviewDescriptions}
                        </p>
                    )}
                </CardContent>
                <CardFooter className="flex justify-center">
                    <Button
                        disabled
                        onClick={onDemoClick}
                        size="lg" 
                        className="text-white py-2 px-4"
                    >
                        Try Assistant
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}
