import React, { createContext, useEffect, useRef, useState } from 'react';

export const AITeacherContext = createContext({});

export const AITeacherProvider = ({ children }) => {
  
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [selectedSample, setSelectedSample] = useState([]);

  // User docs
  const [userDocs, setUserDocs] = useState([]);
  const [isUserDocProcessing, setIsUserDocProcessing] = useState(false);
  
  // Org docs
  const [orgDocs, setOrgDocs] = useState([]);
  const [isOrgDocProcessing, setIsOrgDocProcessing] = useState(false);

  // messages
  const initialMessage = [
    {
      role: 'assistant',
      content: "Hi! I am AI Teacher. I am here to help you go through your course materials."
    },
  ]
  const [messages, setMessages] = useState(initialMessage);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  
  
  // Mode select
  const [selectedMode, setSelectedMode] = useState("teacher");

  // Link Click // DOC Display (PDF)
  const [clickedLink, setClickedLink] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [changed, setChanged] = useState (false);
  const URLMapRef = useRef({})
  const [urlMap, setUrlMap] = useState({})
    
  const addToURLMap = (documentId, url=null, loading=true) => {
      // URLMapRef.current[documentId] = {
      //   url,
      //   loading
      // }
      console.log("ADDING TO URL MAP.....")
      setUrlMap((prev)=>(
        {
          ...prev,
          [documentId]:{
            url,
            loading
          }
        }
      )
    )
  }
  useEffect(()=>{
      
      console.log("URL MAP", urlMap )
  },[selectedDocs])

  const value={
    selectedDocs, setSelectedDocs,

    // User docs
    userDocs, setUserDocs,
    isUserDocProcessing, setIsUserDocProcessing,

    // Org docs
    orgDocs, setOrgDocs,
    isOrgDocProcessing, setIsOrgDocProcessing,

    // Messages
    initialMessage,
    messages, setMessages,
    isMessageLoading, setIsMessageLoading,

    // DOC Display (PDF)
    pageNumber, setPageNumber,

    // Mode select
    selectedMode, setSelectedMode,

    // Link Click
    clickedLink, setClickedLink,
    pageNumber, setPageNumber,
    changed, setChanged,
    addToURLMap, URLMapRef, urlMap
  }

  return (
      <AITeacherContext.Provider value={value}>
          {children}
      </AITeacherContext.Provider>
  );
};