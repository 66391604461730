import React, { useContext, useRef, useState } from 'react'
import { PageContent, PageContentHeader, PageContentHeaderText, PageContentHeaderTitle, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Button } from 'components/ui/button';
import OpCodeBuilderCollection from './OpCodeBuilderCollection';
import { OpCodeBuilderContext } from './OpCodeBuilderHAndC/OpCodeBuilderContext';
import OpCodeBuilderCreate from './OpCodeBuilderCreate';

const OpCodeBuilder = () => {
    const [openModal, setOpenModal] = useState(false)
    const { isOpcodeLoading, opCodes } = useContext(OpCodeBuilderContext);
    const scrollAreaRef = useRef(null);
    
    return (
        <PageLayout ref={scrollAreaRef}>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Build your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Workflow Blueprints
                    </PageSubTitle>
                    <PageDescription>
                        Design intelligent, scalable Opcodes with agentic AI and operational codes. Streamline execution, optimize processes, and ensure precision with customizable blueprints for enterprise success
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button onClick={() => setOpenModal(true)} className='w-full'> Add new opcode </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent loading={isOpcodeLoading}>

                <PageContentHeader>
                    <PageContentHeaderTitle>Opcode Builder:</PageContentHeaderTitle>
                    <PageContentHeaderText>Manage your Agentic AI Oerational Codes</PageContentHeaderText>
                </PageContentHeader>

                {opCodes?.length > 0 ? (
                    <OpCodeBuilderCollection opCodes={opCodes}/>
                ) : (
                    <div className='flex flex-grow justify-center items-center'>
                        <span>No opcodes saved.</span>
                    </div>
                )}

               
            </PageContent>
            {/* create new opcode dialog */}
            <OpCodeBuilderCreate openModal={openModal} setOpenModal={setOpenModal} scrollAreaRef={scrollAreaRef}/>
        </PageLayout>
    )
}

export default OpCodeBuilder