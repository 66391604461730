import React from 'react';
import { Label } from '../../../../components/ui/label';
import { Input } from '../../../../components/ui/input';
import { Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue, } from '../../../../components/ui/select';
import { CardDescription } from 'components/ui/card';


const AddUserForm = ({ userInput, setUserInput, disabledEmail=false }) => {

    const handleInputChange = (key, value) => {
        setUserInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div className="space-y-6">
            <p className="mt-4 p-2 bg-muted rounded-md text-sm text-muted-foreground">
                Add a user to the organization.
            </p>
            <div className="space-y-3">
                <Label htmlFor="email">User Email</Label>
                <Input
                    disabled={disabledEmail}
                    id="email"
                    placeholder="Type user email here"
                    type="email"
                    value={userInput.email}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                />
            </div>
            <div className="space-y-3">
                <Label htmlFor="role">Select Role</Label>
                <Select
                    value={userInput.role}
                    onValueChange={(value) => handleInputChange('role', value)}
                    className="w-full"
                >
                    <SelectTrigger>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="z-50">
                        <SelectGroup>
                            <SelectLabel>Roles</SelectLabel>
                            <SelectItem value="admin">Admin</SelectItem>
                            <SelectItem value="editor">Editor</SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>
    );
};

export default AddUserForm;