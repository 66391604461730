/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react'
import { Button } from "components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "components/ui/dialog"
import { ScrollArea } from "components/ui/scroll-area"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service'
import { useParams } from 'react-router-dom'
import { Context } from 'context/GlobalState'
import MultiSelectComponent from 'components/ui/MultiSelect'
import { Label } from 'components/ui/label'
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "lib/utils"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover"
import { opcodeAssistantService } from 'api'

export default function OpcodeInstructionDialog() {
    
    const [opcodes, setOpcodes] = useState([]);
    const [selectedOpcodes, setSelectedOpcodes] = useState([]);
    const [selectDefaultOpcode, setSelectDefaultOpcode] = useState(null);
    const [fetchOpCodesLoading, setFetchOpCodesLoading] = useState(true);

    const [isOpen, setIsOpen] = useState(false);
    const [savedOpcodes, setSavedOpcodes] = useState([]);

    const [btnLoading, setBtnLoading] = useState(false);

    const { oragID, subOragID, assistantId } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleSave = () => {
        
        setBtnLoading(true)
        const opCodeObj = {
            "org_id": oragID,
            "sub_org_id": subOragID,
            "assistant_name": assistantId,
            "opcodes": selectedOpcodes,
            "default_opcode": selectDefaultOpcode.value
        }

        opcodeAssistantService.updateOpcodeAssistant(opCodeObj)
        .then(() => {
            addNewNotifcation('Opcode added successfully to this assistant', 'success')

            const savedOpcodesArr = selectedOpcodes.map(item => ({name: item, default: 'false'}))
            savedOpcodesArr.push({name: selectDefaultOpcode.value, default: 'true'})
            setSavedOpcodes(savedOpcodesArr)

            setIsOpen(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })

    };

    const getOpCodes = () => {

        setFetchOpCodesLoading(true)

        opCodeService.getOpCodesByOrgSubOrg(oragID, subOragID)
        .then((res) => {
            setOpcodes(res.data.OpCodes.map(item => ({value: item, label: item})))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setFetchOpCodesLoading(false)
        })
    }

    const createAssistant = async () => {
        const opcodeAssistantObj = {
            "org_id": oragID,
            "sub_org_id": subOragID,
            "assistant_id": assistantId,
            "description": "..."
        }
        try {
            await opcodeAssistantService.createOpcodeAssistant(opcodeAssistantObj)
        } catch (assistantError) {
            console.log('error creating assistant: ', assistantError);
        }
    }

    const getOpCodeAssistant = () => {

        opcodeAssistantService.getOpcodeAssistant(oragID, subOragID, assistantId)
        .then(async (res) => {
            console.log('opcode assistant res: ', res);
            setSelectedOpcodes(res.data.opcodes.map(item => (item)))
            const savedOpcodesArr = res.data.opcodes.map(item => ({name: item, default: 'false'}))
            if (res.data.default_opcode) {
                savedOpcodesArr.push({name: res.data.default_opcode, default: 'true'})
                setSelectDefaultOpcode({value: res.data.default_opcode, label: res.data.default_opcode})
            }
            setSavedOpcodes(savedOpcodesArr)
        }).catch(async (error) => {
            console.log('error: ', error);
            if (error.response?.status === 404) {
                console.log('not assistant found');
                // create assistant if not exist in redis
                try {
                    // await createAssistant()
                } catch (assistantError) {
                    console.log('error creating assistant: ', assistantError);
                }
            }

        })
    }

    const opcodeSelect = (items) => {
        setSelectedOpcodes(items)
    }

    const handleSelectDefaultOpcode = (item) => {
        setSelectDefaultOpcode(item)
    }

    useEffect(() => {
        getOpCodeAssistant()
        getOpCodes()
    }, [])

    const disableSave = !selectDefaultOpcode || !selectedOpcodes.length > 0


    return (
        <div className="flex w-full mt-4 flex-col">
            <Card>
                <CardHeader>
                    <div className='flex w-full justify-between'>
                        <CardTitle> OpCodes </CardTitle>
                        <Button onClick={() => setIsOpen(true)} className="mb-8 bg-black text-white hover:bg-gray-800">Manage Opcodes</Button>
                    </div>
                </CardHeader>

                <CardContent>
                    <div>
                        <h3 className="mb-2 font-semibold">OpCodes:</h3>
                        <DisplayOpCodeAssistant opcodes={savedOpcodes} />
                    </div>
                </CardContent>


                <CardContent>
                </CardContent>
            </Card>

            {/* opcode dialog */}
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="sm:max-w-[825px]">
                    <DialogHeader>
                        <DialogTitle>Manage Assistant OpCodes</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div className='flex flex-col gap-2'>
                            <Label> Opcodes </Label>
                            <MultiSelectComponent items={opcodes} onItemsSelect={opcodeSelect} selectedItems={selectedOpcodes} disabled={fetchOpCodesLoading} />
                        </div>

                        <div className='flex flex-col gap-2'>
                            <Label> Default opcode </Label>
                            <AssistantCombobox items={opcodes} setter={handleSelectDefaultOpcode} selectedValue={selectDefaultOpcode} disabled={fetchOpCodesLoading} />
                        </div>
                    </div>
                    <DialogFooter>
                        {/* <Button variant="outline" onClick={handleReset}>Reset</Button> */}
                        <Button variant="outline" onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button onClick={handleSave} disabled={btnLoading || disableSave} className="bg-black text-white hover:bg-gray-800">Save</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>
    )
}

export const DisplayOpCodeAssistant = ({ opcodes }) => {

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>Opcode</TableHead>
                    <TableHead>Default</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {opcodes.map((opcode, i) => (
                    <TableRow key={i}>
                        <TableCell>{opcode.name}</TableCell>
                        <TableCell>{opcode.default === 'true' && 'true'}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}



export function AssistantCombobox({ className, items, selectedValue, setter, disabled=false, defaultDisplayed = "Select Item" }) {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(selectedValue)


    useEffect(() => {
        setValue(selectedValue)
    }, [selectedValue])

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger disabled={disabled} asChild>
                <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-between text-accent-foreground overflow-hidden"
                >
                    <span className="truncate" title={value ? value?.value : defaultDisplayed ? defaultDisplayed : "Select Item"}>
                        {value ? value?.value : defaultDisplayed ? defaultDisplayed : "Select Item"}
                    </span>
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className={cn("w-full p-0 z-[9999]", className)}>
                <Command>
                    <CommandInput placeholder="Search..." />
                    <CommandEmpty>No item found.</CommandEmpty>
                    <CommandGroup>
                        <ScrollArea className="max-h-60">
                            {items?.map((item) => (
                                <CommandItem
                                    key={item?.label}
                                    value={item?.value}
                                    onSelect={() => {
                                    setValue(item)
                                    setter && setter(item)
                                    setOpen(false)
                                    }}
                                >
                                    <Check
                                    className={cn(
                                        "mr-2 h-4 w-4",
                                        value?.value === item?.value ? "opacity-100" : "opacity-0"
                                    )}
                                    />
                                    {item?.value}
                                </CommandItem>
                            ))}
                        </ScrollArea>
                    </CommandGroup>
                    <CommandSeparator />
                </Command>
            </PopoverContent>
        </Popover>
    )
}
