import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout';
import TextToSpeechForm from './Components/TextToSpeechForm';

export default function TextToSpeechPage() {
    
    return (
        <NestedPageLayout>
            <TextToSpeechForm />
        </NestedPageLayout>
    );
}     