/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import './users-styles.css'
import { Context } from '../../../../context/GlobalState'
import { httpRequest } from '../../../../utils/httpsRequest'
import { BsPersonFillAdd } from 'react-icons/bs'
import { AppLoader, LoaderSpinner } from '../../../../components/LoaderSpinner'
import AddUserForm from './AddUserForm'
import { useParams } from 'react-router-dom'
import { MdDelete, MdEdit } from 'react-icons/md'
import AlertModal from '../../../../components/Alertmodal'
import InviteUserForm from './InviteUserForm'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../../../components/ui/dialog'
import { Button } from '../../../../components/ui/button'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../../../components/ui/tabs'
import NewStyledTable from '../../../../components/ui/StyledTable'
import { OrganizationContext } from "context/OrganizationContext";

function UsersSetting() {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const { addNewNotifcation, user } = useContext(Context)
    const [userInput, setUserInput] = useState({ email: '', role: 'editor' });
    const [inviteUserInput, setInviteUserInput] = useState({ email: '', role: 'editor' });
    const [selectedItem, setSelectedItem] = useState({})
    const { selectedOrganization } = useContext(OrganizationContext);

    const [currentTab, setCurrentTab] = useState('add-member')

    const { oragID } = useParams();
     
    useEffect(() => {
        httpRequest.get(`/db/users/getUsers/${oragID}`)
            .then((res) => {
                console.log("response", res);
                const editable = res.data.map((item) => {
                    if (item.role === 'super admin') {
                        item.editable = false
                    }

                    return {id: item._id, ...item}
                })
                setTableData(editable);
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false)
            })
    }, []);

    const handleCreate = () => {
        if (!userInput.email) {
            return addNewNotifcation('Please fill in all fields', 'warning')
        }

        const orgObj = {
            email: userInput.email,
            type: userInput.role,
            organizationId: oragID
        }
        console.log('orgObj: ', orgObj);
        setBtnLoading(true);

        httpRequest.post('/db/organizations/update_organization/add-member', orgObj)
            .then((res) => {
                console.log('Response', res);
                const updatedUser = {...res.data}
                updatedUser['id'] = updatedUser._id
                setTableData(prev => ([...prev, updatedUser]));

                addNewNotifcation('Organization Member added successfully.', 'success');
                setOpenModal(false);

            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 404) {
                    addNewNotifcation('Organization Member could not be added. User is not registered. Please check all your entries.', 'warning')
                } else if (error.response?.status === 409)  {
                    addNewNotifcation('Organization Member could not be added. User is already a member of an organization.', 'warning')
                } else if (error.response?.status === 401)  {
                    addNewNotifcation('Organization Member could not be added. Admin are not authorized to add admin.', 'warning')
                } else {
                    addNewNotifcation('Organization Member could not be added. Please try again.', "danger")
                }
            })
            .finally(() => setBtnLoading(false));
    };

    const handleInvite = () => {

        if (!inviteUserInput.email) {
            return addNewNotifcation('Please fill in all fields', 'warning')
        }

        setBtnLoading(true);

        const inviteObj = {
            email: inviteUserInput.email,
            organizationId: oragID,
            role: inviteUserInput.role,
        }

        httpRequest.post('/db/organizations/invite-to-join-organization', inviteObj)
        .then((res) => {
            addNewNotifcation('Invitation has been sent successfully.', 'success');
            setOpenModal(false);
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.status === 409)  {
                addNewNotifcation('Invitation could not be sent. User already exist.', 'danger')
            } else {
                addNewNotifcation('Invitation could not be sent. Please try again.', "danger")
            }
        })
        .finally(() => setBtnLoading(false));

    }


    const handleDelete = (itemId) => {
        if(selectedOrganization.userRole === 'superAdmin' || selectedOrganization.userRole === 'admin') {
            setAlertModal(true)
            const data = tableData.find((item) => item.id === itemId)
            setSelectedItem(data)
        } else {
            addNewNotifcation('Sorry, you are not authorized to remove users', 'danger')
        }
    }

    const handleUpdate = (itemId) => {
        if(selectedOrganization.userRole === 'superAdmin' || selectedOrganization.userRole === 'admin') {
            setOpenUpdateModal(true)
            const data = tableData.find((item) => item.id === itemId)
            setSelectedItem(data)
        } else {
            addNewNotifcation('Sorry, you are not authorized to update users', 'danger')
        }
    }

    const handleOnDelete = () => {
        setBtnLoading(true);

        const deleteOrgObj = {
            organizationId: oragID,
            userId: selectedItem._id,
        }

        httpRequest.post('/db/organizations/update_organization/remove-member', deleteOrgObj)
        .then((res) => {
            setTableData(prev => {
                return prev.filter((item) => item._id !== selectedItem._id)
            })
            console.log('Response', res);
            addNewNotifcation('Organization member removed successfully.', 'success');
            setAlertModal(false);
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.status === 404)
                addNewNotifcation('Organization Member could not be removed. User is not registered. Please check all your entries.', 'warning')
            else
                addNewNotifcation('Organization Member could not be removed. Please try again.', "danger")
        })
        .finally(() => {
            setBtnLoading(false)
        });
    }



    const handleDeleteSelected = (selectedItems) => {
        if(selectedOrganization.userRole === 'superAdmin' || selectedOrganization.userRole === 'admin') {
            setAlertModal(true)
            setSelectedItem(selectedItems)
        } else {
            addNewNotifcation('Sorry, you are not authorized to remove users', 'danger')
        }
    }

    const userRowActions = [
        {'name': 'Edit', 'icon': <MdEdit size={18} />, 'onclick': handleUpdate},
        {'name': 'Remove', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDelete},
    ]

    const userTableActions = [
        {'name': 'Delete Selected', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDeleteSelected},
        {'name': 'Add New', 'icon': <BsPersonFillAdd size={18} />, 'onclick': () => setOpenModal(true)}
    ]
    
    const actionCondition = (row) => {
        return row?.role !== "super admin";
    };

    if (loading) {
        return <div className='flex flex-grow'> <AppLoader size={50} /> </div>;
    }

    return (
        <div className='flex p-8 overflow-y-scroll w-full min-w-[700px]'>
            <div className="w-full">
                <div className='py-4 h-auto'>
                    {selectedOrganization.userRole === 'editor' ? (
                        <NewStyledTable cardTitle={`Manage User (${tableData.length})`} columns={[{ field: 'name', headerName: 'Name'}, { field: 'email', headerName: 'Email'}, { field: 'role', headerName: 'Role'}]} data={tableData} rowActions={userRowActions}/>
                    ) : (
                        <NewStyledTable cardTitle={`Manage User (${tableData.length})`} columns={[{ field: 'name', headerName: 'Name'}, { field: 'email', headerName: 'Email'}, { field: 'role', headerName: 'Role'}]} data={tableData} rowActions={userRowActions} tableActions={userTableActions} actionCondition={actionCondition} multiSelect={true}/>
                    )}
                    
                    {/* add new user modal */}
                    <Dialog open={openModal} onOpenChange={setOpenModal}>
                        <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                            <DialogHeader>
                                <DialogTitle className="text-2xl font-semibold">Add User</DialogTitle>
                                <DialogDescription className="text-md text-muted-foreground">Choose to add or invite a user to your organization.</DialogDescription>
                            </DialogHeader>
                            <Tabs value={currentTab} onValueChange={setCurrentTab}>
                                <TabsList className="space-x-4 bg-muted p-3 rounded-lg">
                                    <TabsTrigger value="add-member" className="border-none shadow-none">Add User</TabsTrigger>
                                    <TabsTrigger value="invite-member" className="border-none shadow-none">Invite User</TabsTrigger>
                                </TabsList>
                                <TabsContent value="add-member">
                                    <AddUserForm userInput={userInput} setUserInput={setUserInput} />
                                </TabsContent>
                                <TabsContent value="invite-member">
                                    <InviteUserForm userInput={inviteUserInput} setUserInput={setInviteUserInput} />
                                </TabsContent>
                            </Tabs>
                            <DialogFooter className="flex justify-end space-x-5 mt-6">
                                <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                                <Button onClick={currentTab === 'add-member' ? handleCreate : handleInvite} className="px-5 py-3 text-md">
                                    {btnLoading ? <LoaderSpinner /> : currentTab === 'add-member' ? 'Add User' : 'Invite User'}
                                </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>

                    {/* remove user */}
                    <AlertModal
                        loading={btnLoading}
                        setopenModal={setAlertModal}
                        openModal={alertModal}
                        onDelete={handleOnDelete}
                    />

                    {/* update user modal */}
                    <UpdateUserMemberDialog setOpenUpdateModal={setOpenUpdateModal} openUpdateModal={openUpdateModal} selectedItem={selectedItem} 
                    setSelectedItem={setSelectedItem} setTableData={setTableData} />
                </div>
            </div>
        </div>
    )
}

const UpdateUserMemberDialog = ({ setOpenUpdateModal, openUpdateModal, selectedItem, setSelectedItem, setTableData }) => {

    const [btnLoading, setBtnLoading] = useState(false);
    
    const { addNewNotifcation } = useContext(Context);
    const { oragID } = useParams();

    const handleOnUpdate = () => {

        const updateOrgObj = {
            type: selectedItem.role,
            userId: selectedItem._id,
            organizationId: oragID
        }

        setBtnLoading(true);
        httpRequest.post('/db/organizations/update_organization/update-user-role', updateOrgObj)
        .then((res) => {
            console.log('Response', res);
            setTableData(prev => {
                return prev.map(item => (
                    item._id === selectedItem._id ? { ...item, role: selectedItem.role } : item
                ))
            });

            addNewNotifcation('User Role updated successfully.', 'success');
            setOpenUpdateModal(false);
        })
        .catch((error) => {
            console.log(error);
            if (error.response?.status === 404)
                addNewNotifcation('User Role could not be updated. User is not registered. Please check all your entries.', 'warning')
            else if (error.response?.status === 401)
                addNewNotifcation('User Role could not be updated. You are not authorized to update.', 'warning')
            else
                addNewNotifcation('User Role could not be updated. Please try again.', "danger")
        })
        .finally(() => setBtnLoading(false));

    };

    return (
        <Dialog open={openUpdateModal} onOpenChange={setOpenUpdateModal}>
            <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <AddUserForm userInput={selectedItem} setUserInput={setSelectedItem} disabledEmail={true} />

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenUpdateModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">
                        Cancel
                    </Button>
                    <Button disabled={btnLoading} onClick={handleOnUpdate} className="px-5 py-3 text-md">
                        Update
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default UsersSetting
