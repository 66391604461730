/* eslint-disable max-lines */
import { Step, StepsContainer } from 'components/NewDesignComponents/VerticalSteps'
import React, { useContext, useEffect, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import InputGroup from '../../InputGroup'
import { OpCodeBuilderContext, STEP_TYPES } from '../../OpCodeBuilderHAndC/OpCodeBuilderContext';

const NestedSteps = ({mainStepIndex, inputGroups, inputGroup, userInput, setInputGroups, parentId}) => {
    const [inputGroups2, setInputGroups2] = useState([])
    const [stepTypes, setStepTypes] = useState([STEP_TYPES[1].name]);
    const { constantStepTypes } = useContext(OpCodeBuilderContext)
    // console.log(parentId, mainStepIndex)

    useEffect(() => {
        if(inputGroup.steps.length > 0){
            setInputGroups2(inputGroup.steps)
            setStepTypes(inputGroup.steps.map(step=> step.step_type))
        }
    }, [])
    
    useEffect(() => {
        if(inputGroups2.length > 0){
            setInputGroups(inputGroups.map((group,i) => i === mainStepIndex? {...group, steps:inputGroups2} : group ))
        }
    }, [inputGroups2])
    

    const handleMoveStep = (dragIndex, hoverIndex, steps, parentIdContainer) => {
        try{
            if(parentId === parentIdContainer){
                let newInputGroups = []
                let newTypes = []
                let i = 0
                steps?.map(step => {
                   
                        const foundInput = inputGroups2.find(inputGroup => inputGroup.step_id === step.props.step_id)
        
                        const newDynamicInputs = {
                            ...foundInput, dynamic_inputs: foundInput.registry_type === 'api'? 
                            {"query_text":i === 0? "real_time_data" : ""} : i === 0? ["real_time_data"] : [],
                        }
                        // const newInputGroup = foundInput.step_type === 'LLM' || foundInput.registry_type === 'api' 
                        // || foundInput.registry_type === 'function'? newDynamicInputs : foundInput
        
                        newInputGroups.push(newDynamicInputs)
        
                        newTypes.push(foundInput.step_type)
                        i++
                })
                setInputGroups2(newInputGroups)
                setInputGroups(inputGroups.map((group,i) => i === mainStepIndex? {...group, steps:newInputGroups} : group ))
                setStepTypes(newTypes)
            }
        }catch(err) {
            console.log(err)
        }
    }
    
    const handleAddGroup = (position, currentStep) => {
        console.log(`Add step ${position} ${currentStep}`)
        let newInputGroups = [...inputGroups2];
        const newStepType = [...stepTypes];
        const currentIndex = Number(currentStep) - 1
        const isToBeFirstIndex = position === 'before' && currentIndex === 0

        // Find the index of the current step  
        if (currentIndex === -1) {
            console.error("Current step not found");
            return;
        }

        const newInputGroup = {
            // indicator_id:window?.crypto?.randomUUID(),
            step_id: window?.crypto?.randomUUID(),
            step_type: "LLM",
            unique_name: "",
            model_type: "",
            registry_type: null,
            registry_key: null,
            fixed_inputs: {},
            dynamic_inputs: isToBeFirstIndex? ["real_time_data"] : [],  
            steps:null,
            output: { Output: "", Output_map: null },
            next_step: {branch_name:[]},
            accumulate_output: true,
            is_first_step: isToBeFirstIndex? true: false
        }

        if(isToBeFirstIndex){
            newInputGroups = newInputGroups.map((group, i) => i ===  currentIndex? {...group, is_first_step:false} : group)
        }
    
        if (position === 'before' || position === 'after') {
            const indexOffset = position === 'before' ? 0 : 1;
            newInputGroups.splice(currentIndex + indexOffset, 0, newInputGroup);
            newStepType.splice(currentIndex + indexOffset, 0, constantStepTypes[1].name);
        } else {
            console.error("Invalid position");
        }
    
        setInputGroups2(newInputGroups);
        setInputGroups(inputGroups.map((group,i) => i === mainStepIndex? {...group, steps:newInputGroups} : group ))
        setStepTypes(newStepType)
    };

    const handleTypeSelect = (value, index) => {
        const newType = constantStepTypes.find(item => item.name === value).name
        setStepTypes(stepTypes.map((type,i) => i === index? newType : type))
        let newInputGroup = {}

        const llmObj = {
            step_id: "",
            step_type: "LLM",
            unique_name: "",
            model_type: "",
            registry_type: null,
            registry_key: null,
            fixed_inputs: {},
            dynamic_inputs: index === 0 ? ["real_time_data"] : [],  
            // dynamic_inputs: [],  
            steps:null,
            output: { Output: "", Output_map: null },
            next_step: {branch_name:[]},
            accumulate_output: true,
            is_first_step: index === 0 ? true : false
        }

        if (newType === "LLM") {
            newInputGroup = llmObj
        } else if (newType === "LLM-Stream") {
            newInputGroup = llmObj
        } else if (newType === "Non-LLM") {
            newInputGroup = {
                step_id: "",
                step_type: "Non-LLM",
                unique_name: null,
                model_type: null,
                registry_type: "",
                registry_key: "",
                fixed_inputs: {},
                dynamic_inputs: {},
                steps:null,
                output: { Output: "", Output_map: null },
                next_step: {branch_name:[]},
                accumulate_output: true,
                is_first_step: index === 0 ? true : false
            }
        } else if (newType === "Condition"){
            newInputGroup = {
                step_id: "",
                step_type: "Condition",
                unique_name: null,
                model_type: null,
                registry_type: null,
                registry_key: null,
                fixed_inputs: {
                    expression: ""
                },
                dynamic_inputs:index === 0 ? ["real_time_data"] : [],
                steps:null,
                output: { Output: null, Output_map: null },
                next_step: {
                    true_branch: [],
                    false_branch: []
                },
                accumulate_output: true,
                is_first_step: index === 0 ? true : false
            }
        } else if (newType === "Loop"){
            newInputGroup = {
                step_id: "",
                step_type: "Loop",
                unique_name: null,
                model_type: null,
                registry_type: null,
                registry_key: null,
                fixed_inputs: {
                    collection: "",
                    item: "loopItem",
                    max_iterations: "",
                    delimiters: [""],
                    break_condition: ""
                },
               dynamic_inputs:index === 0 ? ["real_time_data"] : [],
               steps: [{
                step_id: window?.crypto?.randomUUID(),
                step_type: "LLM",
                unique_name: "",
                model_type: "",
                registry_type: null,
                registry_key: null,
                fixed_inputs: {},
                dynamic_inputs:["real_time_data"],  
                steps:null,
                output: { Output: "", Output_map: null },
                next_step: {branch_name:[]},
                accumulate_output: true,
                is_first_step: true
            }],
               output: { Output: null, Output_map: null },
               next_step: {branch_name: []},
               accumulate_output: true,
               is_first_step: index === 0 ? true : false
            }
        }

        const newInputGroups = inputGroups2.map((inputGroup,i) => i === index? {...newInputGroup, step_id:inputGroup.step_id} : inputGroup)
        setInputGroups2(newInputGroups)
        setInputGroups(inputGroups.map((group,i) => i === mainStepIndex? {...group, steps:newInputGroups} : group ))
    }
    
    const handleRemoveGroup = (id) => {
        console.log(`Delete step ${id}`, stepTypes)
        const inputGroupDeleted = inputGroups2?.find((_, index) => index === id - 1)

        const updatedInputGroups = inputGroups2?.map(group =>{
            if(group?.step_type !== "Condition" ){
                return group?.next_step.branch_name[0] === inputGroupDeleted?.step_id? {...group, next_step:{branch_name:[]}} : group
            }

            if(group?.step_type === "Condition"){
                const true_branch = group?.next_step?.true_branch?.filter(step => step !== inputGroupDeleted?.step_id)
                const false_branch = group?.next_step?.false_branch?.filter(step => step !== inputGroupDeleted?.step_id)
                return {...group, next_step:{true_branch, false_branch}}
            }

            return group
        } )

        let newInputGroups = updatedInputGroups.filter((_, index) => index !== id - 1)

        newInputGroups = newInputGroups?.map((group, i) => {
            if(group?.step_type === "LLM" || group?.step_type === "LLM-Stream"){
                const newData = group?.dynamic_inputs_ids?.filter(input => input.id !== inputGroupDeleted.step_id)
                return i === 0 ? group : {
                    ...group, dynamic_inputs_ids:newData || [],
                     dynamic_inputs:group?.dynamic_inputs_ids?.length > 0? newData?.map(data=> data.outputVar) : group?.dynamic_inputs
                }
            }

            if(group?.registry_type === 'api'){
                const nonLlm = group?.dynamic_inputs?.query_text
                return {...group, dynamic_inputs:{query_text:nonLlm === inputGroupDeleted?.output_var? "" : nonLlm}}
            }

            if(group?.registry_type === 'function'){
                const newData = group?.dynamic_inputs?.filter(input => input.id !== inputGroupDeleted?.step_id)
                return {...group, dynamic_inputs:newData}
            }

            return group
        })

        setInputGroups2(newInputGroups)
        setStepTypes(stepTypes.filter((_, index) => index !== id - 1))
        
        setInputGroups(inputGroups.map((group,i) => i === mainStepIndex? {...group, steps:newInputGroups} : group ))
    }

    return (
        <StepsContainer onMoveStep={handleMoveStep} parentId={parentId} className={'w-full bg-white'}>
            {inputGroups2?.map((inputGroup2, index) => (
                <Step
                indicator={`${index + 1}`}
                canAddBefore={true}
                canAddAfter={true}
                isDraggable={true}
                canDelete={index === 0 ? false : true}
                onAddStep={handleAddGroup}
                onDeleteStep={handleRemoveGroup}
                key={index}
                title="Opcode Step"
                itemId={parentId}
                step_id={inputGroup2.step_id}
                >
                    <div className="flex flex-col gap-2 w-full">
                        <Select onValueChange={(value) => handleTypeSelect(value, index)} 
                        id="step_type" 
                        className="w-full" value={stepTypes[index]}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select step type" />
                            </SelectTrigger>
                            <SelectContent>
                                {constantStepTypes.slice(1).map((item) => (
                                    <SelectItem key={item.id} value={item.name}>
                                        {item.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <div className="flex flex-col gap-4">
                            <InputGroup 
                                index={index}
                                inputGroup={inputGroup2} 
                                inputGroups={inputGroups2}
                                setInputGroups={setInputGroups2}
                                nested={true}
                                userInput={userInput}
                            />
                            <hr/>
                        </div>
                    </div>
                </Step>
                ))}
        </StepsContainer>
    )
}

export default NestedSteps