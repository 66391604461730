import React, { useState, useContext } from 'react';
import './organization-styles.css';
import { Outlet, useParams } from 'react-router-dom';
import { Context } from '../../../context/GlobalState';
import AlertModal from '../../../components/Alertmodal';
import { Card, CardContent, CardFooter, CardTitle } from '../../../components/ui/card';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { handleLeaveOrganization } from '../../../utils/organizationUtils';
import { OrganizationContext } from "context/OrganizationContext";
import OrganizationCluster from './OrganizationCluster';
import SubOrganizationPlan from 'pages/Dashboard/SubOrganization/Plan';

export default function OrganizationSetting() {
    return (
        <div className='flex flex-grow gap-2'>

            <div className='flex flex-col flex-grow'>
                <Outlet />
            </div>
        </div>
    );
}

export const OrganizationInfoCard = () => {

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { selectedOrganization } = useContext(OrganizationContext);

    const { oragID } = useParams();
    const { user, addNewNotifcation } = useContext(Context)


    const handleLeaveOrg = async () => {

        setDeleteLoading(true)
        try {
            await handleLeaveOrganization(oragID, user._id)
        } catch (error) {
            if (error.response?.status === 409) {
                addNewNotifcation('Super admin can not leave the organization', 'warning')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'warning')
            }
        } finally {
            setDeleteLoading(false)
        }

    }

    return (
        <div className='flex p-8 overflow-y-scroll w-full min-w-[700px]'>
            <div className='w-full'>
                <div className='h-auto py-8 space-y-6'>
                    <Card className="w-full space-y-8 p-4">
                        <CardTitle> Organization </CardTitle>
                        <CardContent>
                            <div className='flex flex-col gap-3'>
                                <div className='flex flex-col gap-2'>
                                    <Label className='text-gray-500'>Name</Label>
                                    <span className='font-[500]'>{selectedOrganization.name}</span>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <Label className='text-gray-500'> Created </Label>
                                    <span className='font-[500]'>{new Date(selectedOrganization.created).toDateString()}</span>
                                </div>
                            </div>
                        </CardContent>
                        <CardFooter>
                            {selectedOrganization.userRole !== "superAdmin" && (
                                <div className='flex w-full justify-end'>
                                    <Button variant='destructive' onClick={() => setDeleteModal(true)}> Leave Organization </Button>
                                    <AlertModal 
                                    openModal={deleteModal}
                                    setopenModal={setDeleteModal}
                                    onDelete={handleLeaveOrg}
                                    loading={deleteLoading} 
                                    title='Are you sure you want to leave this organization'
                                    subTitle=''
                                    />
                                </div>
                            )}
                        </CardFooter>
                    </Card>

                    <OrganizationCluster />

                    {/* organization subscription */}
                    <SubOrganizationPlan />
                </div>
            </div>
        </div>
    )
}