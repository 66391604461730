import { Trash2, FileUp, FolderUp, EyeIcon, EyeOffIcon } from 'lucide-react'
import React, { useCallback, useState, useRef, useEffect, useContext } from "react";
import { Button } from 'components/ui/button';
import { fileManagerService } from 'api/services/BEX/fileManager.service';
import { Context } from 'context/GlobalState';
import { useParams } from 'react-router-dom';
import { AddFolderDialog } from './AddFolder';
import { CollectionQueryModal } from './CollectionQuery';
import { Switch } from "components/ui/switch"
import axios from 'axios';

const FileActionBar = ({ setFileSystem, findFolder, selectedFolder, currentPath, setUploadLoading, getFileSystem, handleDeleteModal, selectedItems, showFailed, setShowFailed }) => {
    const { user, addNewNotifcation } = useContext(Context)
    const params = useParams()

    const [prefix, setPrefix] = useState("");
    const [bucket, setBucket] = useState("");
    const fileInputRef = useRef(null)
    const folderInputRef = useRef(null)

    const handleFileButtonClick = useCallback(() => {
        fileInputRef.current?.click()
    }, [])

    const handleFolderButtonClick = useCallback(() => {
        folderInputRef.current?.click()
    }, [])

    const handleFileChange = useCallback( async (event, currentPrefix, bucketName) => {
        console.log('currentPrefix:' ,currentPrefix);
        
        const files = event.target.files
        if (files && files.length <= 0)  {
            return addNewNotifcation('Please add files to upload', 'warning')
        }

        const fileNamesArr = []
        const metadataArr = []

        Array.from(files).forEach((file) => {
            fileNamesArr.push(file.name)
            metadataArr.push(
                JSON.stringify({
                    lastModifiedDate: file.lastModifiedDate,
                    userId: user._id,
                    organizationId: params.oragID,
                    subOrganizationId: params.subOragID,
                })
            );
        })

        const uploadFilesObj = {
            organizationId: params.oragID,
            bucketId: bucketName,
            prefix: currentPrefix || null,
            fileNames: fileNamesArr,
            metadataArray: metadataArr
        }

        try {
            setUploadLoading(true)

            const response = await fileManagerService.getPreSignedUrls(uploadFilesObj)
            const { preSignedUrls } = response.data;

            // Step 2: Upload each file to IBM COS using its respective pre-signed URL
            await Promise.all(preSignedUrls.map(async ({ fileName, url }) => {
                const file = Array.from(files).find((file) => file.name === fileName);
                await axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                });
            }));

            addNewNotifcation("Files successfully uploaded.", "success")
            getFileSystem()
        } catch (error) {
            console.error(error);
            // if (error)
            if (error.response?.data?.message === "\"prefix\" is not allowed to be empty")
                addNewNotifcation("Prefix is not allowed to be empty.", "warning")
            else
                addNewNotifcation("Files not uploaded.", "danger")
        } finally {
            setUploadLoading(false)
        }
        event.target.value = null;
    }, [])

    const handleFolderChange = useCallback(async (event, currentPrefix, bucketName) => {
        event.preventDefault();
        const files = event.target.files;
        if (files && files.length > 0) {
            const formGroups = {};
    
            // Loop through each file to get the relative path and group by folder
            Array.from(files).forEach((file, index) => {
                const relativePath = file.webkitRelativePath; // Get the relative path
                const folderPath = relativePath.split('/').slice(0, -1).join('/'); // Get folder path
    
                // Create a unique key for the folder group
                const filePrefix = `${currentPrefix ? currentPrefix + "/" : ""}${folderPath}`;
    
                // Initialize the group if it doesn't exist
                if (!formGroups[filePrefix]) {
                    formGroups[filePrefix] = new FormData(); // Create a new FormData for the group
                }
    
                // Append the file to the respective FormData group
                formGroups[filePrefix].append('files', file);
                formGroups[filePrefix].append(`metadata[${index}]`, JSON.stringify({
                    lastModifiedDate: file.lastModifiedDate,
                    userId: user._id,
                    organizationId: params.oragID,
                    subOrganizationId: params.subOragID,
                }));
            });
    
            // Prepare to hold all promises
            const uploadPromises = [];
    
            // Loop through each group and prepare the upload
            for (const [prefix, form] of Object.entries(formGroups)) {
                // Add the other metadata to each FormData
                form.append('bucketId', bucketName);
                form.append('prefix', prefix);
                form.append('organizationId', params.oragID);
                // form.append('subOrganizationId', params.subOragID);
                // form.append('metadata', JSON.stringify({ user_id: user._id }));
    
                // Create the upload promise
                const uploadPromise = fileManagerService.uploadFiles(form);
                uploadPromises.push(uploadPromise);
            }
    
            try {
                setUploadLoading(true)
                // Await all promises to resolve
                const responses = await Promise.all(uploadPromises);
                console.log(responses);
                addNewNotifcation("Files successfully uploaded.", "success")
                getFileSystem()
            } catch (error) {
                console.error(error);
                if (error.response?.data?.message === "\"prefix\" is not allowed to be empty")
                    addNewNotifcation("Prefix is not allowed to be empty.", "warning")
                else
                    addNewNotifcation("Files not uploaded.", "danger")
            } finally {
                setUploadLoading(false)
            }
    
            event.target.value = null; // Clear input for re-selection
        }
    }, []);


    useEffect(() => {
        const modifiedPath = currentPath.slice(1); // Remove the first item (collection name)
        console.log(currentPath);
        const prefixes = modifiedPath.map(item => item.name).join('/');
        setBucket(currentPath[0]?.bucket_name);
        setPrefix(prefixes);
        console.log("bucket name:", currentPath[0]?.bucket_name);
        console.log("prefixes:", prefixes);
}, [currentPath]);

    return (
        <nav className="bg-white px-4 py-2 flex flex-row flex-nowrap items-center border-b">
            <div className="flex flex-row flex-nowrap gap-2 pr-2 w-fit items-center border-r">
                <AddFolderDialog setFileSystem={setFileSystem} findFolder={findFolder} selectedFolder={selectedFolder}/>
            </div>
            <div className="flex flex-row flex-nowrap gap-2 px-2 w-fit items-center border-r">
                <Button onClick={handleFileButtonClick} variant="outline" className="flex gap-2 text-xs" >
                    <FileUp className='w-4 h-4'/>
                    <span>Upload File</span>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={(e) => handleFileChange(e, prefix, bucket)}
                        className="hidden"
                        multiple
                        aria-label="File upload"
                    />
                </Button>
                <Button onClick={handleFolderButtonClick} variant="outline" className="flex gap-2 text-xs" >
                    <FolderUp className='w-4 h-4'/>
                    <span>Upload Folder</span>
                    <input
                        type="file"
                        ref={folderInputRef}
                        onChange={(e) => handleFolderChange(e, prefix, bucket)}
                        className="hidden"
                        webkitdirectory="true"
                        directory="true"
                        multiple
                        aria-label="Folder upload"
                    />
                </Button>
            </div>
            <div className="flex items-center px-2 border-r gap-2">
                <Switch
                    checked={!showFailed}
                    onCheckedChange={() => setShowFailed(!showFailed)}
                />
                {showFailed ? (
                    <span className='text-xs'>Show Success Files Only</span>
                ) : (
                    <span className='text-xs'>Show Success Files Only</span>
                )}
            </div>
            
            <div className="flex gap-2 px-2 border-r">
                <CollectionQueryModal collectionName={bucket} selectedItems={selectedItems}/>
            </div>
            {selectedItems.length > 0 && 
            <div className="flex gap-2 px-2 border-r">
                <Button variant="ghost" className="flex gap-2 text-xs" disabled={selectedItems.length === 0} onClick={(e) => handleDeleteModal(selectedItems)}>
                    <Trash2 className='w-4 h-4' />
                </Button>
            </div>}
        </nav>
    );
};

export default FileActionBar;