import React from 'react'
import OpCodeBuilder from "pages/Dashboard/SubOrganization/OpCodes/OpcodeBuider/index";

export default function OpCodes() {

    return (
        <>
            <OpCodeBuilder />
        </>
    )
}