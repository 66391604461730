
export function checkRequiredFields(instructionObj, requiredFields) {
    const emptyFields = [];

    requiredFields.forEach(field => {
        if (!instructionObj[field.field] || instructionObj[field.field].trim() === '') {
            emptyFields.push(field);
        }
    });

    if (emptyFields.length > 0) {
        return {
            isValid: false,
            missingFields: emptyFields
        };
    }

    return { isValid: true };
}