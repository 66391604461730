import { knowledgeBuilderHttpClient } from "api/clients/knowledgeBuilder.httpClient";

/**
 * Service class for performing Knowledge Builder Operations
 */
class KnowledgeBuilderService {

    /**
     * L1 Processing from COS
     * @param {Object} body - The body of the request.
     * @returns {Promise<Object>}
     */
    async processFromCOS(body) {
        const res = await knowledgeBuilderHttpClient.post(`/l1_processing_v2/process_from_cos`, body);
        return res;
    }


    async processFromCOSV2(body) {
        const res = await knowledgeBuilderHttpClient.post(`/l1_processing_v2/process_from_cos_v2`, body);
        return res;
    }


    async getL2Processing(body) {
        const res = await knowledgeBuilderHttpClient.post(`/elastic/list`, body);
        return res;
    }

    async l2Processing(body) {
        const res = await knowledgeBuilderHttpClient.post(`/l2_processing/extract-info`, body);
        return res;
    }

    async l2Processing2(body) {
        const res = await knowledgeBuilderHttpClient.post(`/l2/process`, body);
        return res;
    }

    // IBM COS 
    /**
     * Fetches files from COS by bucketName.
     * @returns {Promise<Object>} The response from the server returns list of documents.
     */
    async listFilesCOS(orgId, subOragID, bucketName, collectionId, prefix="", max_keys=100, url_expiration=3600, continuation_token=null) {
        // kb deployment endpoint for now
        let url = `/cos/list/${orgId}?suborg_id=${subOragID}&bucket_name=${bucketName}&collection_id=${collectionId}&prefix=${prefix}&max_keys=${max_keys}&url_expiration=${url_expiration}`;
        if (continuation_token) {
            url +=`&continuation_token=${continuation_token}`
        }
        const response = await knowledgeBuilderHttpClient.get(url);
        return response;
    }
}

const kbService = new KnowledgeBuilderService();
export { kbService };
