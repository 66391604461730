import React, { useContext, useState } from 'react'
import { Button } from "../../components/ui/button"
import { Textarea } from "../../components/ui/textarea"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import emailSamples from './emailSamples'
import { Context } from '../../context/GlobalState'
import { LoaderModal } from '../../components/LoaderModal'
import { EmailHandlingRes } from './EmailHandlingRes'
import { emailHandlingWSService } from 'api/services/PROJECT-O/WEBSOCKETS/EmailHandlingDemo.Websocket.service'
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'

export default function EmailHandlingDemo() {

    const [emailSample, setEmailSample] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [emailInbox, setEmailInbox] = useState(false)
    const [isPredefined] = useState(true)
    const [uploadedDocs] = useState([])
    const [responseData, setResponseData] = useState([])

    const { addNewNotifcation } = useContext(Context)
    

    const handleOpCodeClick = async () => {

        if (!emailSample) {
            return addNewNotifcation('Please choose email sample', 'warning')
        } else if (!isPredefined && uploadedDocs.length <= 0) {
            return addNewNotifcation('Please add at least one file to process email', 'warning')
        }

        const encodedEmailTitle = encodeURIComponent(emailSample);

        try {
            setBtnLoading(true);
            const emailSampleObj = {
                org_id: process.env.REACT_APP_COMMON_ORGANIZATION_ID,
                sub_org_id: process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID,
                assistant_name: "667538b01444181354ac98eb",
                mode: "Bypass",
                real_time_data: encodedEmailTitle,
                log_id: window?.crypto?.randomUUID()
            }

            const res = await emailHandlingWSService.getEmailMessage(emailSampleObj)
            console.log(res)
            if(res?.execution_log?.length > 0 ){
                setResponseData(res?.execution_log);
            }
        } catch(err) {
            console.log("error: ", err);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
            setResponseData(null)
        } finally {
            setBtnLoading(false);
            setEmailInbox(false);
        }
    };

    const handleChooseEmailSample = (item) => {
        setEmailSample(item.email)
    }

    return (
        <NestedPageLayout>
            <Card className='relative h-auto'>
                {(emailInbox || btnLoading) && <LoaderModal />}

                <CardHeader>
                    <CardTitle>Test AI Email Assistants</CardTitle>
                    <CardDescription>Select an email, modify the content, and see the AI's response.</CardDescription>
                </CardHeader>

                <CardContent className="flex flex-col h-auto gap-4">

                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                        {emailSamples.map(item => (
                            <Button key={item.opcode} variant="outline" onClick={handleChooseEmailSample.bind(this, item)}>
                                <span className="truncate">{item.opcode}</span>
                            </Button>
                        ))}
                    </div>
        
                    <div className="grid gap-4">
                        <Textarea value={emailSample} onChange={(e) => setEmailSample(e.target.value)} placeholder="Modify the email content here..." className="min-h-[200px]" />
                    </div>

                    <div className="grid gap-4">
                        <Button onClick={handleOpCodeClick} disabled={!emailSample || btnLoading} className="w-full">Process</Button>
                    </div>

                    {responseData?.length > 0 && (
                        <EmailHandlingRes executionLog={responseData}/>
                    )}

                </CardContent>
                
            </Card>
        </NestedPageLayout>

    )
}