import { bexHttpClient } from '../../clients';


/**
 * Service class for performing subscription operations.
 */
class TuningLabService {

    /**
     * Fetches the user's tuning experiments.
     * @returns {Promise<Object>} The response from the server returns sub organization channels.
     */
    async getUserTuningExperiments(org_id, sub_org_id, user_id) {
        const response = await bexHttpClient.get(`/db/tuning-experiment/get-user-tuning-experiments?org_id=${org_id}&sub_org_id=${sub_org_id}&user_id=${user_id}`)
        return response;
    }

    /**
     * add new tuning experiment
     * @param {Object} data - The new tuning experiment object includes org_id, sub_org_id, and any extra info
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async addTuningExperiment(data) {
        const response = await bexHttpClient.post(`/db/tuning-experiment/add-tuning-experiment`, data)
        return response;
    }

    /**
     * update channel
     * @param {Object} data - The new channel object includes organizationId, subOrganizationId, topic, address and any extra info
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async updateTuningExperiment(data) {
        const response = await bexHttpClient.put(`/db/tuning-experiment/update-tuning-experiment`, data)
        return response;
    }


    /**
     * delete an existing tuning experiment
     * @param {Object} data - Delete object includes org_id, sub_org_id, and tuningExperiment_id
     * @returns {Promise<Object>} The response from the server returns new channel document from mongoDB.
     */
    async deleteTuningExperiment(org_id, sub_org_id, tuningExperiment_id, user_id) {
        const response = await bexHttpClient.delete(`/db/tuning-experiment/delete-tuning-experiment?org_id=${org_id}&sub_org_id=${sub_org_id}&tuningExperiment_id=${tuningExperiment_id}&user_id=${user_id}`)
        return response;
    }


}

const tuningLabService = new TuningLabService()
export { tuningLabService }
