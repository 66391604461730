import React, { useEffect, useState } from 'react'
import OpCodeBuilderCard from './OpCodeBuilderCard'
import { Input } from 'components/ui/input'

const OpCodeBuilderCollection = ({opCodes = []}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOpCodes, setFilteredOpCodes] = useState([...opCodes])

    useEffect(() => {
        handleFilterOpCodes(searchTerm)
    }, [opCodes])

    const handleFilterOpCodes = (text) => {
        const filteredItems = text? opCodes.filter(opcode =>
            opcode?.opcode_id?.toLowerCase().includes(text.toLowerCase())
            ) : opCodes;

        setFilteredOpCodes(filteredItems)
    }
        
    return (
        <div className='w-full mx-auto p-2 md:p-6'>
            <div className="space-y-4">
                <div className="flex gap-2 items-center w-full">
                    <Input
                        type="text"
                        placeholder="Search opcodes..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            handleFilterOpCodes(e.target.value)
                        }}
                        className="w-full mx-auto bg-white"
                    />
                </div>
                {filteredOpCodes.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {filteredOpCodes.map((opCode, index) => (
                            <OpCodeBuilderCard key={index} opCode={opCode} opCodes={opCodes} />
                        ))}
                    </div>
                ) : (
                    <div className='flex flex-grow justify-center items-center'>
                        <span className="text-lg font-semibold mb-2">No opcodes Found</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OpCodeBuilderCollection