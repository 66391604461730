import { bexHttpClient } from '../../clients';


/**
 * Service class for performing opcode assistant operations.
 */
class OpcodeAssistantService {

    /**
     * Fetches assistant instances by organization, sub organization id and assistant id.
     * @returns {Promise<Object>} The response from the server returns object of an assistant.
     */
    async getOpcodeAssistant(organizationId, subOrganizationId, assistantId) {
        const response = await bexHttpClient.get(`/redis/opcode-assistants/get-assistant?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}&assistantId=${assistantId}`);
        return response;
    }

    /**
     * lists assistants by organization and sub organization id.
     * @returns {Promise<Object>} The response from the server returns array of assistants.
     */
    async listOpcodeAssistants(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`/redis/opcode-assistants/list-assistants?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`);
        return response;
    }

    /**
     * add new opcode assistant.
     * @param {Object} data - The new assistant instance data including assistant_name, org_id, sub_org_id and description.
     * @returns {Promise<Object>} The response from the server returns new assistant instance document from mongoDB.
     */
    async createOpcodeAssistant(data) {
        const response = await bexHttpClient.post(`/redis/opcode-assistants/create-assistant`, data);
        return response;
    }

    /**
     * update opcode assistant.
     * @param {Object} data - The updated assistant instance data including id, organizationId, subOrganizationId, status, name and description.
     * @returns {Promise<Object>} The response from the server returns updated assistant instance document from mongoDB.
     */
    async updateOpcodeAssistant(data) {
        const response = await bexHttpClient.put(`/redis/opcode-assistants/update-assistant`, data);
        return response;
    }

    /**
     * delete assistant.
     * @param {Object} data - The deleted assistant instance data including assistant id, organizationId and subOrganizationId.
     * @returns {Promise<Object>} The response from the server returns response assistant of deleting status.
     */
    async deleteOpcodeAssistant(data) {
        const response = await bexHttpClient.delete(`/redis/opcode-assistants/delete-assistant`, {data: data});
        return response;
    }

}


const opcodeAssistantService = new OpcodeAssistantService()
export { opcodeAssistantService }
