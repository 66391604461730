import { AppLoader } from "components/LoaderSpinner";
import React, { useState, useEffect } from "react";

const withLoader = (WrappedComponent, fetchData) => {
    return function WithLoaderComponent(props) {
        const [loading, setLoading] = useState(true);
        const [data, setData] = useState(null);
        const [error, setError] = useState(null);

        useEffect(() => {
            const loadData = async () => {
                setLoading(true);
                setError(null);
                try {
                    const result = await fetchData();
                    setData(result);
                } catch (err) {
                    setError(err.message || "An error occurred while fetching data.");
                } finally {
                    setLoading(false);
                }
            };
            loadData();
        }, []);

        if (loading) {
            return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
        }

        if (error) {
            return <div className="flex flex-grow flex-col justify-center items-center">{error}</div>;
        }

        // Pass the fetched data to the wrapped component
        return <WrappedComponent {...props} data={data} />;
    };
};

export default withLoader;
