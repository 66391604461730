

    
export function isLastIndex(index, array) {
    return index === array.length - 1;
}

export function convertObjectToArray(obj, key='field', value1='fieldType') {

    const results = Object.entries(obj).map(([field, value]) => ({
        [key]: field,
        [value1]: value,
    }));
    // console.log('results: ', results);
    return results
}

export function convertObjectToArrayV2(obj) {

    const results = Object.entries(obj).map(([field, value]) => ({
      field: field,
      fieldType: value.convertObjectToArray,
      required: value.required,
      description: value.description,
    }));
    // console.log('results: ', results);
    return results
}
  

export function convertArrayToObject(array) {
    const resultObject = {};
  
    array.forEach(({ field, fieldType }) => {
      resultObject[field] = fieldType;
    });
  
    return resultObject;
}

export function convertArrayToObjectV2(array, key, value) {
    const resultObject = {};
  
    array.forEach((item) => {
      resultObject[item[key]] = item[value];
    });
  
    return resultObject;
}

export function areObjectsEqual(obj1, obj2) {
  // Check if both objects are null or undefined
  if (obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined) {
      return obj1 === obj2;
  }

  // Get the keys of each object
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
      return false;
  }

  // Check if all properties have the same values
  for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      
      // Check if both values are objects, and if so, recursively compare
      if (typeof val1 === 'object' && typeof val2 === 'object') {
          if (!areObjectsEqual(val1, val2)) {
              return false;
          }
      } else if (val1 !== val2) {
          return false;
      }
  }

  return true;
}

export function containsKeyValue(objects, keyToCheck) {
    return objects.some(obj => obj[keyToCheck] === '');
}