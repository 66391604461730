import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Badge } from "components/ui/badge"
import { Button } from "components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { CalendarDays, User, Cog, Info, MoreVertical, Trash2, Clock, XCircle, CheckCircle, Play } from 'lucide-react'
import { useContext } from "react"
import { Context } from "context/GlobalState"
import { formatDate } from "utils/timeUtils"

const statusConfig = {
    'not started': { label: 'Not Started', icon: Clock, color: 'bg-gray-500' },
    'executing': { label: 'Executing', icon: Play, color: 'bg-blue-500' },
    'completed': { label: 'Completed', icon: CheckCircle, color: 'bg-green-500' },
    'failed': { label: 'Failed', icon: XCircle, color: 'bg-red-500' }
  }

export default function TuningInfoCard({ experiment, onViewEdit, onDelete }) {
    const {user} = useContext(Context)
    const {id, deployment_name, tuning_method, status, created, summary} = experiment
    const { label, icon: StatusIcon, color } = statusConfig[status] || statusConfig['not started']

    return (
        <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-xl font-bold flex items-center gap-2">
                    <User className="h-5 w-5" />
                    {user.name} 
                </CardTitle>
                <DropdownMenu  modal={false}>
                    <DropdownMenuTrigger asChild>
                        <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                            <MoreVertical className="h-4 w-4" />
                            <span className="sr-only">Toggle menu</span>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        {/* <DropdownMenuSeparator /> */}
                        <DropdownMenuItem onClick={() => onViewEdit(id)}>
                            <Cog className="mr-2 h-4 w-4" />
                            <span>View/Edit</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem className="text-red-500" onClick={() => onDelete(id)}>
                            <Trash2 className="mr-2 h-4 w-4 " />
                            <span>Delete</span>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </CardHeader>
            <CardContent className="grid gap-3 text-sm">
                <div className="flex items-center gap-2">
                    <Info className="h-4 w-4 text-gray-500" />
                    <span className="font-medium">Deployment Name:</span> {deployment_name}
                </div>
                <div className="flex items-center gap-2">
                    <Cog className="h-4 w-4 text-gray-500" />
                    <span className="font-medium">Tuning Method:</span> {tuning_method === 'prompt'? 'Prompt' : "Fine"}
                </div>
                <div className="flex items-center gap-2">
                    <StatusIcon className={`h-4 w-4 ${color.replace('bg-', 'text-')}`} />
                    <span className="font-medium">Status:</span>
                    <Badge 
                        variant="outline"
                        className={`${color} text-white`}
                    >
                        {label}
                    </Badge>
                </div>
                <div className="flex items-center gap-2">
                    <CalendarDays className="h-4 w-4 text-gray-500" />
                    <span className="font-medium">Created:</span> {formatDate(created)}
                </div>
                <div className="mt-2">
                    <p className="font-medium mb-1">Summary:</p>
                    <p className="text-gray-600">{summary}</p>
                </div>
            </CardContent>
        </Card>
    )
}

