
export function getTimeDifference(inputDateStr) {
    if (!inputDateStr)
        return ''
    const inputDate = new Date(inputDateStr);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - inputDate;
    return formatTimeDifference(diffInMilliseconds);
}

function formatTimeDifference(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30); // Assuming a month is 30 days

    if (months > 0) {
        return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
        return days === 1 ? "1 day ago" : `${days} days ago`;
    } else if (hours > 0) {
        return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
        return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
        return "just now";
    }
}

export function convertUnixTimestampToDate(unixTimestamp) {
    try {
        const date = new Date(unixTimestamp * 1000);

        // Extract the date components
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        let hours = date.getHours();
        const minutes = ('0' + date.getMinutes()).slice(-2);
    
        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
    
        // Format the date as 'MMM DD, h:mm AM/PM'
        return `${month} ${day}, ${hours}:${minutes} ${ampm}`;
    } catch (error) {
        return ''
    }

}


export function formatDate(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format as "YYYY-MM-DD HH:mm:ss"
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function isISODateString(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // Check if the date is valid
}

export function formatDateToDDMMYYY(isoDate) {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    
    return `${day} ${month} ${year}`;
}
  