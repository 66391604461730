import { bexHttpClient } from '../../clients';


/**
 * Service class for performing authentication operations.
 */
class AuthService {
    /**
     * Fetches the authenticated user.
     * @returns {Promise<Object>} The response from the server indicating the user's authentication status.
     */
    async getUser() {
        const response = await bexHttpClient.get('/auth/is-user-auth');
        return response;
    }

    /**
     * Fetches the authenticated user.
     * @returns {Promise<Object>} The response from the server indicating the user's authentication status.
     */
    async getUserObject(userId) {
        const response = await bexHttpClient.get(`/auth/get-user/${userId}`);
        return response;
    }

    /**
     * Signs up a new user with the provided data.
     * @param {Object} data - The signup data including name, email, and password.
     * @returns {Promise<Object>} The response from the server after attempting to create a new user.
     */
    async signup(data) {
        const response = await bexHttpClient.post('/auth/signup', data);
        return response;
    }

    /**
     * Logs in a user with the provided credentials.
     * @param {Object} data - The login data including email and password.
     * @returns {Promise<Object>} The response from the server after attempting to log in the user.
     */
    async login(data) {
        const response = await bexHttpClient.post('/auth/login', data);
        return response;
    }

    /**
     * Logs in an admin user with the provided credentials.
     * @param {Object} data - The admin login data including email and password.
     * @returns {Promise<Object>} The response from the server after attempting to log in the admin user.
     */
    async adminLogin(data) {
        const response = await bexHttpClient.post('/auth/admin-login', data);
        return response;
    }

    /**
     * Logs out the current user.
     * @returns {Promise<Object>} The response from the server after attempting to log out the user.
     */
    async logout() {
        const response = await bexHttpClient.post('/auth/logout');
        return response;
    }

    /**
     * Resets a user's password using a token.
     * @param {string} token - The token for resetting the password.
     * @param {string} newPassword - The new password to set.
     * @returns {Promise<Object>} The response from the server after attempting to reset the password.
     */
    async resetPasswordWithToken(token, newPassword) {
        console.log('resetPasswordWithToken');
        const response = await bexHttpClient.post(`/auth/reset-password-with-token/${token}`, { newPassword: newPassword });
        return response;
    }

    /**
     * Initiates the forgot password process for a given email.
     * @param {string} email - The email address to send the password reset link to.
     * @returns {Promise<Object>} The response from the server after attempting to initiate the forgot password process.
     */
    async forgotPassword(email) {
        const response = await bexHttpClient.post('/auth/forgot-password', { email: email });
        return response;
    }

    /**
     * Checks the validity of a password reset token.
     * @param {string} token - The token to check.
     * @returns {Promise<Object>} The response from the server indicating whether the token is valid.
     */
    async checkResetPasswordToken(token) {
        const response = await bexHttpClient.get(`/auth/check-reset-password-token/${token}`);
        return response;
    }

    /**
     * Resets a user's password with the provided email.
     * @param {string} email - The email address of the user whose password is to be reset.
     * @returns {Promise<Object>} The response from the server after attempting to reset the password.
     */
    async resetPassword(email) {
        const response = await bexHttpClient.post(`/auth/reset-password/${email}`);
        return response;
    }

    /**
     * Resets an admin's password with the provided email and new password.
     * @param {string} email - The email address of the admin whose password is to be reset.
     * @param {string} password - The new password to set.
     * @returns {Promise<Object>} The response from the server after attempting to reset the admin's password.
     */
    async resetAdminPassword(email, password) {
        const response = await bexHttpClient.post(`/auth/reset-admin-password/${email}`, { password });
        return response;
    }

    /**
     * Verifies a user's account with the provided token.
     * @param {string} token - The token to verify the account.
     * @returns {Promise<Object>} The response from the server after attempting to verify the account.
     */
    async verify(token) {
        const response = await bexHttpClient.get(`/auth/verify`, { params: { token: token } });
        return response;
    }

    /**
     * Sends an email to the provided email address.
     * @param {string} email - The email address to send the email to.
     * @returns {Promise<Object>} The response from the server after attempting to send the email.
     */
    async sendEmail(email) {
        const response = await bexHttpClient.post(`/auth/send-email/${email}`);
        return response;
    }
}


const authService = new AuthService()
export { authService }
