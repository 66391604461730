import React from 'react'
import { Outlet } from 'react-router-dom'
import { OpCodeBuilderProvider } from './OpcodeBuider/OpCodeBuilderHAndC/OpCodeBuilderContext'

const OpCodeWrapper = () => {
    return (
        <OpCodeBuilderProvider>
            <Outlet />
        </OpCodeBuilderProvider>
    )
}

export default OpCodeWrapper