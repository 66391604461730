import SpeechToTextDashboard from './SpeechToText';
import EmailExtractionDashboard from './EmailExtraction';
import LegalContractDashboard from './LegalContract';
import TextToSpeechDashboard from './TextToSpeech';
import WebEngagement from './WebEngagement';
import HrAssistant from './HrAssistant';
import HotelOperationsDashboard from './HotelOperations';

export const assistantComponents = {
  '667538b01444181354ac98eb': EmailExtractionDashboard,
  '6675395ea13370ac974a2e93': LegalContractDashboard,
  '66753975a13370ac974a2e96': SpeechToTextDashboard,
  '6675398ba13370ac974a2e99': TextToSpeechDashboard,
  '66864d52e79314bdffee06d9': WebEngagement,
  '66a7091678a11bfed8614831': HrAssistant,
  '66daa0018370f6a851642c41': HotelOperationsDashboard
};
