import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Switch } from "components/ui/switch"
import { Controller } from 'react-hook-form'
import React from 'react'

const FineTuning = ({control, requiredSpan}) => {

    return (
        <div className='space-y-5'>
            <div>
                <Label htmlFor="parameters.max_seq_length">Max Sequence Length</Label>
                <Controller
                    name="parameters.max_seq_length"
                    control={control}
                    render={({ field }) => (
                        <Input {...field} type="number" placeholder="Enter max sequence length" />
                    )}
                />
            </div>
            <div>
                <Label htmlFor="parameters.response_template">Response Template {requiredSpan()}</Label>
                <Controller
                name="parameters.response_template"
                control={control}
                render={({ field }) => (
                    <Input {...field} placeholder="Enter response template" />
                )}
                />
            </div>
    
            <div>
                <Label htmlFor="parameters.gpu">GPU {requiredSpan()}</Label>
                <Controller
                name="parameters.gpu"
                control={control}
                render={({ field }) => (
                    <Input {...field} placeholder="Enter GPU configuration" />
                )}
                />
            </div>
    
            <div className="flex items-center space-x-2">
                <Controller
                name="parameters.group_by_name"
                control={control}
                render={({ field }) => (
                    <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    />
                )}
                />
                <Label htmlFor="parameters.group_by_name">Group by Name</Label>
            </div>
        </div>
    )
}

export default FineTuning