import { bexHttpClient } from '../../clients';

/**
 * Service class for performing assistant instances operations.
 */
class ActivityLogService {

    /**
     * Fetches system activity logs
     * @returns {Promise<Object>} The response from the server returns list of activities.
     */
    async getActivityLog() {
        const response = await bexHttpClient.get(`/admin/get-system-activities`);
        return response;
    }

}


const activityLogService = new ActivityLogService()
export { activityLogService }
