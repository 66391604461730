import React, { createContext, useState, useEffect, useContext } from 'react';
import handler from 'pages/Dashboard/SubOrganization/OpCodes/OpcodeBuider/OpCodeBuilderHAndC/OpCodeBuilderHandler';
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { opcodeService } from 'api/services/BEX/opcode.service';
export const OpCodeBuilderContext = createContext({});

export const STEP_TYPES = [ 
    {id: 0, name: "Identification"}, 
    {id: 1, name: "LLM"}, 
    {id: 2, name: "LLM-Stream"}, 
    {id: 3, name: "Non-LLM"}, 
    // {id: 4, name: "Condition"},
    // {id: 5, name: "Loop"},
]

export const initialInputGroups = [{
    step_id: window?.crypto?.randomUUID(),
    step_type: "Identification",
    unique_name: "",
    model_type: "",
    input: "",  
    output: { Output: "" },
}]

export const OpCodeBuilderProvider = ({ organizationId=null, subOrganizationId=null, children }) => {
    const [opCodes, setOpCodes] = useState([]);
    const [isOpcodeLoading, setIsOpcodeLoading] = useState([]); 
    const [selectedOpCode, setSelectedOpCode] = useState(""); 
    const [constantStepTypes, setConstantStepTypes] = useState(STEP_TYPES)
    const { selectedOrganization } = useContext(OrganizationContext);
    const { selectedSubOrganization } = useContext(SubOrgContext);
    const  orgId = organizationId ? organizationId : selectedOrganization?._id 
    const  subOrgId = subOrganizationId ? subOrganizationId : selectedSubOrganization?._id 

    const actions = (action) => {
        const { type, payload } = action;
        switch (type) {
            case "SET_OP_CODES":
                return setOpCodes(payload);
            case "SET_SELECTED_OPCODE":
                return setSelectedOpCode(payload);
            // case "SET_USER_INPUT":
            //     return setuserInput(payload);
            case "SET_CONSTANT_TYPES":
                return setConstantStepTypes(payload);
            default:
                console.error(`Unhandled action type: ${type}`);
                return null;
        }
    };
    
    const handleGetSavedOpcodes = async () => {
        try {
            setIsOpcodeLoading(true)
            console.log("getting opcodes")
            const res = await opcodeService.listOpCodes(orgId, subOrgId);
            console.log(res);
            setOpCodes(res.data)
        } catch (error) {
            console.error("Failed to get saved models", error);
        } finally {
            setIsOpcodeLoading(false)
        }
    };

    function validateSteps(steps) {
        let errorMessages = [];
        
        steps.forEach((step, index) => {
            let stepErrors = [];
    
            // Validate LLM steps
            if (step.step_type === "LLM" || step.step_type === "LLM-Stream" || step.step_type === "Identification") {
                if (!step?.model_type) {
                    stepErrors.push("no model type");
                }
                if (!step?.unique_name) {
                    stepErrors.push("no instruction");
                }
                // Check if dynamic_inputs is an empty 
                if (step.input.trim() === "") {
                    stepErrors.push("no step input");
                } 
            } 
            // Validate Non-LLM steps
            else if (step.step_type === "Non-LLM") {
                if (!step?.registry_key) {
                    stepErrors.push("no function selected");
                }
                if (step.registry_key === "KNOWLEDGE_RETRIEVAL_API" && !step?.input?.collection_id) {
                    stepErrors.push("no collection selected");
                }
                if (!step?.input || Object.keys(step.input).length === 0) {
                    stepErrors.push("no fixed inputs");
                }
                if (step?.input?.query_text?.trim() === "") {
                    stepErrors.push("no step input");
                }
            }
    
            // Validate output for both step types
            if (!step?.output?.Output && step.step_type !== "Identification") {
                stepErrors.push("no output variable");
            } else if(step.step_type !== "Identification") {
                const { isValid, message } = handler.validateOutputName(step?.output?.Output);
                if (!isValid) {
                    stepErrors.push(`invalid output variable: ${message}`);
                }
            }
    
            if (stepErrors.length > 0) {
                errorMessages.push(`Step ${index + 2} has ${stepErrors.join(', ')}.`);
            }
        });
    
        return {
            isValid: errorMessages.length === 0,
            message: errorMessages.length > 0 ? errorMessages.join(' ') : "All steps are valid."
        };
    }
    
    useEffect(() => {
        handleGetSavedOpcodes()
    }, []);

    const value={
        opCodes,
        isOpcodeLoading,
        selectedOpCode,
        constantStepTypes,
        orgId,
        subOrgId,
        actions, 
        handleGetSavedOpcodes,
        validateSteps,
    }
    return (
        <OpCodeBuilderContext.Provider value={value}>
            {children}
        </OpCodeBuilderContext.Provider>
    );
}