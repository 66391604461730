import React from 'react'
import { Textarea } from "components/ui/textarea"
import { Slider } from "components/ui/slider"
import { Switch } from "components/ui/switch"
import FineTuning from './FineTuning'
import PromptTuning from './PromptTuning'
import { tasks, baseModels} from './data'
import { Controller } from 'react-hook-form'
import { Label } from 'components/ui/label'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'

const Parameters = ({control, watch, requiredSpan}) => {
    const tuningMethod = watch('tuning_method')
    const watchedValues = watch()
    const {accumulate_steps, batch_size, learning_rate, num_epochs, max_input_tokens, max_output_tokens} = watchedValues.parameters
    
    return (
        <div className="space-y-5">
            <h3 className="text-lg font-semibold">
                Parameters ({`${tuningMethod === 'fine'? "Fine " : "Prompt"} Tuning`})
            </h3>

            <div>
                <Label htmlFor="parameters.name">Name {requiredSpan()}</Label>
                <Controller
                name="parameters.name"
                control={control}
                render={({ field }) => (
                    <Input {...field} placeholder="Enter experiment name" />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.task_id">Task ID {requiredSpan()}</Label>
                <Controller
                name="parameters.task_id"
                control={control}
                render={({ field }) => (
                    <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select a task" />
                        </SelectTrigger>
                        <SelectContent>
                            {tasks.map((task) => (
                                <SelectItem key={task} value={task}>
                                    {task}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.base_model">Base Model {requiredSpan()}</Label>
                <Controller
                name="parameters.base_model"
                control={control}
                render={({ field }) => (
                    <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select a base model" />
                        </SelectTrigger>
                        <SelectContent>
                            {baseModels[tuningMethod || 'fine'].map((model) => (
                                <SelectItem key={model} value={model}>
                                    {model}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.accumulate_steps">Accumulate Steps: {accumulate_steps}</Label>
                <Controller
                name="parameters.accumulate_steps"
                control={control}
                render={({ field }) => (
                    <Slider
                    min={1}
                    max={128}
                    step={1}
                    value={[field.value]}
                    onValueChange={(value) => {
                        field.onChange(value[0])
                    }}
                    />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.batch_size">Batch Size: {batch_size}</Label>
                <Controller
                name="parameters.batch_size"
                control={control}
                render={({ field }) => (
                    <Slider
                    min={1}
                    max={16}
                    step={1}
                    value={[field.value]}
                    onValueChange={(value) => {
                        field.onChange(value[0])
                    }}
                    />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.learning_rate">Learning Rate: {learning_rate?.toFixed(2)}</Label>
                <Controller
                name="parameters.learning_rate"
                control={control}
                render={({ field }) => (
                    <Slider
                    min={0.01}
                    max={0.5}
                    step={0.01}
                    value={[field.value]}
                    onValueChange={(value) => {
                        field.onChange(value[0])
                    }}
                    />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.num_epochs">Number of Epochs: {num_epochs}</Label>
                <Controller
                name="parameters.num_epochs"
                control={control}
                render={({ field }) => (
                    <Slider
                    min={1}
                    max={50}
                    step={1}
                    value={[field.value]}
                    onValueChange={(value) => {
                        field.onChange(value[0])
                    }}
                    />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.verbalizer">Verbalizer</Label>
                <Controller
                name="parameters.verbalizer"
                control={control}
                render={({ field }) => (
                    <Textarea {...field} placeholder="Enter verbalizer" value={field.value} />
                )}
                />
            </div>

            {tuningMethod === 'fine' && (
                <FineTuning control={control} requiredSpan={requiredSpan}/>
            )}

            {tuningMethod === 'prompt' && (
                <PromptTuning control={control}  max_input_tokens={max_input_tokens} max_output_tokens={max_output_tokens} requiredSpan={requiredSpan}/>
            )}

            <div className="flex items-center space-x-2">
                <Controller
                name="parameters.auto_update_model"
                control={control}
                render={({ field }) => (
                    <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    />
                )}
                />
                <Label htmlFor="parameters.auto_update_model">Auto Update Model</Label>
            </div>

        </div>
  )
}

export default Parameters