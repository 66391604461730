/* eslint-disable max-lines */
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from 'components/ui/button';
import { Label } from "components/ui/label";
import { Context } from 'context/GlobalState';
import InputValidation from 'components/InputValidation';
import { Textarea } from 'components/ui/textarea';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { OrganizationContext } from 'context/OrganizationContext';
import { SubOrgContext } from 'context/SubOrganizationContext';
import { instructionService } from 'api/services/BEX/instruction.service';
import WatsonModelSelectionModal from '../Common/WatsonModelSelectionModal';
import WatsonXConfiguration from '../Common/WatsonXConfiguration';
import GroqModelSelectionModal from '../Common/GroqModelSelectionModal';
import GroqConfiguration from '../Common/GroqConfiguration';
import Anatomy from '../Common/Anatomy';

export default function UpdateInstructionDialog({ open, setOpen, modelDetails, setModelDetails, model_type }) {
    const [instructionForm, setInstructionForm] = useState(null)
    const [parameters, setParameters] = useState(null)
    const [name, setName] = useState(modelDetails.unique_name || "")
    const [description, setDescription] = useState(modelDetails.modelDescription || "")
    const [selectedModel, setSelectedModel] = useState("")
    const [isUpdating, setIsUpdating] = useState(false)
    const {addNewNotifcation} = useContext(Context)
    const {selectedOrganization} = useContext(OrganizationContext)
    const {selectedSubOrganization} = useContext(SubOrgContext)
    const {user} = useContext(Context)

    useEffect(() => {
        if(modelDetails){
            setName(modelDetails.unique_name)
            setDescription(modelDetails.modelDescription)
            const extractedSegment = labHelper.extractValues(modelDetails.prompt);

            if (!extractedSegment['Instruction:']){
                setInstructionForm({
                    instruction: modelDetails.prompt || '',
                    context: '',
                    targetFormat: '',
                    sampleInput: '',
                    sampleOutput: '',
                    inputData: '',
                })
            } else {
                setInstructionForm({
                    instruction: extractedSegment['Instruction:'] || '',
                    context: extractedSegment['Context:'] || '',
                    targetFormat: extractedSegment['Target Format:'] || '',
                    sampleInput: extractedSegment['Sample Input:'] || '',
                    sampleOutput: extractedSegment['Sample Output:'] || '',
                    inputData: '',
                })
            }
            
            let parametersObj = null
            if(model_type === "IBM"){
                setSelectedModel(modelDetails?.modelId)
                parametersObj = {
                    decoding_method: modelDetails?.parameters?.decoding_method || "",
                    temperature: modelDetails?.parameters?.temperature ,
                    min_new_tokens: modelDetails?.parameters?.min_new_tokens,
                    max_new_tokens: modelDetails?.parameters?.max_new_tokens ,
                    top_p: modelDetails?.parameters?.top_p , 
                    top_k: modelDetails?.parameters?.top_k ,
                    stop_sequences: modelDetails?.parameters?.stop_sequences,
                    repetition_penalty: modelDetails?.parameters?.repetition_penalty 
                }
            } else if(model_type === "Groq"){
                setSelectedModel(modelDetails?.modelId)
                parametersObj = {
                   max_tokens: modelDetails?.parameters?.max_tokens,
                   n: modelDetails?.parameters?.n,
                   top_p: modelDetails?.parameters?.top_p,
                   stop: modelDetails?.parameters?.stop,
                   temperature: modelDetails?.parameters?.temperature,
                   frequency_penalty: modelDetails?.parameters?.frequency_penalty,
                   presence_penalty: modelDetails?.parameters?.presence_penalty,
                   response_format:  modelDetails?.parameters?.response_format || {"type": "text"},
                }
            }
    
            setParameters(parametersObj)
        }

    }, [open])

    const handleUpdate = async () => {
        let { isValid: isUniqueNameValid, message: uniqueNameMessage } = labHelper.validateUniqueName(name.trim());
        if (!isUniqueNameValid) {
            addNewNotifcation(uniqueNameMessage, "warning");
            return;
        }

        if(!instructionForm.instruction.trim()){
            addNewNotifcation("The instruction field cannot be left empty.", "warning");
            return;
        }

        try{
            setIsUpdating(true);
            const body = {
                instructionId: modelDetails._id,
                unique_name: name,
                modelId: selectedModel,
                prompt: labHelper.ConstructPromptUnified({
                    instruction: instructionForm.instruction.trim(),
                    context: instructionForm.context.trim(),
                    target_format: instructionForm.targetFormat.trim(),
                    sample_input: instructionForm.sampleInput.trim(),
                    sample_output: instructionForm.sampleOutput.trim(),
                }),
                org_id: selectedOrganization._id,
                sub_org_id: selectedSubOrganization._id,
                user_id: user._id,
                modelDescription: description,
                placeholders: {},
                metadata: {}
              }

            if(model_type === "IBM"){
                body.model_type = "IBM"
                body.parameters = {
                    min_new_tokens: 10,
                    max_new_tokens: parameters.max_new_tokens,
                    temperature: parameters.decoding_method === "sampling" ? parameters.temperature : 0,
                    repetition_penalty: parameters.repetition_penalty,
                    stop_sequence: parameters.stop_sequences,
                    top_k: parameters.decoding_method === "sampling" ? parameters.top_k : 50,
                    top_p: parameters.decoding_method === "sampling" ? parameters.top_p : 1,
                    decoding_method: parameters.decoding_method
                }

            } else if(model_type === "Groq"){
                body.model_type = "Groq"
                body.parameters ={
                    max_tokens: parameters.max_tokens,
                    n: parameters.n,
                    top_p: parameters.top_p,
                    stop: parameters.stop,
                    temperature: parameters.temperature,
                    frequency_penalty: parameters.frequency_penalty,
                    presence_penalty: parameters.presence_penalty,
                    response_format: {"type": "text"},
                }
            } else {
                addNewNotifcation("No Model is specified", "warning");
                return
            }

            console.log(body);
            const res = await instructionService.updateModel(body);
            console.log("result", res);
            setModelDetails({...body})
            addNewNotifcation("Instruction updated successfully.", "success");
            setOpen(false);
        }
        catch(error){
            console.log("Instruction not saved.", error);
            addNewNotifcation("Instruction not saved. Please try again.", "danger");
            
        } finally {
            setIsUpdating(false)
        }

    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="max-w-[850px] w-full">
                <DialogHeader> 
                    <DialogTitle> Update Instruction </DialogTitle>
                </DialogHeader>

                <div className='flex flex-col'>
                    {model_type === 'IBM'?(
                        <div className='flex gap-2'>
                            <div className="w-full">
                                <WatsonModelSelectionModal 
                                finalModel={selectedModel}
                                onModelSelect ={setSelectedModel}
                                />
                            </div>
                            <WatsonXConfiguration 
                            parametersWatsonX={parameters} 
                            setParametersWatsonX={setParameters} 
                            selectedModel={selectedModel}
                            />
                        </div>
                    )
                    : model_type === 'Groq'?
                        <div className='flex gap-2'>
                            <div className="w-full">
                                <GroqModelSelectionModal 
                                finalModel={selectedModel}
                                onModelSelect ={setSelectedModel}
                                />
                            </div>
                        
                            <GroqConfiguration 
                            parametersGroq={parameters} 
                            setParametersGroq={setParameters} 
                            />
                        </div>
                    
                    :''}
                    <ScrollArea className="max-h-[360px]">
                        <div className="space-y-2 px-1 pt-4">
                            <div>
                                <Label htmlFor="name" className="text-right">
                                    Unique Name
                                </Label>
                                <InputValidation
                                    id="name"
                                    value={name}
                                    disabled={true}
                                    onChange={(e)=>{setName(e.target.value)}}
                                    className="col-span-3"
                                    placeholder="Enter a unique name for your instruction"
                                    validation={labHelper.validateUniqueName}
                                />  
                            </div>
                            <div>
                                <Label htmlFor="description" className="text-right">
                                    Description
                                </Label>
                                <Textarea
                                    id="description"
                                    value={description}
                                    onChange={(e)=>{setDescription(e.target.value)}}
                                    className="col-span-3"
                                    placeholder="Enter description for your instruction"
                                />
                            </div>
                        </div>
                        <Anatomy instructionForm={instructionForm} setInstructionForm={setInstructionForm} />
                    </ScrollArea>
                </div>

                <DialogFooter className='flex w-full justify-end gap-3'>
                    <Button onClick={() => setOpen(false)} variant='outline'>  Cancel </Button>
                    <Button disabled={isUpdating} onClick={handleUpdate}>Update</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}