import React from 'react';

// Load package.json using require
const packageJson = require('../../package.json'); // Adjust the path as needed

export default function Footer() {
    const { name, version } = packageJson;  // Destructure the properties

    return (
        <footer className='bg-white text-[var(--primary-color)] text-[20px] py-3 p-8 flex border-t'>
            <div className='w-full font-[500] flex justify-between items-center'>
                <div className="flex gap-1 text-muted-foreground text-sm">
                    <p> Version: {version}</p> {/* Display version */}
                    <p> &copy; {new Date().getFullYear()} {name}. All rights reserved.</p> {/* Display copyright */}
                </div>
                <h2>Executing with Agility, Velocity, and Precision </h2>

            </div>
        </footer>
    );
}
