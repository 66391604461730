import React, { useState, Children, cloneElement, forwardRef, useEffect } from 'react'
import { cn } from "lib/utils"
import { Button } from 'components/ui/button'
import { ChevronRight } from 'lucide-react'

export const StepsContainer = forwardRef(({ children, className, disabled = false, next = true, 
    prev = true, submitDisabled = false, stepButtons, useEffectCallBack, customNext, customPrev, onSubmit, submitWord = 'Submit' }, ref) => {
    const [currentStep, setCurrentStep] = useState(1)
    const steps = Children.toArray(children)
    const lastStep = currentStep === steps.length ? true : false

    useEffect(() => {
        useEffectCallBack && useEffectCallBack(currentStep)
    }, [currentStep])
    
    const handleNext = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1)
        }
    }

    const handlePrev = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const nextLogic = () => {
        return lastStep? onSubmit? onSubmit() : '' : customNext? customNext(currentStep, handleNext) : handleNext()
    }

    const prevLogic = () => {
        return customPrev? customPrev(currentStep, handlePrev) : handlePrev()
    }

    return (
        <div className={cn("w-full mx-auto", className)} ref={ref}>
            <div className="flex items-center justify-between mb-5">
                {steps.map((_, index) => (
                    <React.Fragment key={index}>
                        <StepIndicator 
                            number={index + 1} 
                            isActive={index + 1 === currentStep} 
                            isCompleted={index + 1 < currentStep}
                        />
                        {index < steps.length - 1 && (
                            <div className="flex-1 h-0.5 bg-muted-foreground" aria-hidden="true" />
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div className="mb-8">
                {cloneElement(steps[currentStep -1], { stepNumber: currentStep })}
            </div>

            {/* StepButtons is passed as a prop and used here */}
            {stepButtons ? stepButtons({ handleNext, handlePrev, currentStep, steps }) : (
                <div className="flex justify-between mt-auto">
                    <Button 
                        variant='ghost'
                        onClick={prevLogic} 
                        type="button"
                        disabled={disabled || !prev || currentStep === 1}
                        className={`${currentStep === 1 ? 'hidden' : 'flex'}`}
                        aria-label="Go to previous step"
                    >
                        Previous
                    </Button>
                    <Button 
                        className='ml-auto'
                        type="button"
                        onClick={nextLogic} 
                        disabled={disabled || lastStep ? submitDisabled : !next }
                        aria-label={currentStep === steps.length ? "Finish" : "Go to next step"}
                    >
                        {lastStep ? submitWord : 'Next'}
                        {!lastStep && <ChevronRight className="h-4 w-4" />}
                    </Button>
                </div>
            )}
        </div>
    )
})

function StepIndicator({ number, isActive, isCompleted }) {
    return (
        <div
            className={cn(
                "flex h-8 w-8 items-center justify-center rounded-full text-sm font-medium",
                isActive && "bg-primary text-primary-foreground",
                isCompleted && "bg-primary text-primary-foreground",
                !isActive && !isCompleted && "bg-muted text-muted-foreground"
            )}
            aria-current={isActive ? "step" : undefined}
        >
            {isCompleted ? (
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
            ) : (
                number
            )}
        </div>
    )
}

export function Step({ children }) {
    return (
        <div className="space-y-4">
            {children}
        </div>
    )
}


