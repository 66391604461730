import { bexHttpClient } from "api/clients";

class OpCodeService {

    /**
     * Creates a new OpCode.
     * @param {Object} data - The data to create a new OpCode.
     * @returns {Promise<Object>} The response from the server regarding OpCode creation.
     */
    async createOpCode(data) {
        const response = await bexHttpClient.post(`/redis/opcodes/opcode/create`, data);
        return response;
    }

    /**
     * Updates an existing opcode (workflow).
     * @param {Object} data - The updated data for the opcode.
     * @returns {Promise<Object>} The response containing the updated opcode.
     */
    async updateOpCode(data) {
        const response = await bexHttpClient.put(`/redis/opcodes/opcode/update`, data);
        return response;
    }

    /**
     * Fetches OpCodes by opcode_ids.
     * @param {Object} data - The unique names to fetch OpCodes.
     * @returns {Promise<Object>} The response containing the list of OpCodes.
     */
    async getOpCode(data) {
        const response = await bexHttpClient.get(`/redis/opcodes/opcode/get`, data);
        return response;
    }

    /**
     * Fetches OpCodes by opcode_ids.
     * @param {string} organizationId - The ID of the organization.
     * @param {string} subOrganizationId - The ID of the sub-organization.
     * @param {string} opcodeId - The ID of the opcode.
     * @returns {Promise<Object>} The response containing the list of OpCodes.
     */
    async getOpCodeById(organizationId, subOrganizationId, opcodeId) {
        const response = await bexHttpClient.get(`/redis/opcodes/opcode/get-by-id?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}&opcodeId=${opcodeId}`);
        return response;
    }

    /**
     * Lists all opcode_ids
     * @param {string} organizationId - The ID of the organization.
     * @param {string} subOrganizationId - The ID of the sub-organization.
     * @returns {Promise<Array>} The response containing the list of models.
     */
    async listOpCodes(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`/redis/opcodes/opcode/list?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`);
        return response;
    }

    /**
     * Deletes opcodes by opcode ids
     * @param {Object} data - The payload containing the list of opcode_ids to delete.
     * @returns {Promise<Object>} The response confirming the deletion.
     */
    async deleteOpCodes(data) {
        const response = await bexHttpClient.delete(`/redis/opcodes/opcode/delete`, {data});
        return response;
    }
}

const opcodeService = new OpCodeService();
export { opcodeService };