import React from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Button } from "components/ui/button"
import { X } from 'lucide-react'
import { Label } from 'components/ui/label'

const StepIdSelector = ({ label, selectedSteps, availableSteps, setInputGroups, isTrue, index, inputGroup }) => {
  const handleStepSelect = (stepId) => {
    if (stepId && stepId !== '_default' && !selectedSteps.includes(stepId)) {
        const newInputGroup = {
            ...inputGroup, 
            next_step:{
                ...inputGroup.next_step,
                 [isTrue? "true_branch" : "false_branch"]: [...selectedSteps, stepId]
                }
            }
        setInputGroups(prev => prev.map((group, i) => i === index? newInputGroup : group))
    }
  }

  const handleStepRemove = (stepId) => {
    const newInputGroup = {
        ...inputGroup, 
        next_step:{
            ...inputGroup.next_step, 
            [isTrue? "true_branch" : "false_branch"]: selectedSteps.filter(id => id !== stepId)
        }
    }
    setInputGroups(prev => prev.map((group, i) => i === index? newInputGroup : group))
  }

  const getStepName = (id) => {
    const foundStep = availableSteps.find(step => step.nextStep.value === id)
    return foundStep?.nextStep?.label? foundStep.nextStep.label : "name"
  }

  const remainingSteps = availableSteps.filter(step => !selectedSteps.includes(step.nextStep.value))

    return (
        <div className="">
            <Label className="block text-sm font-medium text-gray-700">{label}</Label>
            <div className="space-y-2 mb-2">
                {selectedSteps.map((stepId) => (
                    <div key={stepId} className="flex items-center space-x-2">
                        <div className="flex-grow bg-gray-100 rounded-md px-3 py-2 text-sm">{getStepName(stepId)}</div>
                        <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => handleStepRemove(stepId)}
                        aria-label={`Remove step ${getStepName(stepId)}`}
                        >
                            <X className="h-4 w-4" />
                        </Button>
                    </div>
                ))}
            </div>
            {remainingSteps.length > 0 ? (
                <Select onValueChange={handleStepSelect} value='_default'>
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select a step" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="_default">Select a step</SelectItem>
                        {remainingSteps.map((step) => (
                            <SelectItem key={step.nextStep.value} value={step.nextStep.value}>
                                {step.nextStep.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
        ) : (
            <div className="text-sm text-gray-500 mt-2 pl-2">All steps have been selected</div>
        )}
        </div>
    )
}

export default StepIdSelector

