import { bexHttpClient } from "api/clients";

class OrganizationIndex {

    /**
     * Creates a new vector index (Instruction).
     * @param {Object} data - The data for the new vector index.
     * @returns {Promise<Object>} The response containing the vector index.
     */
    async createSubOrganizationVectorIndex(data) {
        const response = await bexHttpClient.post(`/db/sub-organizations/create-sub-organization-vector-index`, data);
        return response;
    }

    /**
     * Deletes an existing vector index
     * @param {Object} data - The deleted data for the vector index.
     * @returns {Promise<Object>} The response containing the vector index.
     */
    async deleteSubOrganizationVectorIndex(data) {
        const response = await bexHttpClient.delete(`/db/sub-organizations/delete-sub-organization-vector-index`, {data});
        return response;
    }

    /**
     * Gets sub organization vector index
     * @param {string} organizationId - The ID of the organization.
     * @param {string} subOrganizationId - The ID of the sub-organization.
     * @returns {Promise<Object>} The response containing the vector index.
     */
    async getOrganizationVectorIndex(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`/db/sub-organizations/get-sub-organization-vector-index?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`);
        return response;
    }

    /**
     * Lists organization vector indexes
     * @param {string} organizationId - The ID of the organization.
     * @returns {Promise<Object>} The response containing the vector index.
     */
    async listOrganizationVectorIndex(organizationId) {
        const response = await bexHttpClient.get(`/db/organizations/list-sub-organization-vector-index/${organizationId}`);
        return response;
    }

}

const organizationIndex = new OrganizationIndex();
export { organizationIndex };