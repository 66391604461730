import React from 'react';
import Laboratory from "./components/Laboratory.jsx";
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout.jsx';


export default function AILab() {
    return (
        <NestedPageLayout>
            <Laboratory/>
        </NestedPageLayout>
    );
}

