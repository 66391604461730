import { bexHttpClient } from '../../clients';


/**
 * Service class for performing assistant instances operations.
 */
class AssistantInstanceService {

    /**
     * Fetches assistant instances by organization and sub organization id.
     * @returns {Promise<Object>} The response from the server returns list of assistants.
     */
    async getSubOrgAssistantInstance(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`/db/assistant-instances/get-assistant-instances?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`);
        return response;
    }

    /**
     * Fetches assistant instances by organization and sub organization id.
     * @returns {Promise<Object>} The response from the server returns list of assistants.
     */
    async getSubOrgAssistantInstanceById(organizationId, subOrganizationId, assistantInstanceId) {
        const response = await bexHttpClient.get(`/db/assistant-instances/get-assistant-instances?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}&assistantInstanceId=${assistantInstanceId}`);
        return response;
    }

    /**
     * add new assistant instance.
     * @param {Object} data - The new assistant instance data including assistantId, organizationId, subOrganizationId, status.
     * @returns {Promise<Object>} The response from the server returns new assistant instance document from mongoDB.
     */
    async addAssistantInstance(data) {
        const response = await bexHttpClient.post(`/db/assistant-instances/add-assistant-instance`, data);
        return response;
    }

    /**
     * update assistant instance.
     * @param {Object} data - The updated assistant instance data including id, organizationId, subOrganizationId, status, name and description.
     * @returns {Promise<Object>} The response from the server returns updated assistant instance document from mongoDB.
     */
    async updateAssistantInstance(data) {
        const response = await bexHttpClient.put(`/db/assistant-instances/update-assistant-instance`, data);
        return response;
    }

    /**
     * delete assistant instance.
     * @param {Object} data - The deleted assistant instance data including assistant id, organizationId and subOrganizationId.
     * @returns {Promise<Object>} The response from the server returns response assistant of deleting status.
     */
    async deleteAssistant(data) {
        const response = await bexHttpClient.delete(`/db/assistant-instances/delete-assistant-instance`, {data: data});
        return response;
    }

}


const assistantInstanceService = new AssistantInstanceService()
export { assistantInstanceService }
