/* eslint-disable max-lines */
import React from 'react'
import InputGroup from './InputGroup'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/textarea'
import { Step } from 'components/NewDesignComponents/VerticalSteps'
import { Input } from 'components/ui/input'

const InputGroups = ({inputGroupsState, otherProps, stepTypesState, userInputState}) => {
    const {inputGroups, setInputGroups} = inputGroupsState
    const {stepTypes, setStepTypes} = stepTypesState
    const {userInput, setuserInput} = userInputState
    const {constantStepTypes, opType, checkOpcodeName, orgId=0, subOrgId=0, parentId} = otherProps 

    const handleAddGroup = (position, currentStep) => {
        console.log(`Add step ${position} ${currentStep}`)
        let newInputGroups = [...inputGroups];
        const newStepType = [...stepTypes];
        const currentIndex = Number(currentStep) - 2

        // Find the index of the current step  
        if (currentIndex === -1) {
            console.error("Current step not found");
            return;
        }

        const newInputGroup = {
            step_id: window?.crypto?.randomUUID(),
            step_type: `${opType === 'AutoOp'? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: "",  
            output: { Output: "", Output_map: {} },
            next_step: [],
            accumulate_output: true,
            is_first_step: false
        }
    
        if (position === 'before' || position === 'after') {
            const indexOffset = position === 'before' ? 0 : 1;
            newInputGroups.splice(currentIndex + indexOffset, 0, newInputGroup);
            //after
            if(currentIndex !== 0){
                newInputGroups = newInputGroups.map((group, i) => i === currentIndex? {...group, next_step:[newInputGroup.step_id]} : group)
            }
            newStepType.splice(currentIndex + indexOffset, 0, constantStepTypes[1].name);
        } else {
            console.error("Invalid position");
        }
    
        setInputGroups(newInputGroups);
        setStepTypes(newStepType)
    };

    const handleTypeSelect = (value, index) => {
        const newType = constantStepTypes.find(item => item.name === value).name
        setStepTypes(stepTypes.map((type,i) => i === index? newType : type))
        let newInputGroup = {}
        console.log(value)

        const llmObj = {
            step_id: "",
            step_type: `${newType === "LLM"? "LLM" : "LLM-Stream"}`,
            unique_name: "",
            model_type: "",
            input: "",  
            output: { Output: "", Output_map: {} },
            next_step: [],
            accumulate_output: true,
            is_first_step: false
        }

        if (newType === "LLM") {
            newInputGroup = llmObj
        } else if (newType === "LLM-Stream") {
            newInputGroup = llmObj
        } else if (newType === "Non-LLM") {
            newInputGroup = {
                step_id: "",
                step_type: "Non-LLM",
                registry_key: "KNOWLEDGE_RETRIEVAL_API",
                input: {
                    "org_id": orgId,
                    "sub_org_id": subOrgId,
                    "collection_id": "",
                    "use_rerank": true,
                    "query_text": "",
                    "top_k": 10,
                    "top_n": 3,
                    "offset": 0,
                    "filters": {},
                    "search_type": "vector",
                },
                output: { Output: "compiled_text" },
                next_step: [],
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Condition"){
            newInputGroup = {
                step_id: "",
                step_type: "Condition",
                unique_name: null,
                model_type: null,
                registry_type: null,
                registry_key: null,
                fixed_inputs: {
                    expression: ""
                },
                dynamic_inputs:[],
                steps:null,
                output: { Output: null, Output_map: null },
                next_step: {
                    true_branch: [],
                    false_branch: []
                },
                accumulate_output: true,
                is_first_step: false
            }
        } else if (newType === "Loop"){
            newInputGroup = {
                step_id: "",
                step_type: "Loop",
                unique_name: null,
                model_type: null,
                registry_type: null,
                registry_key: null,
                fixed_inputs: {
                    collection: "",
                    item: "loopItem",
                    max_iterations: "",
                    delimiters: [""],
                    break_condition: ""
                },
               dynamic_inputs:[],
               steps: [{
                step_id: window?.crypto?.randomUUID(),
                step_type: "LLM",
                unique_name: "",
                model_type: "",
                registry_type: null,
                registry_key: null,
                fixed_inputs: {},
                dynamic_inputs:["real_time_data"],  
                steps:null,
                output: { Output: "", Output_map: null },
                next_step: {branch_name:[]},
                accumulate_output: true,
                is_first_step: true
            }],
               output: { Output: null, Output_map: null },
               next_step: {branch_name: []},
               accumulate_output: true,
               is_first_step: false
            }
        }

        const newInputGroups = inputGroups.map((inputGroup,i) => i === index? {...newInputGroup, step_id:inputGroup.step_id} : inputGroup)
        setInputGroups(newInputGroups)
    }
    
    const handleRemoveGroup = (id) => {
        console.log(`Delete step ${id}`, stepTypes)
        const inputGroupDeleted = inputGroups?.find((_, index) => index === id - 2)

        const updatedInputGroups = inputGroups?.map(group =>{
            if(group?.step_type !== "Condition" ){
                if(group.step_type === 'Identification'){
                    return group
                }

                return group?.next_step[0] === inputGroupDeleted?.step_id? {...group, next_step:[]} : group
            }

            if(group?.step_type === "Condition"){
                const true_branch = group?.next_step?.true_branch?.filter(step => step !== inputGroupDeleted?.step_id)
                const false_branch = group?.next_step?.false_branch?.filter(step => step !== inputGroupDeleted?.step_id)
                return {...group, next_step:{true_branch, false_branch}}
            }

            return group
        } )

        let newInputGroups = updatedInputGroups.filter((_, index) => index !== id - 2)

        // newInputGroups = newInputGroups?.map((group, i) => {
        //     if(group?.step_type === "LLM" || group?.step_type === "LLM-Stream"){
        //         const newData = group?.dynamic_inputs_ids?.filter(input => input.id !== inputGroupDeleted.step_id)
        //         return i === 0 ? group : {
        //             ...group, dynamic_inputs_ids:newData || [],
        //              dynamic_inputs:group?.dynamic_inputs_ids?.length > 0? newData?.map(data=> data.outputVar) : group?.dynamic_inputs
        //         }
        //     }

        //     if(group?.registry_type === 'api'){
        //         const nonLlm = group?.dynamic_inputs?.query_text
        //         return {...group, dynamic_inputs:{query_text:nonLlm === inputGroupDeleted?.output_var? "" : nonLlm}}
        //     }

        //     if(group?.registry_type === 'function'){
        //         const newData = group?.dynamic_inputs?.filter(input => input.id !== inputGroupDeleted?.step_id)
        //         return {...group, dynamic_inputs:newData}
        //     }

        //     return group
        // })

        setInputGroups(newInputGroups)
        setStepTypes(stepTypes.filter((_, index) => index !== id - 2))
    }

    return (
        <div className='space-y-2'>
            <Step
            indicator="1"
            canAddBefore={false}
            canAddAfter={false}
            isDraggable={false}
            canDelete={false}
            itemId={parentId}
            isDisabled={checkOpcodeName()}
            title="Opcode Testing Input"
            >
                <div className='w-full space-y-4'>
                    <div>
                        <Label htmlFor="file-upload">
                            Upload a text file (optional)
                        </Label>
                        <Input
                            id="file-upload"
                            type="file"
                            accept=".txt"
                            disabled
                            className="cursor-pointer"
                            onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                // eslint-disable-next-line no-undef
                                const reader = new FileReader();
                                reader.onload = (event) => {
                                const content = event.target?.result;
                                if (typeof content === 'string') {
                                    setuserInput(content);
                                }
                                };
                                reader.readAsText(file);
                            }
                            }}
                        />
                    </div>
                    <div>
                        <Label htmlFor="user-input">
                            Input for testing an instruction and workflow <span className='text-gray-400'>(real_time_data)</span>
                        </Label>
                        <Textarea
                            id="user-input"
                            value={userInput}
                            disabled
                            className="min-h-[80px]"
                            onChange={(e) => setuserInput(e.target.value)}
                            placeholder="Enter input for execution or upload a text file"
                        />
                    </div>
                </div>
            </Step>
            {inputGroups?.map((inputGroup, index) => (
                <Step
                indicator={`${index + 2}`}
                canAddBefore={false }
                canAddAfter={true}
                isDraggable={false }
                canDelete={index === 0 ? false : true}
                isDisabled={checkOpcodeName()}
                onAddStep={handleAddGroup}
                onDeleteStep={handleRemoveGroup}
                key={inputGroup?.step_id || index}
                title="Opcode Step"
                itemId={parentId}
                step_id={inputGroup.step_id}
                >
                    <div className="flex flex-col gap-2 w-full">
                        <Select onValueChange={(value) => handleTypeSelect(value, index)} 
                        id="step_type" 
                        className="w-full" value={stepTypes[index]}
                        disabled={index === 0}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select step type" />
                            </SelectTrigger>
                            <SelectContent>
                                {index === 0 ? (
                                    <SelectItem key="0" value="Identification">
                                        Identification
                                    </SelectItem>
                                    ) : (
                                    constantStepTypes.slice(1).map((item) => (
                                        <SelectItem key={item.id} value={item.name}>
                                            {item.name}
                                        </SelectItem>
                                    ))
                                )}
                            </SelectContent>
                        </Select>
                        <div className="flex flex-col gap-4">
                            <InputGroup 
                                index={index}
                                inputGroup={inputGroup} 
                                inputGroups={inputGroups}
                                setInputGroups={setInputGroups}
                                userInput={userInput}
                            />
                            <hr/>
                        </div>
                    </div>
                </Step>
                ))}
        </div>
    )
}
export default InputGroups