export const projects = [
    { id: '3018fd35-d2e0-493c-8bc0-939c0ab03f54', name: 'watsonx for IBM Training' },
  ]
  
export const tasks = [
    'classification',
    'question_answering',
    'summarization',
    'retrieval_augmented_generation',
    'generation',
    'code',
    'extraction'
  ]
  
export const baseModels = {
    prompt: ['google/flan-t5-xl-3b', 'ibm/granite-13b-instruct-v2', 'meta-llama/llama-2-13b-chat'],
    fine: ['llama-3-1-8b-instruct', 'allam-1-13b-instruct', 'granite-3b-code-instruct', 'granite-8b-code-instruct', 'granite-20b-code-instruct']
  }

export const commonFields = [
      "project_id",
      "files",
      "tuning_method",
      "deployment_name",
      "parameters.name",
      "parameters.task_id",
      "parameters.base_model",
      "parameters.accumulate_steps",
      "parameters.batch_size",
      "parameters.learning_rate",
      "parameters.num_epochs",
      "parameters.verbalizer",
      "parameters.auto_update_model",
  ]

export const fineTuningFields = [
      "parameters.max_seq_length",
      "parameters.response_template",
      "parameters.gpu",
      "parameters.group_by_name",
  ];
  
export const promptTuningFields = [
      "parameters.max_input_tokens",
      "parameters.max_output_tokens",
      "parameters.tuning_type",
      "parameters.init_text",
      "parameters.init_method",
  ];

export const initialState = {
    tuning_method: "fine",
    project_id: '',
    files:[],
    presignedUrls:[],
    originalFiles:[],
    deployment_name: '',
    parameters: {
        name: '',
        task_id: '',
        base_model: '',
        accumulate_steps: 16,
        batch_size: 16,
        learning_rate: 0.3,
        num_epochs: 20,
        verbalizer: 'Input: {{input}} Output:',
        auto_update_model: false,
        max_seq_length: 128,
        response_template: '',
        gpu: '',
        group_by_name: false,
        max_input_tokens: 256,
        max_output_tokens: 128,
        tuning_type: '',
        init_text: '',
        init_method: 'random',
    },
}


export const setTuningParameters = (data) => {
  const updatedState = { ...data };
  const tuningMethod = data.tuning_method


  // Remove non-relevant parameters from the 'parameters' object
  Object.keys(updatedState.parameters).forEach(paramKey => {
    if (
      !commonFields.includes(`parameters.${paramKey}`) &&
      !(tuningMethod === "fine" && fineTuningFields.includes(`parameters.${paramKey}`)) &&
      !(tuningMethod === "prompt" && promptTuningFields.includes(`parameters.${paramKey}`))
    ) {
      delete updatedState.parameters[paramKey];
    }
  });


  return updatedState;
};
