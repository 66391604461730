import React, { useContext, useRef, useState } from 'react'
import './login-styles.css'
import { Context } from '../../context/GlobalState'
import LoginForm from './LoginForm'
import { authService } from 'api'
import loginLogo from '../../assets/login-logo.png';


export default function Login() {

    const [loading, setLoading] = useState(false)
    const { addNewNotifcation } = useContext(Context);
    
    const loginFormRef = useRef(null);
    
    const handleOnSubmit = (e) => {
        e.preventDefault()

        const emailRef = loginFormRef.current.getEmailRef();
        const passwordRef = loginFormRef.current.getPasswordRef();

        setLoading(true)
        authService.login({
            email: emailRef.value,
            password: passwordRef.value,
        })
        .then(async () => {
            window.location = '/redirect'
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status == 403) {
                addNewNotifcation('Email or password is incorrect. Please reenter your credentials.', 'danger')
            } else {
                addNewNotifcation('Login failed. Please check your credentials.', 'danger')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='flex flex-grow flex-col justify-center items-center'>
            <div>
                <div className='w-full text-center mt-12'>
                    <h1 className='text-4xl font-bold mb-6'> BeX AI Platform </h1>
                    <h3 className='text-2xl font-bold md:block hidden'> Agentic AI Platform for Workflow Orchestration </h3>
                </div>
                <div className='flex md:flex-row flex-col items-center gap-8 mt-4'>
                    <div className='max-w-md md:max-w-[500px] hidden md:flex w-full order-last md:order-first flex-col gap-4'>
                        <img src={loginLogo} alt="login-image" className='order-last md:order-first' />
                        <h3 className='text-2xl text-center text-red-500 font-bold'> AI that gets Business Execution Done </h3>
                    </div>
                    <div className='flex flex-col w-full'>
                        <LoginForm ref={loginFormRef} loading={loading} onSubmit={handleOnSubmit} />
                    </div>
                </div>
            </div>
        </div>
    )
}
