/* eslint-disable max-lines */
import * as React from "react"
import {
  MoreHorizontal,
  PlusCircle,
  ChevronsUpDown,
  EditIcon,
} from "lucide-react"
import { MagnifyingGlassIcon, Cross1Icon } from "@radix-ui/react-icons"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog"
import { Button } from "./button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "./dropdown-menu"
import { Input } from "./input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./table"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from "./tooltip"
import { Checkbox } from "./checkbox"

function NewStyledTable({ columns, data, rowActions = [],tableActions=[], cardTitle=null, search = true, multiSelect = false, actionCondition = () => true }) {
    const [searchValue, setSearchValue] = React.useState("");
    const [filteredData, setFilteredData] = React.useState(data);
    const [checkedRows, setCheckedRows] = React.useState([]);
    const [areCheckboxesVisible, setAreCheckboxesVisible] = React.useState(false);

    React.useEffect(() => {
        if (searchValue === "") {
        setFilteredData(data);
        } else {
        setFilteredData(
            data.filter((row) => {
            return columns.some((column) => {
                return row[column.field]
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase());
            });
            })
        );
        }
    }, [searchValue, data, columns]);

    const handleSearchInputChanges = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSelectAllToggle = () => {
        if (checkedRows.length === filteredData.length) {
        setCheckedRows([]);
        } else {
        setCheckedRows(filteredData.map((row) => row.id));
        }

    }

    const handleSelectRowToggle = (id) => {
        if (checkedRows.includes(id)) {
        setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
        } else {
        setCheckedRows([...checkedRows, id]);
        }
    }
    
    if (tableActions.length > 0) {
        var deleteAction = tableActions.find(action => action.name === 'Delete Selected');
        var addAction = tableActions.find(action => action.name === 'Add New');
    }

    return (
        <Card className="shadow-md w-full">
            <CardHeader>
                {cardTitle && <CardTitle className='mb-4'>{cardTitle}</CardTitle>}
                <div className="flex items-center">
                    {search && <SearchInput value={searchValue} onChange={handleSearchInputChanges} />}
                    <div className="ml-auto flex items-center gap-2">
                        {multiSelect && (
                            <Button size="lg" variant="outline" className="h-7 gap-1" onClick={() => setAreCheckboxesVisible(!areCheckboxesVisible)}>
                                {areCheckboxesVisible ? (
                                    <>
                                        <Cross1Icon className="h-3.5 w-3.5" />
                                        <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                                            Cancel
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <EditIcon className="h-3.5 w-3.5" />
                                        <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                                            Edit All
                                        </span>
                                    </>
                                )}
                            </Button>
                        )}
                        {tableActions.length > 0 && (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button size="lg" className="h-7 gap-1" onClick={addAction.onclick}>
                                            <PlusCircle className="h-3.5 w-3.5" />
                                            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                                                Add New
                                            </span>
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-gray-900 text-white">
                                        <p>This will add a new record to this table</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </div>
                </div>
            </CardHeader>
            <CardContent className="relative">
                <div className="overflow-x-auto no-scrollbar max-h-[400px]">
                    <Table className="relative" >
                        <TableHeader className="sticky top-0 z-10 bg-muted/100">
                            <TableRow className='border-[0] border-b-[0.1px] border-solid border-b-gray-100'>
                                {multiSelect && areCheckboxesVisible && (
                                    <TableHead className="w-20">
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Checkbox 
                                                    id="selectall"
                                                    checked={checkedRows.length === filteredData.length} 
                                                    onClick={handleSelectAllToggle} 
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-gray-900 text-white">
                                                    <p>Select all</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </TableHead>
                                )}
                                {columns.map((column, index) => (
                                    <TableHead className="text-base" key={index}>{column.headerName}</TableHead>
                                ))}
                                {rowActions.length > 0 && (
                                    <TableHead>
                                        <span className="sr-only">Actions</span>
                                    </TableHead>
                                )}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredData.map((row) => (
                                <TableRow className='border-[0] border-b-[0.3px] border-solid border-b-gray-300' key={row.id}> 
                                    {multiSelect && areCheckboxesVisible && (
                                        <TableCell>
                                            <Checkbox
                                            id={row.id}
                                            checked={checkedRows.includes(row.id)}
                                            onClick={() => handleSelectRowToggle(row.id)}
                                            />
                                        </TableCell>
                                    )}
                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex} className="text-base">
                                            {column.renderCell ? column.renderCell(row) : row[column.field]}
                                        </TableCell>
                                    ))}
                                    {rowActions.length > 0 && (
                                        <>
                                            {actionCondition(row) && (
                                                <TableCell className="w-20">
                                                    <DropdownMenu modal={false}>
                                                        <DropdownMenuTrigger asChild>
                                                            <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                                                                <MoreHorizontal className="h-4 w-4" />
                                                                <span className="sr-only">Toggle menu</span>
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent align="end">
                                                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                                            {rowActions.map((action) => (
                                                                <div key={action.name}>
                                                                    {action.isDelete && <DropdownMenuSeparator /> }
                                                                    <DropdownMenuItem
                                                                    className={`${action.isDelete ? 'text-red-500' : ''}`}
                                                                    key={action.name}
                                                                    onClick={() => action.onclick(row.id)}
                                                                    >
                                                                        {action.name}
                                                                    </DropdownMenuItem>
                                                                </div>

                                                            ))}
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </TableCell>
                                            )}
                                        </>
                                    )}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div className="absolute bottom-0 left-0 right-0 flex justify-center pb-2">
                    <ChevronsUpDown className="h-6 w-6 text-gray-500" />
                </div>
            </CardContent>
            <CardFooter>
                {areCheckboxesVisible && (
                    <div className="flex items-center gap-2">
                        <Button size="lg" variant="outline" className="h-7 gap-1" onClick={() => setCheckedRows([])}>
                            Clear
                        </Button>
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button size="lg" className="h-7 gap-1">
                                    Delete
                                </Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogHeader>
                                    <DialogTitle>Delete Selected</DialogTitle>
                                </DialogHeader>
                                <DialogDescription>
                                    Are you sure you want to delete the selected items?
                                </DialogDescription>
                                <DialogFooter>
                                    <DialogTrigger asChild>
                                        <Button size="sm" onClick={() => deleteAction.onclick(checkedRows)}>
                                            Delete
                                        </Button>
                                    </DialogTrigger>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>            
                    </div>
                )}
            </CardFooter>
        </Card>
    )
}

const SearchInput = ({ value, onChange }) => (
    <div className="relative w-full max-w-md">
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-500 dark:text-gray-400" />
        <Input
      className="w-full pl-10 pr-4 py-2 rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
      placeholder="Search table..."
      type="search"
      value={value}
      onChange={onChange}
    />
    </div>
);


export default NewStyledTable;
