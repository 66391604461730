import { createContext, useEffect, useState } from "react";
import { createWebSocketClient } from "api/clients/knowledgeBuilder.httpClient";
import { useParams } from "react-router-dom";

export const FileManagerContext = createContext({});

export const FileManagerProvider = ({ children }) => {
    const { collectionId } = useParams();
    const [socket, setSocket] = useState(null);
    const [l1Status, setL1Status] = useState("checking");

    useEffect(() => {
        // Create WebSocket connection
        const ws = createWebSocketClient(`/L1/ws/process_from_cos/${collectionId}`);
        setSocket(ws);

        ws.onopen = () => {
            console.log("Connected to knowledge builder websocket...");
        };
        
        ws.onmessage = (event) => {
            const message = JSON.parse(event.data);

            if (message.status === "processing") {
                setL1Status("processing");
            } else if (message.status === "completed") {
                setL1Status("completed");
            } else if (message.status === "idle") {
                setL1Status("idle");
            } else if (message.status === "error") {
                // setL1Status("error");
                console.error(`Error: ${message.message}`);
                ws.close();
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
            ws.close();
        };

        ws.onclose = () => {
            console.log("WebSocket connection closed");
        };

        // Cleanup function to close WebSocket when component unmounts
        return () => {
            if (ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [collectionId]);

    return (
        <FileManagerContext.Provider
        value={{
            socket,
            l1Status
        }}
        >
            {children}
        </FileManagerContext.Provider>
    );
};
