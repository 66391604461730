import React, { useContext, useEffect, useState } from 'react';
import { Card, CardContent, CardTitle } from '../../../../components/ui/card';
import { Label } from '../../../../components/ui/label';
import { SubOrgContext } from 'context/SubOrganizationContext';
import WorkflowCard from './Workflow';
import SubOrganizationMembers from '../Members';
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout';
import { organizationIndex } from 'api';
import { AppLoader } from 'components/LoaderSpinner';
import { Button } from 'components/ui/button';
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from 'components/ui/alert-dialog';
import { OrganizationContext } from 'context/OrganizationContext';
import { Context } from 'context/GlobalState';
import { formatDate } from 'utils/timeUtils';
import AlertModal from 'components/Alertmodal';
import { getOrganizationMember } from 'utils/organizationUtils';

const SubOrganizationSettings = () => {

    const { workflow, selectedSubOrganization} = useContext(SubOrgContext);

    return (
        <NestedPageLayout>
            <div className='w-full flex flex-col gap-4'>
                <Card className="w-full space-y-8 p-4">
                    <CardTitle> Sub Organization </CardTitle>
                    <CardContent>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Name </Label>
                                <span className='font-[500]'>{selectedSubOrganization.name}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Created </Label>
                                <span className='font-[500]'>{new Date(selectedSubOrganization.created).toDateString()}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* sub organization vector index */}
                <SubOrganizationIndex />

                {/* members card */}
                <SubOrganizationMembers />

                {/* workflow card info */}
                <WorkflowCard workflow={workflow} />

            </div>
        </NestedPageLayout>

    )
}

const SubOrganizationIndex = () => {

    const [vectorIndex, setVectorIndex] = useState(null)
    const [loading, setLoading] = useState(true)
    const [createdByName, setCreatedByName] = useState('-')

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isDelDialogOpen, setIsDelDialogOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { selectedSubOrganization } = useContext(SubOrgContext)

    useEffect(() => {
        organizationIndex.getOrganizationVectorIndex(selectedOrganization._id, selectedSubOrganization._id)
        .then((res) => {
            console.log('res: ', res);
            setVectorIndex(res.data)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })

    }, [])

    const isSuperAdmin = selectedOrganization.userRole === 'superAdmin'


    const handleAddVectorIndex = () => {
        if (!isSuperAdmin) {
            return addNewNotifcation('You don not have the right access to activate vector index', 'warning')
        }

        setBtnLoading(true)
        const vectorIndexObj = {
            organizationId: selectedOrganization._id,
            subOrganizationId: selectedSubOrganization._id,
        }
        organizationIndex.createSubOrganizationVectorIndex(vectorIndexObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Vector index activated successfully', 'success')
            setVectorIndex(res.data)
        }).catch((error) => {
            console.log('error: ', error);
            if (error?.response?.status === 409) {
                addNewNotifcation(error.response?.data?.message || 'Something went wrong. Please try again later', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again later', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    const handleDelete = () => {

        if (!isSuperAdmin) {
            return addNewNotifcation('You don not have the right access to delete vector index', 'warning')
        }

        setBtnLoading(true)
        const vectorIndexObj = {
            organizationId: selectedOrganization._id,
            subOrganizationId: selectedSubOrganization._id,
        }
        organizationIndex.deleteSubOrganizationVectorIndex(vectorIndexObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Vector index deleted successfully', 'success')
            setVectorIndex(null)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again later', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }   

    
    useEffect(() => {
        if (!vectorIndex) return 
        getOrganizationMember(vectorIndex?.createdBy, selectedOrganization)
        .then((name) => {
            setCreatedByName(name)
        }).catch((error) => {
            console.log('error: ', error);
        })
    }, [vectorIndex])

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <Card className="w-full space-y-8 p-4">
            <CardTitle> MongoDB Vector Index </CardTitle>
            <CardContent>
                <div className='flex flex-col gap-3'>
                    {vectorIndex ? (
                        <div className='space-y-3'>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Name </Label>
                                <span className='font-[500]'>{vectorIndex.indexName}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Created </Label>
                                <span className='font-[500]'>{formatDate(vectorIndex.created)}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Created By </Label>
                                <span className='font-[500]'>{createdByName || '-'}</span>
                            </div>

                            <div className='flex gap-2 mt-4 w-full justify-end'>
                                <Button onClick={() => setIsDelDialogOpen(true)} disabled={!isSuperAdmin} variant='destructive'> Remove index </Button>

                                {/* delete vector index dialog alert */}
                                <AlertModal loading={btnLoading} openModal={isDelDialogOpen} setopenModal={setIsDelDialogOpen} onDelete={handleDelete} />
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-col w-full'>

                            <div className='mt-16 w-full flex justify-center flex-col items-center'>
                                <span className='text-muted-foreground'> Activating the Vector Index will allow for faster and more efficient searches within this sub-organization. </span>
                                <Button disabled={!isSuperAdmin} onClick={() => setIsDialogOpen(true)} className='mt-4'> Activate Vector Index </Button>
                            </div>

                            {/* create vector index dialog alert */}
                            <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Activate Vector Index</AlertDialogTitle>
                                        <AlertDialogDescription>
                                            Are you sure you want to activate the Vector Index for <strong className='font-bold'> {selectedSubOrganization.name} </strong>?
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <div className="my-4 flex flex-col">
                                    </div>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <Button disabled={btnLoading} onClick={handleAddVectorIndex}>
                                            {btnLoading ? 'Activating...' : 'Activate'}
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                            
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    )
}


export default SubOrganizationSettings;