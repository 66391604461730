import { projectOHttpClient } from 'api/clients/project-o.httpClient';
import axios from 'axios';

/**
 * Service class for performing RAG operations.
 */
class RAGService {

    /**
     * Query Data from document.
     * @returns {Promise<Object>}
     */
    async queryData(data){
        const response = await projectOHttpClient.post(`/embedding_api_service/query_data/`, data, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        return response;
    }


    /**
     * upload new document.
     * @returns {Promise<Object>}
     */
    async processFile(data) {
        const response = await projectOHttpClient.post(`/embedding_api_service/process_file/`, data, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        return response;
    }

    /**
     * Fetches documents by organizationId and subOrganizationId.
     * @returns {Promise<Object>} The response from the server returns list of documents.
     */
    async listDocuments(organizationId, subOrganizationId, filter=null) {
        let url = `/document_api_service/list-document/?organization_id=${organizationId}&sub_organization_id=${subOrganizationId}`;
        if (filter) {
            const jsonString = JSON.stringify(filter);
            const encodedString = encodeURIComponent(jsonString);
            // const filterQuery = new URLSearchParams(filter)
            url += `&filter=${encodedString}`;
        }
    
        const response = await projectOHttpClient.get(url);
        return response;
    }

    /**
     * delete document by organizationId, subOrganizationId, document id and bucket name.
     * @returns {Promise<Object>} The response from the server returns status of response.
     */
    async deleteDocument(organizationId, subOrganizationId, documentId, bucket) {
        const bucketObj = new URLSearchParams();
        bucketObj.append('bucket_name', bucket);

        console.log('bucket: ', bucket);
        const response = await projectOHttpClient.delete(`/document_api_service/delete-document/?organization_id=${organizationId}&sub_organization_id=${subOrganizationId}&document_id=${documentId}`, 
            {
                data: bucketObj.toString(),
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        )
        return response;
    }



    // IBM COS 
    /**
     * Fetches files from COS by bucketName.
     * @returns {Promise<Object>} The response from the server returns list of documents.
     */
    async listFilesCOS(orgId, bucketName, prefix="", max_keys=1000, url_expiration=3600, continuation_token=null) {
        // kb deployment endpoint for now
        let url = `/ibmcos/list-documents/${orgId}/${bucketName}?prefix=${prefix}&max_keys=${max_keys}&url_expiration=${url_expiration}`;
        if (continuation_token) {
            url +=`&continuation_token=${continuation_token}`
        }
        const response = await projectOHttpClient.get(url);
        return response;
    }
}

const ragService = new RAGService()
export { ragService }
