import React, { useContext, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import './secure-styles.css'
import { SecureRoute } from '../../layout/SecureRoutes'
import { Link, Outlet, useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Context } from '../../context/GlobalState'
import { IoIosArrowDown } from 'react-icons/io'
import { settingsSubscriptionsList, settingsUsersList, settingsAdminUsersList } from '../../data/adminSettingsMenu'
import { httpRequest } from '../../utils/httpsRequest'


import Login from './Login';
import AdminPermissionList from './IdentityAccessManagement/AdminPermission';
import Profile from './Profile';
import AdminPasswordChangeModal from './AdminPasswordChangeModal';
import Users from './Users'
import Organizations from './Organizations'
import Packages from './Packages'
import Assisstants from './Assisstants'
import WorkflowTypes from './WorkflowTypes'
import PackageDetails from './Packages/PackageDetails'
import OrganizationDetails from './Organizations/OrgDetails'
import SubOrganizationDetails from './Organizations/SubOrgDetails'
import { OrganizationInfoProvider } from './Organizations/OrganizationContext'
import OrgLayout from './Organizations/OrgLayout'
import SideMenu from 'components/ui/SideMenu'
import UserActivityPage from './ActivityLog'


export default function Secure() {
    const { isAdminAuth } = useContext(Context);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    
    return (
        <>
        <Routes>
            <Route element={<SecureRoute />}>
                <Route path='/' element={isAdminAuth ? <SecureHome /> : <Navigate to="login" />}>
                    <Route index element={<AdminDashboard />} />
                    <Route path='dashboard' element={<AdminDashboard />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='iam' element={<AdminPermissionList />} />
                    <Route path='users' element={<Users />} />
                    <Route path='activity-logs' element={<UserActivityPage />} />
                    <Route path='organizations'>
                        <Route index element={<Organizations />} />
                        <Route path=':orgId' element={
                            <OrganizationInfoProvider>
                                <OrgLayout />
                            </OrganizationInfoProvider>
                        }>
                            <Route index element={<OrganizationDetails />} />
                            <Route path='sub/:subOrgId' element={<SubOrganizationDetails />} />
                        </Route>
                    </Route>
                    <Route path='packages' element={<Packages />} />
                    <Route path='/packages/:packageId' element={<PackageDetails />} /> 
                    <Route path='assistants' element={<Assisstants />} />
                    <Route path='workflow-types' element={<WorkflowTypes />} />
                </Route>
            </Route>
            <Route path='login' element={!isAdminAuth ? <Login /> : <Navigate to="/secure/dashboard" />} />
            
        </Routes>
        { showPasswordReset && <AdminPasswordChangeModal/>}
        </>
    )
}


const SecureHome = () => {
    const { actions, userAdmin } = useContext(Context)
    const navigate = useNavigate()

    const handleLogOut = () => {
        httpRequest.post('/admin/admin-logout')
            .then((res) => {
                console.log(res);
                actions({ type: 'SET_IS_ADMIN_AUTH', payload: false })
                navigate('/secure/login')
            }).catch((error) => {
                console.log(error);
            })
    }

    const list1 = settingsUsersList(userAdmin.role)
    const list2 = settingsAdminUsersList()
    const list3 = settingsSubscriptionsList()

    const x =  {
        name: 'admin',
        list: [...list1, ...list2, ...list3]
    }

    return (
        <div className='!p-0 flex flex-grow h-full'>
            <SideMenu section={x} />

            <div className='flex flex-grow p-6 overflow-scroll'>
                <Outlet />
            </div>
        </div>
    )
}

const AdminDashboard = () => {
    return (
        <div className='organization-settings-container'>
            <div className='org-container'>
                <div>
                    <h3 className='org-label'>Dashboard</h3>
                    
                </div>
                <div>
                </div>
            </div>
            <hr className='hr-line' />
        </div>
    )
}




// Sidebar provider
const NestedSettingSidebar = ({ name, icon, nestedlist }) => {

    const [showDrowDown, setShowDrowDown] = useState(false)

    return (
        <div className='nested-secure-home-item-container'>
            <div className='nested-secure-home-header'>
                <div onClick={() => setShowDrowDown(!showDrowDown)} className={`secure-home-item-container`}>
                    <span> {icon} </span>
                    <span> {name} </span>
                    <div className={`nested-dropdown-icon ${showDrowDown ? 'nested-dropdown-icon__active' : ''}`}>
                        <IoIosArrowDown size={16} />
                    </div>
                </div>
            </div>
            {showDrowDown && (
                <div className='nested-dropdown-list-container'>
                    {nestedlist.map((item) => (
                        <SettingSidebar key={item.path} {...item} />
                    ))}
                </div>
            )}
        </div>
    )
}

export const SettingsSection = ({ list, title }) => {
    return (
        <li className='secure-home-item-gorup'>
            <span className='secure-home-group-title'> {title} </span>
            <div className='secure-home-nav-container'>
                <ul className='secure-home-nav-list'>
                    {list.map((item) => (
                        <li key={item.path}>
                           {!item.nested ? <SettingSidebar {...item} /> : <NestedSettingSidebar {...item} />}
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    )
}

const SettingSidebar = ({ name, path, icon }) => {
    const loaction = useLocation()

    return (
        <Link to={path} className={`secure-home-item-container ${loaction.pathname === path ? 'setting-item__active' : ''}`}>
            <span> {icon} </span>
            <span> {name} </span>
        </Link>
    )
}