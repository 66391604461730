import React from 'react';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';


const EditUserForm = ({ userInput, setUserInput }) => {

    const handleInputChange = (key, value) => {
        setUserInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div className='space-y-3'>
            <Label>Name</Label>
            <Input onChange={(e) => handleInputChange('name', e.target.value)}
                type="text"
                name="name"
                placeholder="Type new name here"
                required
                value={userInput.name}
            />
        </div>
    );
};

export default EditUserForm;