import { createApiClient } from './';

const BEX_API_BASE_URL = process.env.REACT_APP_BEX_SERVER_URL
const BEX_API_ENDPOINT_PREFIX = "/cm";

/**
 * HTTP client for the BeX API endpoints /  services.
 *
 * @type {ApiClient}
 * @see {@link https://github.com/coolriots/BeX-platform-server/tree/main/src/Routers}
 */
export const bexHttpClient = createApiClient(
    BEX_API_BASE_URL, 
    BEX_API_ENDPOINT_PREFIX
);