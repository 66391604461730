import { knowledgeBuilderHttpClient, createWebSocketClient } from "api/clients/knowledgeBuilder.httpClient";


class KBWebsocketService {

    /**
     * Creates a new opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async l1_processing(data) {
        // Step 1: Get Task ID
        const response = await knowledgeBuilderHttpClient.post(`/processing/celery/process_from_cos_v2`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/processing/celery/ws/process_from_cos_v2/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    async get_l1_task_id(data) {
        const response = await knowledgeBuilderHttpClient.post(`/L1/process_from_cos`, data);
        return response
    }
    

}

const kbWSService = new KBWebsocketService();
export { kbWSService };