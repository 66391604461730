import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout';
import SpeechToTextForm from './Components/SpeechToTextForm';

export default function SpeechToTextPage() {

    return (
        <NestedPageLayout>
            <SpeechToTextForm />
        </NestedPageLayout>
    );
}