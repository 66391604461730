import React, { useEffect, useRef } from "react";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../components/ui/tooltip";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { Info } from 'lucide-react';
import { Textarea } from "../../../components/ui/textarea";

export default function StyledTextAreaCn({
    value,
    onChange,
    labelText,
    rows = 2,
    tooltipText,
    placeholder,
    disabled = false,
    required = false,
    readOnly = false
}) {

    const textareaRef = useRef(null)
    const containerRef = useRef(null)

    const adjustTextareaAndScroll = () => {
        const textarea = textareaRef.current
        const container = containerRef.current
        if (textarea && container) {
          const previousHeight = textarea.style.height
          
          // Reset height to auto and set to scrollHeight to grow
          textarea.style.height = 'auto'
          const newHeight = `${textarea.scrollHeight}px`
          textarea.style.height = newHeight
    
          // Adjust container height and scroll
          if (previousHeight !== newHeight) {
            container.style.height = newHeight
            container.scrollTop = container.scrollHeight
          }
        }
    }
    const handleChange = (e) => {
        const textarea = e.target
        const { selectionStart, selectionEnd } = textarea
        
        onChange?.(e)
        
        requestAnimationFrame(() => {
          adjustTextareaAndScroll()
          if (textareaRef.current) {
            textareaRef.current.selectionStart = selectionStart
            textareaRef.current.selectionEnd = selectionEnd
          }
        })
    
        
      }

    useEffect(() => {
        adjustTextareaAndScroll()
    }, [value])
    useEffect(() => {
        window.addEventListener('resize', adjustTextareaAndScroll)
        return () => window.removeEventListener('resize', adjustTextareaAndScroll)
    }, [])
    
    return (
        <div className="flex flex-col gap-[2px] h-fit">
            <div className="flex flex-row items-center justify-between">
                <TooltipProvider className="flex items-center justify-between">
                    {labelText && (
                        <Label htmlFor="email" className="select-none text-xs">
                            {labelText} {required && <span className="text-red-500">*</span>}
                        </Label>
                    )}

                    {tooltipText && (
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon">
                                    <Info className="h-4 w-4" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent className="w-[300px]">
                                <p>{tooltipText}</p>
                            </TooltipContent>
                        </Tooltip>
                    )}
                </TooltipProvider>
            </div>
            <div className=""ref={containerRef}>
                <Textarea
                    ref={textareaRef}
                    placeholder={placeholder ? placeholder : ""}
                    value={value}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md text-xs"
                    disabled={disabled}
                    readOnly={readOnly}
                    rows={rows}
                    style={{ resize: 'none', overflow: 'hidden' }}
                />
            </div>
        </div>
    );
}
