/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Cog, Folder, MoreVertical, Trash2 } from "lucide-react"
import { Button } from "components/ui/button"
import { useNavigate, useParams } from 'react-router-dom'
import UpdateCollectionDialog from './UpdateCollection'
import { Context } from 'context/GlobalState'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import AlertModal from 'components/Alertmodal'
import { redisCollectionService } from 'api'
import { formatDateToDDMMYYY } from 'utils/timeUtils'
import { getOrganizationMember } from 'utils/organizationUtils'
import { OrganizationContext } from 'context/OrganizationContext'
import CollectionForm from './CollectionForm'
import SearchItems from 'components/NewDesignComponents/SearchItems'


export default function Collection({ collections, actions, isMilvusActivated }) {

    const [openModal, setOpenModal] = useState(false)

    const [updateModal, setUpdateModal] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null)
    const [filteredCollections, setFilteredCollections] = useState([...collections])

    const handleFilterCollections = (searchTerm) => {
        const filteredItems = searchTerm? collections.filter(collection =>
            collection.name.toLowerCase().includes(searchTerm.toLowerCase())
            ) : collections;

        setFilteredCollections(filteredItems)
    }

    return (
        <>
            <div className="space-y-4">
                <SearchItems items={collections} handleFilterItems={handleFilterCollections}/>
                {filteredCollections.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {filteredCollections.map((item, index) => (
                            <CollectionCard
                            isMilvusActivated={isMilvusActivated}
                            actions={actions} collections={collections}
                            {...item} index={index} key={index}
                            onManage={() => {
                                setSelectedCard(item)
                                setUpdateModal(true)
                            }}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='flex flex-grow justify-center items-center'>
                        <span className="text-lg font-semibold mb-2">No collections Found</span>
                    </div>
                )}
            </div>

            {/* create collection modal */}
            <CreateNewCollectionDialog isMilvusActivated={isMilvusActivated} actions={actions} openModal={openModal} setOpenModal={setOpenModal} />

            {/* update collection dialog */}
            <UpdateCollectionDialog
            selectedCard={selectedCard}
            isMilvusActivated={isMilvusActivated}
            actions={actions}
            show={updateModal} setShow={setUpdateModal} />
            
        </>
    )
}

function CollectionCard({ actions, collections, onManage, name, _id, created, createdBy }) {

    const [delModal, setDelModal] = useState(false)
    const [delBtn, setDelBtn] = useState(false)
    const [createdByName, setCreatedByName] = useState('')

    const navigate = useNavigate()
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)
    const { oragID, subOragID } = useParams()

    const handleDeleteCollection = () => {
        setDelBtn(true)

        const delObj = {
            // projectId: project._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
            collectionId: _id,
        }

        redisCollectionService.deleteCollection(delObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Collection deleted successfully', 'success')
            actions({type: 'SET_COLLECTIONS', payload: (collections.filter(collection => collection._id !== _id))})
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setDelBtn(false)
            setDelModal(false)
        })

    }

    useEffect(() => {
        getOrganizationMember(createdBy, selectedOrganization)
        .then((name) => {
            setCreatedByName(name)
        }).catch((error) => {
            console.log('error: ', error);
        })
    }, [])


    return (
        <Card>
            <CardHeader>
                <div className='flex w-full justify-between items-center'>
                    <CardTitle onClick={() => navigate(`${_id}/documents`)} className='cursor-pointer hover:underline'>{name}</CardTitle>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                {/* <span className="sr-only">Open menu</span> */}
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={onManage}>
                                <Cog className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => setDelModal(true)} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex gap-3 items-center">
                    <span className='rounded-full size-16 flex justify-center items-center bg-[#b1092a]'>
                        <Folder size={28} color='white'/>
                    </span>
                    <ul className="flex flex-col">
                        {/* <li>Purpose: </li> */}
                        <li className='text-sm text-muted-foreground'>Created on: <span>{formatDateToDDMMYYY(created)}</span> </li>
                        <li className='text-sm text-muted-foreground'>Created by: <span>{createdByName || '-'}</span> </li>
                    </ul>
                </div>
            </CardContent>

            {/* delete collection modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteCollection} />
        </Card>
    )
}

export const CreateNewCollectionDialog = ({ actions, openModal, isMilvusActivated, setOpenModal }) => {

    const initialData = {
        embeddingModel: 'jina',
        name: '',
        chunkingStrategy: 'by page',
        dimensions: 1024,
        vectorStoreType: 'MongoDB',
    }

    const [collectionForm, setCollectionForm] = useState(initialData);
    const [btnLoading, setBtnLoading] = useState(false)
    
    const { oragID, subOragID  } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleCreateCollection = () => {

        setBtnLoading(true)
        const collectionObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            name: collectionForm.name,
            dimensions: collectionForm.dimensions,
            vectorStoreType: collectionForm.vectorStoreType,
            chunkingStrategy: collectionForm.chunkingStrategy,
            embeddingModel: collectionForm.embeddingModel,
        }

        redisCollectionService.addCollection(collectionObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Collection created successfully', 'success')
            actions({type: 'SET_COLLECTIONS', payload: (collections => [...collections, res.data])})

            setCollectionForm(initialData)
            setOpenModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 403) {
                addNewNotifcation('Resource limit reached. You cannot create more collections under your current plan', 'danger')
            } else if (error.response?.status === 409) { 
                addNewNotifcation(`Collection with ${collectionForm.name} already created`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Create New Collection</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Set up a new collection.</DialogDescription>
                </DialogHeader>

                <CollectionForm
                form={collectionForm} setForm={setCollectionForm}
                isMilvusActivated={isMilvusActivated} />

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleCreateCollection} className="px-5 py-3 text-md">
                        {btnLoading ? 'Creating Collection...' : 'Create Collection'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}