import React from 'react';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';

const ResetUserPasswordForm = ({ userPassInput, setUserPassInput }) => {

    const handleInputChange = (key, value) => {
        setUserPassInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div className='space-y-3'>
            <Label> Old password </Label>
            <Input onChange={(e) => handleInputChange('oldPass', e.target.value)}
                type="password"
                name="password"
                placeholder="Type your old password here"
                required
                value={userPassInput.oldPass}
            />
            <Label> New password </Label>
            <Input onChange={(e) => handleInputChange('newPass', e.target.value)}
                type="password"
                name="password"
                placeholder="Type your new password here"
                required
                value={userPassInput.oldPass}
            />
            <Label> Confirm new password </Label>
            <Input onChange={(e) => handleInputChange('confirmNewPass', e.target.value)}
                type="password"
                name="password"
                placeholder="Type confirm new password here"
                required
                value={userPassInput.oldPass}
            />
        </div>
    );
};

export default ResetUserPasswordForm;