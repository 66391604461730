
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import MilvusConfig from './MilvusKeys'
import RedisKeys from './RedisKys'
import ElasticsearchConfig from './ElasticsearchKeys'


export default function OrganizationConfig() {

    return (
        <div className='flex p-8 overflow-y-scroll w-full min-w-[700px]'>
            <div className='w-full'>
                <div className='h-auto py-8 space-y-6'>
                    <Tabs defaultValue="milvus" className="space-y-4">
                        <TabsList className='w-full'>
                            <TabsTrigger className='w-full' value="milvus">Milvus Keys</TabsTrigger>
                            <TabsTrigger className='w-full' value="elasticsearch">Elasticsearch Keys</TabsTrigger>
                            <TabsTrigger className='w-full' value="redis">Redis Keys</TabsTrigger>
                        </TabsList>

                        <TabsContent value="milvus">
                            <MilvusConfig />
                        </TabsContent>

                        <TabsContent value="elasticsearch">
                            <ElasticsearchConfig />
                        </TabsContent>

                        <TabsContent value="redis">
                            <RedisKeys />
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
