/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import StepLLM from './OpCodeBuilderSteps/StepLLM/StepLLM';
// import { Button } from 'components/ui/button';
// import { Play } from 'lucide-react';
// import { unifiedModelWSService } from 'api/services/PROJECT-O/WEBSOCKETS/UnifiedModel.websocket.service';
// import { OpCodeBuilderContext } from './OpCodeBuilderHAndC/OpCodeBuilderContext';
// import StepResultViewer from './StepResultViewer';
// import { Context } from 'context/GlobalState';
// import { milvusService } from 'api/services/PROJECT-O/Milvus.service';
import StepNonLLM from './OpCodeBuilderSteps/StepNonLLM/StepNonLLM';
import StepCondition from './OpCodeBuilderSteps/StepCondition/StepCondition';
import StepLoop from './OpCodeBuilderSteps/StepLoop/StepLoop';

const InputGroup = ({index, inputGroup, inputGroups, userInput, setInputGroups, nested = false}) => {
    // const [stepResult, setStepResult] = useState("")
    // const [loading, setLoading] = useState(false)
    // const { orgId, subOrgId } = useContext(OpCodeBuilderContext)
    // const { addNewNotifcation } = useContext(Context);
// console.log(inputGroups.slice(index + 1))
    // useEffect(() => {
    //     if(stepResult){
    //         const newInputGrops = inputGroups.map((group, i)=> i === index? {...group, stepResult} : group)
    //         setInputGroups(newInputGrops)
    //     }
    // }, [stepResult])
    
    const getPreviousOutputs = (currentIndex) => {
        if (currentIndex <= 0) return [];
        
        return inputGroups
            .slice(1, currentIndex)
            .map((group, i) => ({
                stepIndex:i,
                stepId:group.step_id,
                prevOutp:{
                value: group.step_type === "Non-LLM"? "Output" : (group?.output?.Output || ""),
                label: `Step ${i + (nested? 1: 2)} Output: ${group?.output?.Output}`,
                }
            }))
            .filter((item) => item.prevOutp.value.trim() !== "");
    };

    const getNextSteps = (currentIndex) => {
        return inputGroups
            .slice(currentIndex + 1)
            .map((group, i) => ({
                stepIndex:i,
                nextStep:{
                value: group.step_id,
                label: `Step ${i + (nested? currentIndex + 1 : currentIndex + 3)}`,
                }
            }))
            // .filter((_, index) => index !== currentIndex );
    };

    const getStepType = () => {
        if (!inputGroup?.step_type) return null;
    
        return inputGroup.step_type === "LLM"
            ? "LLM"
            : inputGroup.step_type === "LLM-Stream"
            ? "LLM-Stream"
            : inputGroup.step_type === "Condition"
            ? "Condition"
            : inputGroup.step_type === "Identification"
            ? "Identification"
            : inputGroup.step_type === "Loop"
            ? "Loop"
            : inputGroup.registry_type === "api"
            ? "Non-LLM - RAG API"
            : inputGroup.registry_type === 'function'
            ? "Non-LLM - BeX Insights"
            : "Non-LLM";
    };

    // const handleRunStep = async () => {
    //     const inputForTestingValue = `Please enter an input field for testing before proceeding. 
    //     The input will be used as real-time data for the testing process and run single instruction.`
    //     const apiNameWarningMessage = `Please select and API name before proceeding.`
    //     const stepInputWarningMessage = `Please select a step input before proceeding.`
    //     const noPrevStep = `No previous step to use its output result.`
    //     const noPrevStepRestult = `Please run the selected step output in "step input" and make sure they have result or remove them before proceeding.`
    //     let queryText = ''

    //     if(inputGroup?.step_type === 'LLM' || inputGroup?.step_type === 'LLM-Stream' || inputGroup?.step_type === 'Identification'){
            
    //         if(!inputGroup?.unique_name) {
    //             addNewNotifcation(`Please select an instruction name before proceeding`)
    //             return
    //         }

    //         if(index === 0 && !userInput){
    //             addNewNotifcation(inputForTestingValue)
    //             return
    //         }

    //         if(index !== 0 && inputGroup.dynamic_inputs.length === 0){
    //             addNewNotifcation(stepInputWarningMessage)
    //             return
    //         }

    //         const isRealTimeData = inputGroup.dynamic_inputs?.find(input => input.includes("real_time_data"))

    //         if(isRealTimeData && !userInput){
    //             addNewNotifcation(inputForTestingValue)
    //             return
    //         }

    //         if(!isRealTimeData && inputGroup.dynamic_inputs.length > 1){

    //             if(index !== 0 && inputGroup.dynamic_inputs.length > 0){
    //                 const newDynamicInputs = inputGroup?.dynamic_inputs_ids
    //                 ?.map(step => {
    //                     const itemIndex = inputGroups.findIndex(group => group.step_id === step.id);
    //                     return itemIndex === step.stepIndex
    //                         ? step
    //                         : { ...step, stepIndex: itemIndex };
    //                 })
    //                 ?.filter(step => {
    //                     const itemIndex = inputGroups.findIndex(group => group.step_id === step.id);
    //                     return itemIndex < index;
    //                 });
    
    //                 const uniqueDynamicInputs = newDynamicInputs.reduce((acc, step) => {
    //                     const isDuplicate = acc.some(
    //                         input => input?.id === step?.id && input?.outputVar === step?.outputVar
    //                     );
    //                     if (!isDuplicate) acc.push(step);
    //                     return acc;
    //                 }, []);
    
    //                 const allPreviousStepsHaveResults = uniqueDynamicInputs?.every(step => {
    //                     const item = inputGroups.find(group => group.step_id === step.id);
    //                     return item?.stepResult;
    //                 });
    
    //                 if (!allPreviousStepsHaveResults) {
    //                     addNewNotifcation(noPrevStepRestult);
    //                     return;
    //                 }
                    
    //                 if(isRealTimeData) queryText +=`${userInput}\n`
    
    //                 uniqueDynamicInputs.map((step) => {
    //                     const item = inputGroups.find(group => group.step_id === step.id);
    //                     step.outputVar.includes("real_time_data")? "" : queryText += `${item.stepResult}\n`
    //                 })
    //             }           
    //         }

    //         const dataObj = {
    //             org_id: orgId,
    //             sub_org_id: subOrgId,
    //             unique_name: inputGroup?.unique_name,
    //             query: isRealTimeData? userInput : queryText,
    //             placeholder_values: {}
    //         }
    //         console.log(dataObj)
    
    //         try{
    //             setLoading(true)
    //             const res = await unifiedModelWSService.predict(dataObj, inputGroup?.model_type || "IBM")
    //             console.log(res)
    //             setStepResult(res?.result?.result? res?.result?.result : "")
    //         } catch(error) {
    //             console.log(error)
    //         } finally {
    //             setLoading(false)
    //         }
    //     }

    //     if(inputGroup?.registry_type === 'api'){
    //         const isRealTimeData = inputGroup.dynamic_inputs.query_text === "real_time_data"

    //         if(!inputGroup.registry_key){
    //             addNewNotifcation(apiNameWarningMessage)
    //             return
    //         }


    //         if(isRealTimeData && !userInput){
    //             addNewNotifcation(inputForTestingValue)
    //             return
    //         }

    //         if(!inputGroup.fixed_inputs.collection_id){
    //             addNewNotifcation(`Please select a collection before proceeding`)
    //             return
    //         }

    //         const foundInput = inputGroups.find(group => group.output_var === inputGroup.dynamic_inputs.query_text)

    //         if(!isRealTimeData && !foundInput ){
    //             addNewNotifcation(noPrevStep)
    //             return
    //         }

    //         if(!isRealTimeData && !foundInput.stepResult){
    //             addNewNotifcation(noPrevStepRestult)
    //             return
    //         }

    //         const userInputData = inputGroup.dynamic_inputs.query_text === 'real_time_data'? userInput : foundInput.stepResult

    //         const dataObj = {
    //             organization_id: orgId,
    //             sub_organization_id: subOrgId,
    //             collection_id: inputGroup.fixed_inputs.collection_id,
    //             query_text: userInputData,
    //             top_k: inputGroup.fixed_inputs.top_k,
    //             offset: inputGroup.fixed_inputs.offset,
    //             top_n: inputGroup.fixed_inputs.top_n,
    //             use_rerank: inputGroup.fixed_inputs.use_rerank,
    //             filters: inputGroup.fixed_inputs.filters,
    //             search_type: "vector"
    //         }
    //         console.log(dataObj)
    
    //         try{
    //             setLoading(true)
    //             const res = await milvusService.hybridSearch(dataObj)
    //             console.log(res)
    //             setStepResult(res?.data?.results?.length > 0 ? res?.data?.results : "" )
    //         } catch(error) {
    //             console.log(error)
    //         } finally {
    //             setLoading(false)
    //         }
    //     }

    //     // if(inputGroup?.step_type === 'Non-LLM - BeX Insights'){
    //     //     if(!inputGroup.registry_key){
    //     //         addNewNotifcation(apiNameWarningMessage)
    //     //         return
    //     //     }

    //     //     if(inputGroup.dynamic_inputs.length === 0 ){
    //     //         addNewNotifcation(stepInputWarningMessage)
    //     //         return
    //     //     }

    //     //     if(inputGroup.dynamic_inputs[0] === 'real_time_data' ){
    //     //         if(!userInput){
    //     //             addNewNotifcation(inputForTestingValue)
    //     //             return
    //     //         }
    //     //     }

    //     //     // const userInputData = inputGroup.dynamic_inputs[0] === 'real_time_data'? userInput : inputGroup.dynamic_inputs[0]

    //     //     // const dataObj = {
    //     //     //     organization_id: orgId,
    //     //     //     sub_organization_id: subOrgId,
    //     //     //     query: userInput,
    //     //     //     collection_id: inputGroup.fixed_inputs.collection_id,
    //     //     //     query_text: userInputData,
    //     //     //     top_k: inputGroup.fixed_inputs.top_k,
    //     //     //     offset: inputGroup.fixed_inputs.offset,
    //     //     //     top_n: inputGroup.fixed_inputs.top_n,
    //     //     //     use_rerank: inputGroup.fixed_inputs.use_rerank,
    //     //     //     filters: inputGroup.fixed_inputs.filters,
    //     //     //     search_type: "vector"
    //     //     // }
    
    //     //     // try{
    //     //     //     setLoading(true)
    //     //     //     const res = await milvusService.hybridSearch(dataObj)
    //     //     //     console.log(res)
    //     //     //     // setStepResult(res?.result?.result)
    //     //     // } catch(error) {
    //     //     //     console.log(error)
    //     //     // } finally {
    //     //     //     setLoading(false)
    //     //     // }
    //     // }
    // }

    return (
        <div className="space-y-2 bg-gray-50 border shadow rounded p-2">
            <div className="flex flex-row gap-2 items-center justify-between">
                <h3 className="text-lg font-semibold">
                    {/* Step {index + 1} - {getStepType()} */}
                    {getStepType()}
                </h3>
                {/* {(inputGroup?.registry_type === "api" || inputGroup?.step_type === "LLM" || inputGroup?.step_type === 'LLM-Stream' || inputGroup?.step_type === "Identification") && 
                    <div className="flex items-center gap-2">
                        <Button 
                        className="w-full" 
                        disabled={loading} 
                        onClick={handleRunStep}
                        >
                            <Play className="h-5 w-5" />
                        </Button>
                    </div>
                } */}
            </div>
            
            {inputGroup?.step_type === "LLM" || inputGroup?.step_type === 'LLM-Stream' || inputGroup?.step_type === "Identification"  ? (
                <StepLLM 
                inputGroup={inputGroup} 
                index={index} 
                inputGroups={inputGroups} 
                setInputGroups={setInputGroups}
                getPreviousOutputs={getPreviousOutputs}
                getNextSteps={getNextSteps}
                nested={nested}
                />
            ) : inputGroup?.step_type === "Non-LLM"  || inputGroup?.step_type === "Non-LLM - BeX Insights"? (
                <StepNonLLM
                inputGroup={inputGroup} 
                index={index} 
                inputGroups={inputGroups} 
                setInputGroups={setInputGroups}
                getPreviousOutputs={getPreviousOutputs}
                getNextSteps={getNextSteps}
                nested={nested}
                />
            ) : inputGroup?.step_type === "Condition"? (
                <StepCondition
                inputGroup={inputGroup} 
                index={index} 
                inputGroups={inputGroups} 
                setInputGroups={setInputGroups}
                getNextSteps={getNextSteps}
                />
            ) : inputGroup?.step_type === "Loop"? (
                <StepLoop
                inputGroup={inputGroup} 
                index={index} 
                inputGroups={inputGroups} 
                setInputGroups={setInputGroups}
                getNextSteps={getNextSteps}
                userInput={userInput}
                />
            ) : "" }

            {/* {stepResult && 
                <StepResultViewer inputGroup={inputGroup} stepResult={stepResult}/>
            } */}

        </div>
    )
}

export default InputGroup