/* eslint-disable max-lines */
import React, { useContext, useEffect, useRef, useState } from 'react'
import './users-styles.css'
import { httpRequest } from '../../../utils/httpsRequest'
import { AppLoader, LoaderContainerDiv, LoaderSpinner } from '../../../components/LoaderSpinner'
import NewStyledTable from '../../../components/ui/StyledTable'
import { BsPersonFillAdd } from 'react-icons/bs'
import AlertModal from '../../../components/Alertmodal'
import { Context } from '../../../context/GlobalState'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { Button } from 'components/ui/button'
import { organizationService } from 'api'
import { formatDate, isISODateString } from 'utils/timeUtils'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

export default function Users() {

    const { addNewNotifcation } = useContext(Context)
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    // delete user
    const [openDelModal, setOpenDelModal] = useState(false)
    const [deletedUser, setDeletedUser] = useState({})
    const [delUserLoading, setDelUserLoading] = useState(false)
    
    // add user
    const [addUserModal, setAddUserModal] = useState(false)
    const [addKey, setAddKey] = useState(0)

    //show organizations and roles
    const [open, setOpen] = useState(false)
    const [userOrgs, setUserOrgs] = useState([])
    const [userOrgLoading, setUserOrgLoading] = useState(false)

    useEffect(() => {
        httpRequest.get('admin/get-users')
        .then((res) => {
            console.log('res: ', res);
            setUsers(res.data?.map((item) => ({...item, id: item._id})))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <LoaderContainerDiv> <AppLoader size={50} /> </LoaderContainerDiv>;
    }

    const OnAddUserClick = () => {
        setAddUserModal(true)

        // to ensure compoent rerender
        setAddKey(prev => prev+1)
    }

    const handleDelete = (id) => {
        setDeletedUser(users.find(item => item._id === id))
        setOpenDelModal(true)
    }

    const handleDeleteUser = () => {

        setDelUserLoading(true)
        httpRequest.delete(`/admin/users/delete-user/${deletedUser._id}`)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('User deleted successfully', 'success')
            setUsers(users.filter((item) => item._id !== deletedUser._id))
            setOpenDelModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setDelUserLoading(false)
        })
    }

    const handleDisplayUserOrg = (id) => {
        setUserOrgLoading(true)
        setOpen(true)
        organizationService.getOrganizations(id)
        .then((res) => {
            console.log('res: ', res.data);
            const userOrgsAndRoles = res.data.map(org => {
                const orgName= org.name
                const foundUser = org?.members?.find(member => member.id._id === id)
                return {orgName, userRole:foundUser?.role || '-'}
            })

            setUserOrgs(userOrgsAndRoles)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setUserOrgLoading(false)
        })
    }

     const formatDateFunc = (row) => {
            if (typeof row === "string" && isISODateString(row)) {
                return formatDate(row);
            }
    
            return row
    }

    const userRowActions = [
        {'name': 'Organizations', 'onclick': handleDisplayUserOrg},
        {'name': 'Remove', 'onclick': handleDelete},
    ]

    const userTableActions = [
        {'name': 'Add New', 'icon': <BsPersonFillAdd size={18} />, 'onclick': OnAddUserClick}
    ]

    const userColumns = [{ field: 'name', headerName: 'Name'}, { field: 'email', headerName: 'Email'},
         { field: 'lastLogin', headerName: 'Last Login Date', renderCell:(row) => formatDateFunc(row.lastLogin || '-')},
          { field: 'created', headerName: 'Signup Date', renderCell:(row) => formatDateFunc(row.created)}]

    const userOrgsColumns = [{ field: 'orgName', headerName: 'Organization Name'}, { field: 'userRole', headerName: 'Role'}]

    return (
        <div className='admin-users-container'>
            <div className='admin-users-header'>
                <span>Users</span>
                <AddNewUserModal addUserModal={addUserModal} setAddUserModal={setAddUserModal} key={addKey} />
            </div>
            {/* delete user alert */}
            <AlertModal setopenModal={setOpenDelModal} openModal={openDelModal} loading={delUserLoading} onDelete={handleDeleteUser} />

            <div>
                <NewStyledTable
                    columns={userColumns}
                    data={users}
                    multiSelect={false}
                    rowActions={userRowActions}
                    tableActions={userTableActions}
                />
            </div>

            {/* Display user orgs and roles */}
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="sm:max-w-[800px]">
                    <DialogHeader>
                        <DialogTitle>User Organizations</DialogTitle>
                        <DialogDescription>
                            View all user's organizations and their assigned roles.
                        </DialogDescription>
                    </DialogHeader>
                    {userOrgLoading? (
                        <AppLoader />
                    ) : (
                        userOrgs.length > 0 ? (
                            <NewStyledTable
                            columns={userOrgsColumns}
                            data={userOrgs}
                            multiSelect={false}
                            />
                        ) : (
                            <div className='flex justify-center items-center h-9 my-3 p-2 bg-muted rounded-md text-sm'>
                                The user is not associated with any organization.
                            </div>
                        )
                    )}
                
                    <DialogFooter className="sm:justify-end">
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>

    )
}

const AddNewUserModal = ({ addUserModal, setAddUserModal }) => {

    const [btnLoading, setBtnLoading] = useState(false)
    const nameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    useEffect(() => {
        // passwordRef.current.value = 'coolriotsbex123'
    }, [])

    const handleAddUser = () => {

    }

    return (
        <Dialog onOpenChange={setAddUserModal} open={addUserModal}>
            <DialogContent>
                <form>
                    <div className='space-y-3'>
                        <Label> Name </Label>
                        <Input ref={nameRef} type="text" name='name'  /> 
                    </div>
                    <div className='space-y-3'>
                        <Label> Email </Label>
                        <Input ref={emailRef} type="email" name='email'  /> 
                    </div>
                    <div className='space-y-3'>
                        <Label> Password </Label>
                        <Input ref={passwordRef} type="password" name='password'  /> 
                    </div>
                </form>
                <DialogFooter className='flex justify-end w-full'>
                    <Button disabled={btnLoading} onClick={handleAddUser}>
                        {btnLoading ? <LoaderSpinner /> : 'Save Admin'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}