import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input } from "components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Label } from "components/ui/label"
import Parameters from './Parameters'
import FilesUploading from './FilesUploading'
import { commonFields, fineTuningFields, initialState, projects, promptTuningFields } from './data'
import { Step, StepsContainer } from 'components/NewDesignComponents/Steps'
import { Context } from 'context/GlobalState'

const TuningForm2 = ({handleUpdateSubmitExc, editingExperiment, loading}) => {
    const { control, handleSubmit, watch, reset, setValue } = useForm({ defaultValues: initialState })
    // const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [baseModelIssue, setBaseModelIssue] = useState('')
    const {addNewNotifcation} = useContext(Context)
    const watchedValues = watch()
    const isEdit = editingExperiment
    const isNotStarted = editingExperiment?.status? editingExperiment?.status !== 'not started' : false

    useEffect(() => {
        if (editingExperiment) {
            const files = editingExperiment.presignedUrls.map(file => {
                let lastElement;
                if(file?.Key) {
                    const parts = file?.Key?.split('/');
                    lastElement = parts[parts.length - 1];
                }
                return { ...file, name:lastElement || file.name}
            })
        reset({...editingExperiment, files, presignedUrls:files, originalFiles:files})
        setBaseModelIssue(editingExperiment.parameters.base_model);
      } 
    }, [editingExperiment, reset, baseModelIssue])

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    // Function to check if all required fields are filled
    const validateForm = () => {
        const requiredFields = [
            ...commonFields,
            ...(watchedValues.tuning_method === 'fine' ? fineTuningFields : []),
            ...(watchedValues.tuning_method === 'prompt' ? promptTuningFields : [])
        ]

        // Check if all required fields are present and have values
        const allFieldsValid = requiredFields.every((field) => {
            const fieldValue = getNestedValue(watchedValues, field);
            if (Array.isArray(fieldValue)) {
                return fieldValue.length > 0;
            }
            return fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
        })

        return allFieldsValid
        // setIsButtonDisabled(!allFieldsValid)
    }

    const handleCustomNext = (currentStep, handleNext) => {
        if(currentStep === 1 && !checkFirstStep()){
            addNewNotifcation("Please ensure all fields are completed and upload at least one file before proceeding", "warning")
            return
        }

        handleNext()
    }

    const checkFirstStep = () => {
        const { project_id, deployment_name, files, tuning_method} = watchedValues
        const requiredFields = [project_id, deployment_name, files, tuning_method]
        const allFieldsValid = requiredFields.every((field) => {
            if (Array.isArray(field)) {
                return field.length > 0;
            }
            return field !== undefined && field !== null && field !== '';
        })

        return allFieldsValid;
    }

    const requiredSpan = () => {
        return <span className='text-gray-400'>(Required)</span>
    }

    const handleCustomSubmit = (data) => {
        if(!validateForm()){
            addNewNotifcation("Please ensure all required fields are completed before proceeding", "warning")
            return
        }
        handleUpdateSubmitExc(data)
    }

    return (
        <form onSubmit={handleSubmit(handleCustomSubmit)} className="space-y-4 mt-3">
            <StepsContainer
            submitDisabled={ loading || isNotStarted}
            customNext={handleCustomNext}
            submitWord={editingExperiment? 'Update' : 'Submit'}
            onSubmit={handleSubmit(handleCustomSubmit)}
            >
                <Step>
                    {isNotStarted && <p className="p-2 bg-muted rounded-md text-sm text-red-400">
                        The Experiment is {editingExperiment?.status}, therefore you can't edit it.
                    </p>}
                    <div>
                        <Label htmlFor="project_id">Project {requiredSpan()}</Label>
                        <Controller
                            name="project_id"
                            control={control}
                            render={({ field }) => (
                                <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select a project" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {projects.map((project) => (
                                            <SelectItem key={project.id} value={project.id}>
                                                {project.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </div>
                    <FilesUploading watchedValues={watchedValues} setValue={setValue} control={control} requiredSpan={requiredSpan}/>
                    <div>
                        <Label htmlFor="tuning_method">Tuning Method {requiredSpan()}</Label>
                        <Controller
                            name="tuning_method"
                            control={control}
                            render={({ field }) => (
                                <Select onValueChange={field.onChange} value={field.value} disabled={isEdit}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select tuning method" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="fine">Fine Tuning</SelectItem>
                                        <SelectItem value="prompt">Prompt Tuning</SelectItem>
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </div>
                    <div>
                        <Label htmlFor="deployment_name">Deployment Name {requiredSpan()}</Label>
                        <Controller
                            name="deployment_name"
                            control={control}
                            render={({ field }) => (
                                <Input {...field} disabled={isEdit} placeholder="Enter deployment name" />
                            )}
                        />
                    </div>
                </Step>

                <Step>
                    {/* Parameters */}
                    <Parameters control={control} watch={watch} requiredSpan={requiredSpan}/>
                </Step>
            </StepsContainer>
        </form>
  )
}

export default TuningForm2