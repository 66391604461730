import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../context/GlobalState';
import { Combobox } from './ui/combobox';
import CreateSubOrganizationDialog from './AddSubOrgForm';
import { useParams } from 'react-router-dom';

const SubOrgDropdown = ({ urlSubOrg }) => {
    const [subOrgs, setSubOrgs] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [show, setShow] = useState(false);
    
    const { organizations, addNewNotifcation } = useContext(Context);
    const { oragID } = useParams()

    useEffect(() => {
        setSelectedOrganization(organizations?.find(item => item._id === oragID) || null);
        setSubOrgs(organizations?.find(item => item._id === oragID)?.subOrganizations || []);
    }, []);

    const onSelect = (value) => {
        localStorage.setItem('selectedSubOrg', value._id);
        window.location.href = `/organizations/${selectedOrganization?._id}/sub/${value._id}/ai-lab`;
    };

    return (
        <>
            {selectedOrganization?.userRole === 'superAdmin' ? (
                <Combobox
                    items={subOrgs}
                    selectedValue={urlSubOrg}
                    setter={onSelect}
                    buttonProps={{
                    text: 'Add Sub-Organization',
                    onClick: () => setShow(true)
                    }}
                />
            ) : (
                <Combobox
                items={subOrgs}
                selectedValue={urlSubOrg}
                setter={onSelect}
                />
            )}

            <CreateSubOrganizationDialog
            show={show}
            setShow={setShow}
            addNewNotifcation={addNewNotifcation}
            />
        </>
    );
};

export default SubOrgDropdown;
