import { useEffect, useState } from 'react'
import { ActivityFilters } from './ActivityFilters'
import { CalendarIcon, Link, MailCheck, NotebookTabs, User2Icon } from 'lucide-react'
import { NestedPageLayout } from 'components/NewDesignComponents/PageLayout'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { activityLogService } from 'api'
import { Badge } from 'components/ui/badge'
import { formatDateToDDMMYYY } from 'utils/timeUtils'

export default function UserActivityPage() {


    const [filteredActivities, setFilteredActivities] = useState([])
    const [userActivities, setUserActivities] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const handleFilterChange = (filters) => {
        const filtered = userActivities.filter(activity => {
            const activityDate = new Date(activity.timestamp)
            const startDate = filters.startDate ? new Date(filters.startDate) : null
            const endDate = filters.endDate ? new Date(filters.endDate) : null

            const statusCode = activity.responseStatus;
            const getStatusCode = (status) => {
                if (status === '200') {
                    return statusCode >= 200 && statusCode < 300;
                } else if (status === '400') {
                    return statusCode >= 400 && statusCode < 500;
                } else if (status === '500') {
                    return statusCode >= 500;
                }
            }

            return (
                (!filters.userEmail || activity.userId.email.includes(filters.userEmail)) &&
                (!startDate || activityDate >= startDate) &&
                (!endDate || activityDate <= endDate) && (filters.status === 'all' ? true : getStatusCode(filters.status))
            )
        })
        setFilteredActivities(filtered)
    }

    useEffect(() => {
        activityLogService.getActivityLog()
        .then((res) => {
            setFilteredActivities(res.data)
            setUserActivities(res.data)
        }).catch((error) => {
            console.log('error: ', error);
            setError(error.message)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <NestedPageLayout loading={loading} error={error}>
            <Card>
                <CardHeader>
                    <CardTitle> User Activity Details </CardTitle>
                </CardHeader>
                <CardContent>
                    <ActivityFilters onFilterChange={handleFilterChange} />
                    {filteredActivities.map((userActivity) => (
                        <div key={userActivity._id} className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
                            <div className="px-4 py-5 sm:px-6">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">Activity Information</h2>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Details of the user's recent activity.</p>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <User2Icon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            User Email
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{userActivity.userId.email}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <Link className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            API Endpoint
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{userActivity.apiEndpoint}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <Link className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            Method
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{userActivity.method}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <NotebookTabs className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            IP Address
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{userActivity.ipAddress}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            Timestamp
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {formatDateToDDMMYYY(userActivity.timestamp)}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <MailCheck className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            Response Status
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {userActivity.responseStatus}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                                            <MailCheck className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                                            Success
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Badge className={`${userActivity.success ? 'bg-green-500' : 'bg-red-500'} text-white py-1 px-2 text-sm`}>
                                                {userActivity.success ? 'Success' : 'Failed'}
                                            </Badge>
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Query Parameters</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                                                {Object.entries(userActivity?.queryParams || {})?.map(([key, value]) => (
                                                    <li key={key} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                                        <div className="w-0 flex-1 flex items-center">
                                                            <span className="ml-2 flex-1 w-0 truncate">{key}: {value}</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">User Agent</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{userActivity.userAgent}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    ))}
                </CardContent>

            </Card>

        </NestedPageLayout>
    )
}

