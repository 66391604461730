import { useContext, useState } from "react"
import Collection, { CreateNewCollectionDialog } from "./Collection"
import { SubOrgContext } from "context/SubOrganizationContext"
import { OrganizationContext } from "context/OrganizationContext"
import { PageContent, PageContentHeader, PageContentHeaderText, PageContentHeaderTitle, PageDescription, PageHeader, PageHeaderAction, PageLayout, PageSubTitle, PageTitle, PageTitles } from 'components/NewDesignComponents/PageLayout';
import { Button } from "components/ui/button"

export default function KnowledgeBuilder() {

    const [openModal, setOpenModal] = useState(false)

    const { collections, actions } = useContext(SubOrgContext)
    const { selectedOrganization } = useContext(OrganizationContext)


    return (
        <PageLayout>
            <PageHeader>
                <PageTitles>
                    <PageTitle>
                        Create your Enterprise
                    </PageTitle>
                    <PageSubTitle>
                        Knowledge Base
                    </PageSubTitle>
                    <PageDescription>
                        Centralize your organization's knowledge with AI-powered insights. Build a dynamic, accessible repository that empowers your teams to collaborate, innovate, and execute with confidence.
                    </PageDescription>
                </PageTitles>
                <PageHeaderAction>
                    <Button onClick={() => setOpenModal(true)} className='w-full'> Add new collection </Button>
                </PageHeaderAction>
            </PageHeader>
            <PageContent>
                <PageContentHeader>
                    <PageContentHeaderTitle>Knowledge Builder:</PageContentHeaderTitle>
                    <PageContentHeaderText>Manage your Enterprise Knowledge Base</PageContentHeaderText>
                </PageContentHeader>
                <div className="w-full mx-auto p-2 md:p-6">
                    <Collection collections={collections} isMilvusActivated={selectedOrganization.isMilvusActivated} actions={actions} />
                </div>
                {/* create new collection dialog */}
                <CreateNewCollectionDialog isMilvusActivated={selectedOrganization.isMilvusActivated} actions={actions} openModal={openModal} setOpenModal={setOpenModal} />
            </PageContent>
        </PageLayout>
    )
}
