import React, { forwardRef } from 'react'
import { cn } from "../../lib/utils"
import { AppLoader } from 'components/LoaderSpinner'


export const PageLayout = forwardRef(({ className, children }, ref) => {
    return (
        <div ref={ref} className={cn('flex flex-col overflow-y-scroll w-full', className)}>
            {children}
        </div>
    )
})

export const NestedPageLayout = ({ className, children }) => {

    return (
        <div className={cn('flex flex-col overflow-y-scroll w-full p-8', className)}>
            {children}
        </div>
    )
}

export const PageHeader = forwardRef(({ className, children }, ref) => {
    return (
        <div ref={ref} className={cn('w-full flex flex-col md:flex-row bg-[#d3d3d4] md:min-h-[300px] p-4 md:p-8', className)}>
            {children}
        </div>
    )
})


export const PageTitles = forwardRef(({ className, children }, ref) => {
    return (
        <div ref={ref} className={cn('h-full flex flex-col mr-4 overflow-hidden', className)}>
            {children}
        </div>
    )
})



export const PageTitle = forwardRef(({ className, children }, ref) => {
    return (
        <h1 ref={ref} className={cn('text-2xl font-bold', className)}>
            {children}
        </h1>
    )
})

export const PageSubTitle = forwardRef(({ className, children }, ref) => {
    return (
        <h1 ref={ref} className={cn('text-2xl font-bold mt-2 text-red-500', className)}>
            {children}
        </h1>
    )
})

export const PageDescription = forwardRef(({ className, children }, ref) => {
    return (
        <p ref={ref} className={cn('mt-4 text-lg', className)}>
            {children}
        </p>
    )
})

export const PageHeaderAction = forwardRef(({ className, children }, ref) => {
    return (
        <div ref={ref} className={cn('md:min-w-[400px] mt-3 md:mt-0 flex items-end', className)}>
            {children}
        </div>
    )
})

export const PageContent = forwardRef(({ className, children, loading=false, error=null }, ref) => {

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    if (error) {
        return <div className='flex flex-grow flex-col justify-center items-center'>{error}</div>
    }

    return (
        <div ref={ref} className={cn('flex flex-col p-4 md:p-8 w-full', className)}>
            {children}
        </div>
    )
})


export const PageContentHeader = forwardRef(({ className, children }, ref) => {
    return (
        <div ref={ref} className={cn('flex items-center gap-2', className)}>
            {children}
        </div>
    )
})

export const PageContentHeaderTitle = forwardRef(({ className, children }, ref) => {
    return (
        <h2 ref={ref} className={cn('text-red-500 font-bold text-lg', className)}>
            {children}
        </h2>
    )
})


export const PageContentHeaderText = forwardRef(({ className, children }, ref) => {
    return (
        <p ref={ref} className={cn('font-bold text-lg', className)}>
            {children}
        </p>
    )
})
