import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "components/ui/card"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion"
  
export function EmailHandlingRes({executionLog}) {
    const executionLogArray = executionLog
    const lastStep = executionLog[executionLog?.length - 1]
    const aiResponse = lastStep["Accumulated Output"]?.FormattedAnswer || "No AI response available."
  
    return (
        <div className=" grid">    
            <Card className="mb-8 overflow-hidden">
                <CardHeader>
                    <CardTitle>AI Response</CardTitle>
                </CardHeader>
                <CardContent>
                    <p className="whitespace-pre-wrap">{aiResponse}</p>
                </CardContent>
            </Card>

            <Card>
                <CardHeader>
                    <CardTitle>Execution Log</CardTitle>
                    <CardDescription>Details of the AI processing steps</CardDescription>
                </CardHeader>
                <CardContent>
                    <Accordion type="single" collapsible className="w-full">
                        {executionLogArray.map((step, index) => (
                            <AccordionItem key={index} value={`step-${index}`}>
                                <AccordionTrigger>Step {index + 1}: {step["Step Type"]}</AccordionTrigger>
                                <AccordionContent>
                                    <div className="space-y-2 pl-4">
                                        <p><strong>Action:</strong> {step.Action}</p>
                                        {/* <p><strong>Params:</strong> <pre className="whitespace-pre-wrap">{JSON.stringify(step.Params, null, 2)}</pre></p> */}
                                        {/* <p><strong>Outputs:</strong> <pre className="whitespace-pre-wrap">{JSON.stringify(step.Outputs, null, 2)}</pre></p> */}
                                        <div>
                                            <strong>Outputs:</strong>
                                            <div className=" w-full rounded-md border p-2 mt-1">
                                                {Object.entries(step.Outputs).map(([key, value]) => (
                                                    <p key={key} className="mb-1">
                                                        <span className="font-medium">{key}:</span> {typeof value === 'string' ? value : JSON.stringify(value)}
                                                    </p>
                                                  ))}
                                            </div>
                                        </div>
                                        {/* <p><strong>Routing:</strong> <pre className="whitespace-pre-wrap">{JSON.stringify(step.Routing, null, 2)}</pre></p> */}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </CardContent>
            </Card>
        </div>
    )
  }
  