import { createApiClient } from './';

// Notice since we don't have a production environment, we are using the staging environment as the production environment
const FEO_API_BASE_URL = process.env.REACT_APP_FEO_SERVER_URL
const FEO_API_ENDPOINT_PREFIX = "/feo-v3/api";

/**
 * HTTP client for the BeX FEO V3 API endpoints /  services.
 *
 * @type {ApiClient}
 * @see {@link https://github.com/coolriots/BeX-FEO-V3}
 */
export const feoHttpClient = createApiClient(
    FEO_API_BASE_URL, 
    FEO_API_ENDPOINT_PREFIX
);