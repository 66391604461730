import { Card, CardHeader, CardTitle, CardContent } from "components/ui/card"
import { Button } from "components/ui/button"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Context } from "context/GlobalState"
import { channelService } from "api"
import AlertModal from "components/Alertmodal"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { formatDateToDDMMYYY } from "utils/timeUtils"
import { getOrganizationMember } from "utils/organizationUtils"
import { OrganizationContext } from "context/OrganizationContext"
import { ChannelTypeUpdateDialog } from "./ChannelType"
import UpdateWebChannelWrapper from "./WebChatUpdate"
import { Cog, Mail, MessageCircleMore, MoreVertical, Trash2 } from "lucide-react"

export default function ChannelCard({ channel, setEmailChannels }){

    const {  _id, topic, type, created, createdBy } = channel

    const [delBtn, setDelBtn] = useState(false)
    const [delModal, setDelModal] = useState(false)

    const [emailUpdateDialog, setEmailUpdateDialog] = useState(false)
    const [webChatUpdateDialog, setWebChatUpdateDialog] = useState(false)
    const [createdByName, setCreatedByName] = useState('')

    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)
    const { selectedOrganization } = useContext(OrganizationContext)

    const geyChannelIcon = () => {
        switch (type) {
            case "Email":
                return <Mail size={28} color='white' />;
            case "Webchat":
                return <MessageCircleMore size={28} color='white' />;
        }
    }

    useEffect(() => {
        getOrganizationMember(createdBy, selectedOrganization)
        .then((name) => {
            setCreatedByName(name)
        }).catch((error) => {
            console.log('error: ', error);
        })
    }, [])

    const handleDeleteEmail = () => {

        setDelBtn(true)
        channelService.deleteChannel(oragID, subOragID, _id)
        .then(() => {
            setEmailChannels(prev => prev.filter(item => item._id !== _id))
            addNewNotifcation('Channel deleted successfully', 'success')
            setDelModal(false)
        }).catch(() => {
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setDelBtn(false)
        })
    }

    const handleChannelUpdate = () => {
        switch (type) {
            case 'Email':
                setEmailUpdateDialog(true);
                break;
            case 'Webchat':
                setWebChatUpdateDialog(true);
                break;
            default:
                setEmailUpdateDialog(true);
                break;
        }
    }

    return (
        <Card>
            <CardHeader>
                <div className='flex w-full justify-between items-center'>
                    <CardTitle>{topic}</CardTitle>
                    <DropdownMenu modal={false}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <MoreVertical className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <DropdownMenuItem onClick={handleChannelUpdate}>
                                <Cog className="mr-2 h-4 w-4" />
                                <span>Manage</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => setDelModal(true)} className="text-red-600">
                                <Trash2 className="mr-2 h-4 w-4" />
                                <span>Delete</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardHeader>
            <CardContent>
                <div className="flex gap-3 items-center">
                    <span className='rounded-full size-16 flex justify-center items-center bg-[#b1092a]'>
                        {geyChannelIcon()}
                    </span>
                    <ul className="flex flex-col">
                        {/* <li>Purpose: </li> */}
                        <li className='text-sm text-muted-foreground'>Type: <span> {type} </span> </li>
                        <li className='text-sm text-muted-foreground'>Created on: <span>{formatDateToDDMMYYY(created)}</span> </li>
                        <li className='text-sm text-muted-foreground'>Created by: <span>{createdByName || '-'}</span> </li>
                    </ul>
                </div>
            </CardContent>

            {/* update channel dialog */}
            <ChannelTypeUpdateDialog
            setEmailChannels={setEmailChannels}
            isOpen={emailUpdateDialog}
            setOpenEmailModal={setEmailUpdateDialog} 
            channel={channel}
            />

            {/* update web chat channel dialog */}
            <UpdateWebChannelWrapper
            setEmailChannels={setEmailChannels}
            isOpen={webChatUpdateDialog}
            setOpenEmailModal={setWebChatUpdateDialog}
            channel={channel}
            />
            
            {/* delete collection modal */}
            <AlertModal loading={delBtn} openModal={delModal} setopenModal={setDelModal} onDelete={handleDeleteEmail} />
        </Card>
    )
}
