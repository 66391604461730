import React from "react"
import { Badge } from "components/ui/badge"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion"



const OpCodeBuilderStepDetails = ({ step, stepIndex }) => {
  const renderKeyValuePairs = (obj, isDynamicInputs = false) => {
    return Object.entries(obj).map(([key, value], index) => (
        <div key={key} className="flex justify-between items-start py-1 border-b last:border-b-0">
            <span className="font-medium text-sm">{isDynamicInputs ? `${index + 1}:` : `${key}:`}</span>
            <span className="text-sm text-right">{isDynamicInputs?`step_${stepIndex + 2}:` :JSON.stringify(value)}</span>
        </div>
    ))
  }

  return (
      <div className="space-y-4">
          <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">{step.step_type}</h3>
              <Badge variant="outline">step_{stepIndex + 1}</Badge>
          </div>
          <Accordion type="single" collapsible className="w-full">
              {step.unique_name && (
                  <AccordionItem value="instruction_name">
                      <AccordionTrigger>Instruction Name</AccordionTrigger>
                      <AccordionContent>{step.unique_name}</AccordionContent>
                  </AccordionItem>
              )}
              {step.model_type && (
                  <AccordionItem value="model_type">
                      <AccordionTrigger>Model Type</AccordionTrigger>
                      <AccordionContent>{step.model_type}</AccordionContent>
                  </AccordionItem>
              )}
              {step.registry_type && (
                  <AccordionItem value="registry_type">
                      <AccordionTrigger>Registry Type</AccordionTrigger>
                      <AccordionContent>{step.registry_type}</AccordionContent>
                  </AccordionItem>
              )}
              {step.registry_key && (
                  <AccordionItem value="registry_key">
                      <AccordionTrigger>Registry Key</AccordionTrigger>
                      <AccordionContent>{step.registry_key}</AccordionContent>
                  </AccordionItem>
              )}
              {/* {step.fixed_inputs && Object.keys(step.input).length > 0 && (
                  <AccordionItem value="input">
                      <AccordionTrigger>Fixed Inputs</AccordionTrigger>
                      <AccordionContent>{renderKeyValuePairs(step.input)}</AccordionContent>
                  </AccordionItem>
              )} */}
              {/* {step.input && Object.keys(step.input).length > 0 && (
                  <AccordionItem value="dynamic_inputs">
                      <AccordionTrigger>Dynamic Inputs</AccordionTrigger>
                      <AccordionContent>{renderKeyValuePairs(step.dynamic_inputs, true)}</AccordionContent>
                  </AccordionItem>
              )} */}
              {step.output && (
                  <AccordionItem value="output">
                      <AccordionTrigger>Output</AccordionTrigger>
                      <AccordionContent>{renderKeyValuePairs(step.output)}</AccordionContent>
                  </AccordionItem>
              )}
              {step.next_step && (
                  <AccordionItem value="next_step">
                      <AccordionTrigger>Next Step</AccordionTrigger>
                      <AccordionContent>{renderKeyValuePairs(step.next_step, true)}</AccordionContent>
                  </AccordionItem>
              )}
          </Accordion>
          <div className="flex justify-between items-center text-sm">
              <span>Is First Step:</span>
              <Badge variant={step.is_first_step ? "default" : "secondary"}>{step.is_first_step ? "Yes" : "No"}</Badge>
          </div>
      </div>
  )
}

export default OpCodeBuilderStepDetails

