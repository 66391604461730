import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import React from 'react'

export default function AssistantForm({ form, setForm }) {

    return (
        <div className='space-y-4'>
            <div className="space-y-2">
                <Label htmlFor="name" className="text-md">Name</Label>
                <Input
                    value={form.title}
                    onChange={(e) => setForm(prev => ({...prev, title: e.target.value}))}
                    id="name"
                    placeholder="Enter assistant name"
                    className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                />
            </div>
            <div className="space-y-2">
                <Label htmlFor="description" className="text-md">Description</Label>
                <Input
                    value={form.description}
                    onChange={(e) => setForm(prev => ({...prev, description: e.target.value}))}
                    id="description"
                    placeholder="Enter assistant description"
                    className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                />
            </div>
        </div>
    )
}
