import { Input } from 'components/ui/input';
import React, { useEffect, useState } from 'react'

const SearchItems = ({items, handleFilterItems}) => {
    const [searchTerm, setSearchTerm] = useState("");
    
    useEffect(() => {
        handleFilterItems(searchTerm)
    }, [items])

    return (
        <div className="flex gap-2 items-center w-full">
            {/* <Search className="w-5 h-5" /> */}
            <Input
                type="text"
                placeholder="Search opcodes..."
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    handleFilterItems(e.target.value)
                }}
                className="w-full mx-auto bg-white"
            />
        </div>
    )
}

export default SearchItems