import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Slider } from 'components/ui/slider'
import React from 'react'
import { Controller } from 'react-hook-form'

const PromptTuning = ({control, max_input_tokens, max_output_tokens, requiredSpan}) => {
    return (
        <div className='space-y-5'>
            <div>
                <Label htmlFor="parameters.max_input_tokens">Max Input Tokens: {max_input_tokens}</Label>
                <Controller
                name="parameters.max_input_tokens"
                control={control}
                render={({ field }) => (
                    <Slider
                    min={1}
                    max={256}
                    step={1}
                    value={[field.value]}
                    onValueChange={(value) => {
                        field.onChange(value[0])
                    }}
                    />
                )}
                />
            </div>
    
            <div>
                <Label htmlFor="parameters.max_output_tokens">Max Output Tokens: {max_output_tokens}</Label>
                <Controller
                name="parameters.max_output_tokens"
                control={control}
                render={({ field }) => (
                    <Slider
                    min={1}
                    max={128}
                    step={1}
                    value={[field.value]}
                    onValueChange={(value) => {
                        field.onChange(value[0])
                    }}
                    />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.tuning_type">Tuning Type {requiredSpan()}</Label>
                <Controller
                name="parameters.tuning_type"
                control={control}
                render={({ field }) => (
                    <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select tuning type" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="prompt_tuning">Prompt Tuning</SelectItem>
                        </SelectContent>
                    </Select>
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.init_text">initialization Text {requiredSpan()}</Label>
                <Controller
                name="parameters.init_text"
                control={control}
                render={({ field }) => (
                    <Input {...field} placeholder="Enter init text" />
                )}
                />
            </div>

            <div>
                <Label htmlFor="parameters.init_method">initialization Method {requiredSpan()}</Label>
                <Controller
                name="parameters.init_method"
                control={control}
                render={({ field }) => (
                    <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger>
                            <SelectValue placeholder="Select init method" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="random">Random</SelectItem>
                            <SelectItem value="text">Text</SelectItem>
                        </SelectContent>
                    </Select>
                )}
                />
            </div>
        </div>
    )
}

export default PromptTuning