/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { assistantInstanceService } from 'api'
import { useParams } from 'react-router-dom'
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import AssistantForm from './AssistantForm';
import { Context } from 'context/GlobalState';



const ManageAssistantDialog = ({ show, setShow, selectedCard, setAssistantInstances }) => {

    const [formData, setFormData] = useState({
        title: '',
        description: '',
    })

    const [btnLoading, setBtnLoading ] = useState(false)
    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()


    useEffect(() => {
        setFormData({
            title: '',
            description: '',
            ...selectedCard
        })

    }, [show])

    const handleUpdateAssistant = async () => {

        if (!formData.title || !formData.description) {
            return addNewNotifcation('Please fill in all fields', 'warning')
        }

        setBtnLoading(true)
        const updateObj = {
            id: selectedCard._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
            title: formData.title,
            description: formData.description
        }

        try {
            const res = await assistantInstanceService.updateAssistantInstance(updateObj)
            console.log('res: ', res);
            setAssistantInstances(prev => prev.map(item => item._id === selectedCard._id ? {...formData} : {...item}))
            addNewNotifcation('assistant info updated successfully', 'success')
            setShow(false)
        } catch(error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        } finally {
            setBtnLoading(false)
        }
    }

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-lg bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Update assistant</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Update assistant information.</DialogDescription>
                </DialogHeader>

                <div>
                    <AssistantForm form={formData} setForm={setFormData} />
                </div>

                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant='outline' onClick={() => setShow(false)}>Cancel</Button>
                    <Button disabled={btnLoading} onClick={handleUpdateAssistant}>{btnLoading ? 'Updating assistant...' : 'Update assistant'}</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ManageAssistantDialog