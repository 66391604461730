

import { useState } from 'react'
import { Input } from 'components/ui/input'
import { Search } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"

export function SearchBar({ selectModelType, allListings, setListings }) {
    const [search, setSearch] = useState('')

    const handleSearch = (value) => {
        console.log('selectModelType: ',selectModelType);
        
        setSearch(value)
        if (value === '') {
            setListings(allListings)
        } else {
            const lowercasedTerm = value.toLowerCase()
            setListings(allListings.filter(item => 
                item.unique_name?.toLowerCase()?.includes((lowercasedTerm)) &&
                (selectModelType === 'All' || item.model_type === selectModelType)
            ))
        }

    }

    return (
        <div className="relative flex-grow">
            <Input
                type="text"
                placeholder="Search listings..."
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                className="pl-10 bg-white"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
    )
    
}



export function FilterBar({ selectModelType, setSelectModelType, allListings, setListings }) {
    const instructionTypes = [
        {name: 'All', value: 'All'},
        {name: 'Watson', value: 'IBM'},
        {name: 'Groq', value: 'Groq'},
    ]

    const onChange = (value) => {
        setSelectModelType(value)
        if (value === 'All') {
            setListings(allListings)
        } else {
            setListings(allListings.filter(item => item.model_type === value))
        }
    }

    return (
        <Select onValueChange={onChange} value={selectModelType}>
            <SelectTrigger className="w-[180px] bg-white" >
                <SelectValue placeholder="Filter model type" />
            </SelectTrigger>
            <SelectContent>
                {instructionTypes.map((type) => (
                    <SelectItem key={type.value} value={type.value}>
                        {type.name}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}

