import { bexHttpClient } from "api/clients";

class InstructionService {

    /**
     * Creates a new model (Instruction).
     * @param {Object} data - The data for the new model.
     * @returns {Promise<Object>} The response containing the created model.
     */
    async createModel(data) {
        const response = await bexHttpClient.post(`/redis/models/model/create`, data);
        return response;
    }

    /**
     * Updates an existing model (Instruction).
     * @param {Object} data - The updated data for the model.
     * @returns {Promise<Object>} The response containing the updated model.
     */
    async updateModel(data) {
        const response = await bexHttpClient.put(`/redis/models/model/update`, data);
        return response;
    }

    /**
     * Gets a model (Instruction) by ID.
     * @param {string} organizationId - The ID of the organization.
     * @param {string} subOrganizationId - The ID of the sub-organization.
     * @param {string} modelType - The type of the model.
     * @param {string} unique_name - The unique name of the model.
     * @returns {Promise<Object>} The response containing the model.
     */
    async getModel(organizationId, subOrganizationId, modelType, unique_name) {
        const response = await bexHttpClient.get(
            `/redis/models/model/get?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}&modelType=${modelType}&unique_name=${unique_name}`
        );
        return response;
    }

    /**
     * Lists all models (Instructions).
     * @param {string} organizationId - The ID of the organization.
     * @param {string} subOrganizationId - The ID of the sub-organization.
     * @param {string} modelType - The type of the models to list (optional).
     * @returns {Promise<Array>} The response containing the list of models.
     */
    async listModels(organizationId, subOrganizationId, modelType=null) {
        let url = `/redis/models/model/list?organizationId=${organizationId}&subOrganizationId=${subOrganizationId}`

        if (modelType) {
            url += `&modelType=${modelType}`
        }
        const response = await bexHttpClient.get(url);
        return response;
    }

    /**
     * Deletes models by unique names.
     * @param {Object} data - The payload containing the list of unique names to delete.
     * @returns {Promise<Object>} The response confirming the deletion.
     */
    async deleteModels(data) {
        const response = await bexHttpClient.delete(`/redis/models/model/delete`, { data });
        return response;
    }
}

const instructionService = new InstructionService();
export { instructionService };