import { opCodeService } from 'api/services/PROJECT-O/OpCodeBuilder.service';
import { Label } from "components/ui/label";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/ui/select";
import React, {useEffect, useState } from 'react'
import StepNonLLMAPI2 from './StepNonLLMAPI2';
import NextStep from '../NextStep';

const StepNonLLMAPI = ({inputGroup, index, inputGroups, setInputGroups, getPreviousOutputs, getNextSteps}) => {
    const [apis, setApis] = useState([]);
    const [functionRegistry, setFunctionRegistry] = useState({});
    const [selectedFunctionProps, setSelectedFunctionProps] = useState({});

    useEffect(() => {
        // Fetch the list of functions and their properties from FUNCTION_REGISTRY
        const fetchFunctions = async () => {
            try {
                const res = await opCodeService.getFunctionRegistry();
                if (res.data && typeof res.data === 'object') {
                    console.log(res.data)
                    setFunctionRegistry(res.data);
                    setApis(Object.keys(res.data).filter(key => key === "KNOWLEDGE_RETRIEVAL_API"));

                    // Set properties if a function is already selected
                    // if (inputGroup?.registry_key) {
                        setSelectedFunctionProps(res.data[inputGroup.registry_key]?.properties || {});
                    // }
                }
            } catch (err) {
                console.error("Error fetching functions:", err);
            }
        };

        fetchFunctions();
    }, []);

    // const firstStep = inputGroups[0];
    // let firstStepDynamicInput = [];
    // if (firstStep?.step_type === "Identification") {
    //     firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
    //         stepIndex:0,
    //         stepId:inputGroups[0]?.step_id,
    //         prevOutp:{
    //         value,
    //         label: "Step 2 Input",
    //         }
    //     })) || [];
    // } 
    // else if (firstStep?.step_type === "Non-LLM" && firstStep.dynamic_inputs?.query_text) {
    //     firstStepDynamicInput = [
    //         {
    //             stepIndex:0,
    //             stepId:inputGroups[0]?.step_id,
    //             prevOutp:{
    //             value: firstStep.dynamic_inputs.query_text,
    //             label: "Step 2 Input",
    //             }
    //         },
    //     ];
    // } else if (firstStep?.step_type === "Non-LLM - BeX Insights") {
    //     firstStepDynamicInput = firstStep.dynamic_inputs?.map((value) => ({
    //         stepIndex:0,
    //         stepId:inputGroups[0]?.step_id,
    //         prevOutp:{
    //         value,
    //         label: "Step 2 Input",
    //         }
    //     })) || [];
    // }

    // Combine previous outputs with the first step's dynamic input
    // const inputSelection = [
    //     // ...firstStepDynamicInput,
    //     ...getPreviousOutputs(index),
    // ];

    const handleInputChange = (field, value) => {
        const newInputGroups = [...inputGroups];
        if (field === "registry_key") {
            newInputGroups[index].registry_key = value;
            setSelectedFunctionProps(functionRegistry[value]?.properties || {});
        } else if (field === "output") {
            newInputGroups[index].output.Output = value;
        } else if (selectedFunctionProps[field] === "fixed") {
            if (field === "top_k" || field === "top_n" || field === "offset") {
                newInputGroups[index].input[field] = parseInt(value) || 0;
            } else if (field === "use_rerank") {
                newInputGroups[index].input[field] = value === "true";
            } else {
                newInputGroups[index].input[field] = field === "collection_id"? value?._id : value;
            }
        } else if (selectedFunctionProps[field] === "dynamic") {
            newInputGroups[index].input[field] = value;
        }
        setInputGroups(newInputGroups);
    };
    
    return (
        <div className="grid grid-cols-5 gap-4">
            {/* Function Name Selection */}
            <div className="col-span-1">
                <Label htmlFor={`functionName-${index}`}>API Name</Label>
                <Select
                    onValueChange={(value) => handleInputChange("registry_key", value)}
                    value={inputGroup?.registry_key || ""}
                >
                    <SelectTrigger className="w-full bg-white">
                        <SelectValue placeholder="Select an API" />
                    </SelectTrigger>
                    <SelectContent>
                        {apis.map((funcName) => (
                            <SelectItem key={funcName} value={funcName}>
                                {funcName}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
            <StepNonLLMAPI2
            getPreviousOutputs={getPreviousOutputs}
            inputGroup={inputGroup}
            index={index}
            handleInputChange={handleInputChange}
            inputGroups={inputGroups}
            selectedFunctionProps={selectedFunctionProps}
            />

            <div className="col-span-1">
                <NextStep
                getNextSteps={getNextSteps}
                inputGroup={inputGroup}
                inputGroups={inputGroups}
                setInputGroups={setInputGroups}
                index={index}
                />
            </div>

        </div>
    )
}

export default StepNonLLMAPI