/* eslint-disable max-lines */
import React, { useState, useCallback, useEffect } from 'react'
import { Button } from 'components/ui/button'
import { DndProvider, useDrop, useDrag } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { GripVertical, Plus, Trash2, ChevronDown, ChevronUp, Lock } from 'lucide-react'
import { cn } from 'lib/utils'

export const Step = ({
  children,
  indicator,
  canAddBefore,
  canAddAfter,
  isDraggable,
  canDelete,
  isDisabled,
  onAddStep,
  onDeleteStep,
  dragRef,
  title,
  className,
  isCompleted,
  isLast, // Add this prop to know if it's the last step
  stepsLen
}) => {
  const [isMinimized, setIsMinimized] = useState(isDisabled)

  const handleToggleMinimize = () => {
    if (!isDisabled) {
      setIsMinimized(prev => !prev)
    }
  }

  useEffect(() => {
    if(isDisabled){
      setIsMinimized(true)
    }else {
      setIsMinimized(false)
    }
  }, [isDisabled])
  
  return (
      <div className="flex gap-4">
          <div className={`flex flex-col items-center ${isLast ? "items-end" : "items-start"}`}>
              {isLast && stepsLen !== 1 && (
                  <div className="w-0.5 bg-muted-foreground flex-1 my-2" style={{ minHeight: "40px" }} />
                )}
              <div
                  className={cn(
                      "flex h-8 w-8 items-center justify-center rounded-full text-sm font-medium transition-colors",
                      isDisabled ? "bg-muted text-muted-foreground" : "bg-primary text-primary-foreground",
                      isCompleted && "bg-primary text-primary-foreground",
                      "border-2 border-background shadow",
                  )}
              >
                  {isCompleted ? (
                      <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                  ) : (
                      indicator
                  )}
              </div>
              {!isLast && (
                  <div className="w-0.5 bg-muted-foreground flex-1 my-2" style={{ minHeight: "40px" }} />
              )}
          </div>
          <div className={cn(
              "flex-1 mb-4 p-4 border border-gray-400 shadow-md rounded-lg transition-opacity",
              isDisabled && "opacity-50 cursor-not-allowed",
              className
          )}>
              <div className="flex items-center mb-2">
                  {isDraggable ? (
                      <div ref={dragRef} className="cursor-move mr-2">
                          <GripVertical className="h-5 w-5 text-gray-400" />
                      </div>
                  ) : (
                      <div className="mr-2">
                          <Lock className="h-5 w-5 text-gray-400" />
                      </div>
                  )}
                  <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={handleToggleMinimize}
                      aria-label={isMinimized ? "Expand step" : "Minimize step"}
                      className="flex items-center gap-2 text-md font-semibold px-0"
                      disabled={isDisabled}
                  >
                      {isMinimized ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />} 
                      <span>{title}</span>
                  </Button>
                  {!isDisabled && canAddBefore && onAddStep && (
                      <Button 
                          variant="outline" 
                          size="sm" 
                          onClick={() => onAddStep('before', indicator, children)}
                          className="ml-auto mr-2"
                          aria-label="Add step before"
                          disabled={isDisabled}
                      >
                          <Plus className="h-4 w-4 mr-1" /> Before
                      </Button>
                  )}
                  {!isDisabled && canAddAfter && onAddStep && (
                      <Button 
                          variant="outline" 
                          size="sm" 
                          onClick={() => onAddStep('after', indicator, children)}
                          aria-label="Add step after"
                          className={`${canAddBefore? "" : "ml-auto"}`}
                          disabled={isDisabled}
                      >
                          <Plus className="h-4 w-4 mr-1" /> After
                      </Button>
                  )}
                  {!isDisabled && canDelete && onDeleteStep && (
                      <Button 
                          variant="outline" 
                          size="sm" 
                          onClick={() => onDeleteStep(indicator)}
                          className="ml-2"
                          aria-label="Delete step"
                          disabled={isDisabled}
                      >
                          <Trash2 className="h-4 w-4 mr-1" /> Delete
                      </Button>
                  )}
              </div>
              
              <div className={cn(isMinimized? "hidden" : "flex w-full")}>
                  {children}
              </div>
          </div>
      </div>
  )
}

  // function StepIndicator({ indicator, isActive, isCompleted }) {
  //     return (
  //         <div
  //         className={cn(
  //           "flex h-8 w-8 items-center justify-center rounded-full text-sm font-medium transition-colors",
  //           isActive && "bg-primary text-primary-foreground",
  //           isCompleted && "bg-primary text-primary-foreground",
  //           !isActive && !isCompleted && "bg-muted text-muted-foreground",
  //           "border-2 border-background shadow"
  //         )}
  //         aria-current={isActive ? "step" : undefined}
  //       >
  //             {isCompleted ? (
  //                 <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  //                     <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
  //                 </svg>
  //             ) : (
  //               indicator
  //             )}
  //         </div>  
  //     )
  // }
  
  const DraggableStep = ({ index, moveStep, parentId, ...props }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'STEP',
      item: { index, parentId },
      canDrag: props.isDraggable && !props.isDisabled,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })
  
    const [, drop] = useDrop({
      accept: 'STEP',
      hover(item) {
        if (!props.isDraggable || props.isDisabled) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        if (item.parentId !== props.itemId) {
          return
        }

        if (dragIndex === hoverIndex) {
          return
        }

        moveStep(dragIndex, hoverIndex)
        item.index = hoverIndex
      },
    })
  
    return (
        <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <Step {...props} dragRef={drag}  />
        </div>
    )
  }
  
  const findSteps = (children) => {
    const result = [];
    const stack = React.Children.toArray(children);
  
    while (stack.length) {
      const child = stack.pop();
      if (React.isValidElement(child)) {
        if (child.type === Step) {
          result.unshift(child);
        } else if (typeof child.type === 'function') {
          try {
            // For functional components, we render them and push their children to the stack
            const renderedChild = child.type(child.props);
            if (renderedChild && renderedChild.props && renderedChild.props.children) {
              stack.push(...React.Children.toArray(renderedChild.props.children));
            }
          } catch (error) {
            console.error('Error rendering functional component:', error);
            // Optionally, you can add some fallback behavior here
          }
        } else if (child.props && child.props.children) {
          stack.push(...React.Children.toArray(child.props.children));
        }
      }
    }
  
    return result;
  };
  
  export const StepsContainer = ({ children, onAddStep, onDeleteStep, onMoveStep, className, parentId, isNotNestead }) => {
    const [steps, setSteps] = useState([]);

    const rearrangeStepNumbers = (newSteps) => {
      return newSteps.map((step, index) => ({
        ...step,
        props: {...step.props, indicator :isNotNestead? (index === 0 ? 'S' : (index === newSteps.length - 1 ? 'E' : `${index}`)) : `${index + 1}`}
      }));
    }

    useEffect(() => {
      const stepsArray = findSteps(children)
      if(stepsArray.length > 0){
        setSteps(stepsArray);
      }
    }, [children]);

    const moveStep = useCallback((dragIndex, hoverIndex) => {
      const newSteps = [...steps]
      const [removed] = newSteps.splice(dragIndex, 1)
      newSteps.splice(hoverIndex, 0, removed)
      const rearrangedSteps = rearrangeStepNumbers(newSteps);
      onMoveStep(dragIndex, hoverIndex, rearrangedSteps, parentId)
      setSteps(rearrangedSteps)
    }, [steps])

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={cn(' w-full ', className)}>
                {/* <div className="flex flex-col items-center justify-between relative mb-1">
                    {steps?.map((step, index) => (
                        <React.Fragment key={index}>
                            <StepIndicator 
                              number={index + 1} 
                              isActive={!step.props.isDisabled} 
                              isCompleted={step?.props?.isCompleted || false}
                              indicator={step.props.indicator}
                            />
                            {index < steps?.length - 1 && (
                                <div   
                                className="flex-1 w-0.5 bg-muted-foreground h-full" 
                                aria-hidden="true" 
                                />
                            )}
                        </React.Fragment>
                    ))}
                </div> */}
                <div className="space-y-0 w-full">
                    {steps.map((step, index) => 
                        <DraggableStep
                        key={step.props.indicator || `step-${index}`}
                        index={index}
                        moveStep={moveStep}
                        parentId={parentId} 
                        {...step.props}
                        onAddStep={step.props.onAddStep || onAddStep}
                        onDeleteStep={step.props.onDeleteStep || onDeleteStep}
                        isLast={index === steps.length - 1}
                        stepsLen={steps.length}
                        />
                    )}
                </div>
            </div>
        </DndProvider>
    )
  }
  
