import React from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MessageParser = ({text}) => {

    const customComponents = {
        a: ({ node, ...props }) => (
            <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        ),
        h1: ({ node, ...props }) => (
            <h1 className="font-bold mb-3" {...props} />
        ),
        h2: ({ node, ...props }) => <h2 className="font-semibold mt-2 " style={{ color: "#457b9d" }}   {...props} />,
        h3: ({ node, ...props }) => <h3 className="font-semibold mt-2" style={{ color: "#457b9d" }}  {...props} />,
        code: ({ node, inline, className, children, ...props }) => (
            <code
                style={{
                    backgroundColor: inline ? "#f1f1f1" : "#282c34",
                    color: inline ? "#000" : "#61dafb",
                    padding: "4px 8px",
                    borderRadius: "5px",
                    display: inline ? "inline" : "block",
                }}
                {...props}
            >
                {children}
            </code>
        ),
        img: ({ node, ...props }) => (
            <img
                style={{
                    maxWidth: "100%",
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                }}
                {...props}
            />
        ),
        table: ({ node, ...props }) => (
            <table
                style={{
                    borderCollapse: "collapse",
                    width: "100%",
                }}
                {...props}
            />
        ),
        th: ({ node, ...props }) => (
            <th
                style={{
                    backgroundColor: "#457b9d",
                    color: "white",
                    padding: "10px",
                    border: "1px solid #ddd",
                }}
                {...props}
            />
        ),
        td: ({ node, ...props }) => (
            <td
                style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                }}
                {...props}
            />
        ),
      }

return (
    <Markdown className={"custom-markdown-webChat"} components={customComponents} remarkPlugins={[remarkGfm]}>
        {text}
    </Markdown>
  )
}

export default MessageParser