import { createContext, useEffect, useState } from "react";
import { AppLoader } from "components/LoaderSpinner";
import { subscriptionService } from "api";
import { useParams } from "react-router-dom";

export const OrganizationContext = createContext({});

export const OrganizationProvider = ({ children }) => {
    
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const {oragID} = useParams();

    const getOrgData = async  () => {
        try {
            const subscriptionResponse = await subscriptionService.getOrganizationSubscription(oragID);
            console.log('subscriptionResponse',subscriptionResponse.data);
            
            setSubscription(subscriptionResponse.data);
        } catch (error) {
            console.log('error getting organization subscription: ', error);
        } finally {
            setLoading(false)
        }

    }
    useEffect(() => {
        getOrgData()
    }, [])
    // Actions
    const actions = (action) => {
        const { type, payload } = action;

        switch (type) {
            case "SET_LOADING":
                return setLoading(payload);
            case "SET_SELECTED_ORGANIZATION":
                return setSelectedOrganization(payload);
            default:
                return loading;
        }
    };

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <OrganizationContext.Provider
        value={{
            actions,
            loading,
            selectedOrganization,
            subscription
        }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
