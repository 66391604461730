/* eslint-disable max-lines */
import { projectOHttpClient, createWebSocketClient } from "api/clients/project-o.httpClient";


class OpCodeWebsocketService {

    /**
     * Creates a new opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async createOpCode(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/opcodes/celery/create_opcode`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/celery/ws/create_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    async updateOpCode(data, opcode_id) {

        // Step 1: Get Task ID
        const response = await projectOHttpClient.put(`/opcodes/celery/modify_opcode/${opcode_id}`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/celery/ws/modify_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    /**
     * Executes an opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    // async executeOpCode(data) {
    //     // Step 1: Get Task ID
    //     const response = await projectOHttpClient.post(`/opcodes/execute_opcode`, data);
    
    //     if (!response.data?.task_id) {
    //         throw new Error("Failed to retrieve task ID.");
    //     }
    //     console.log(`Task ID: ${response.data?.task_id}`);
    
    //     // Step 2: Start WebSocket connection and handle updates
    //     return new Promise((resolve, reject) => {
    //         const socket = createWebSocketClient(`/opcodes/celery/ws/execute_opcode/${response.data?.task_id}`);
            
    //         socket.onopen = () => {
    //             console.log("Connected to WebSocket, receiving real-time updates...");
    //         };
            
    //         socket.onmessage = (event) => {
    //             const message = JSON.parse(event.data);
    
    //             if (message.status === "processing") {
    //                 console.log("Task is processing...");
    //             } else if (message.status === "completed") {
    //                 console.log("Task is completed");
    //                 socket.close(); // Close the socket when the task is done
    //                 resolve(message.result); // Resolve the promise with the final result
    //             } else if (message.status === "error") {
    //                 console.error(`Error: ${message.message}`);
    //                 socket.close();
    //                 reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
    //             }
    //         };
    
    //         socket.onerror = (error) => {
    //             console.error("WebSocket error:", error);
    //             socket.close();
    //             reject(new Error("WebSocket connection failed"));
    //         };
    
    //         socket.onclose = () => {
    //             console.log("WebSocket connection closed");
    //         };
    //     });
    // }
    

    // New OpCode 3 =========================

    /**
     * Creates a new opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async createOpCode3(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/opcodes/celery/create_opcode3`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/ws/execute_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.state === "PENDING") {
                    console.log("Task registered...");
                } else if (message.state === "RECEIVED") {
                    console.log("Task received by a worker...");
                } else if (message.state === "STARTED") {
                    console.log("Task execution started...");
                } else if (message.state === "SUCCESS") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.state === "FAILURE") {
                    console.error(`Error: ${typeof message.message === 'object' ? JSON.stringify(message.message) : message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    async updateOpCode3(data, opcode_id) {

        // Step 1: Get Task ID
        const response = await projectOHttpClient.put(`/opcodes/celery/modify_opcode3/${opcode_id}`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/celery/ws/modify_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    /**
     * Executes an opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
    async executeOpCode3(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/opcodes/execute_opcode3`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/celery/ws/execute_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.status === "processing") {
                    console.log("Task is processing...");
                } else if (message.status === "completed") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.status === "error") {
                    console.error(`Error: ${message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

    // New OpCode (mainClone) =========================

     /**
     * Executes an opcode (Workflow).
     * @param {Object} data - The experiment data to run.
     * @returns {Promise<Object>} The response containing experiment results.
     */
     async executeOpCode(data) {
        // Step 1: Get Task ID
        const response = await projectOHttpClient.post(`/opcodes/execute_opcode`, data);
    
        if (!response.data?.task_id) {
            throw new Error("Failed to retrieve task ID.");
        }
        console.log(`Task ID: ${response.data?.task_id}`);
    
        // Step 2: Start WebSocket connection and handle updates
        return new Promise((resolve, reject) => {
            const socket = createWebSocketClient(`/opcodes/ws/execute_opcode/${response.data?.task_id}`);
            
            socket.onopen = () => {
                console.log("Connected to WebSocket, receiving real-time updates...");
            };
            
            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
    
                if (message.state === "PENDING") {
                    console.log("Task registered...");
                } else if (message.state === "RECEIVED") {
                    console.log("Task received by a worker...");
                } else if (message.state === "STARTED") {
                    console.log("Task execution started...");
                } else if (message.state === "SUCCESS") {
                    console.log("Task is completed");
                    socket.close(); // Close the socket when the task is done
                    resolve(message.result); // Resolve the promise with the final result
                } else if (message.state === "FAILURE") {
                    console.error(`Error: ${typeof message.message === 'object' ? JSON.stringify(message.message) : message.message}`);
                    socket.close();
                    reject(new Error(`Error: ${message.message}`)); // Reject the promise on error
                }
            };
    
            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
                reject(new Error("WebSocket connection failed"));
            };
    
            socket.onclose = () => {
                console.log("WebSocket connection closed");
            };
        });
    }

}

const opcodeWSService = new OpCodeWebsocketService();
export { opcodeWSService };