import { Context } from 'context/GlobalState';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectUser = () => {
    const navigate = useNavigate();

    const { organizations } = useContext(Context)

    useEffect(() => {
        const storedOrganization = localStorage.getItem('selectedOrganization')
        const isOrgMember = organizations?.find(item => item._id === storedOrganization)
        if (isOrgMember) {
            const storedSubOrg = localStorage.getItem('selectedSubOrg')
            const isSubOrgMember = isOrgMember?.subOrganizations?.find(item => item._id === storedSubOrg)
            if (isSubOrgMember) {
                navigate(`/organizations/${storedOrganization}/sub/${storedSubOrg}/ai-lab`);
            } else {
                navigate(`/organizations/${storedOrganization}/sub`);
            }
        } else {
            navigate(`/organizations`);
        }
    }, [navigate]);

    return null; // or loading spinner if needed
};

export default RedirectUser;
