/* eslint-disable max-lines */
/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, useContext } from "react";
import {
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogContent,
  Dialog,
} from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";
import { Button } from "components/ui/button";
import { Context } from "context/GlobalState";
import { useParams } from "react-router-dom";
import { redisCollectionService } from "api";
import CollectionForm from "./CollectionForm";

const UpdateCollectionDialog = ({ selectedCard, actions, isMilvusActivated, show, setShow }) => {


    const [btnLoading, setBtnLoading] = useState(false);
    const [collectionForm, setCollectionForm] = useState({
        embeddingModel: 'jina',
        name: '',
        chunkingStrategy: 'by page',
        dimensions: 1024,
        vectorStoreType: 'MongoDB',
    });

    const { addNewNotifcation } = useContext(Context)
    const { oragID, subOragID } = useParams()

    useEffect(() => {
        setCollectionForm({
            embeddingModel: 'jina',
            name: '',
            chunkingStrategy: 'by page',
            dimensions: 1024,
            vectorStoreType: 'MongoDB',
            ...selectedCard
        })
    }, [show]);

    const handleUpdateCollection = () => {
        setBtnLoading(true);

        const updatedCollectionObj = {
            organizationId: oragID,
            subOrganizationId: subOragID,
            collectionId: selectedCard._id,
            dimensions: collectionForm.dimensions,
            name: collectionForm.name,
            chunkingStrategy: collectionForm.chunkingStrategy,
            vectorStoreType: collectionForm.vectorStoreType,
            embeddingModel: collectionForm.embeddingModel,
        }
        redisCollectionService.updateCollection(updatedCollectionObj)
        .then((res) => {
            setShow(false);
            actions({type: 'SET_COLLECTIONS', payload: (collections => {
                const updatedCollection = collections.map(item => {
                    if (item._id === selectedCard._id) {
                        return {...item, ...res.data}
                    } else {
                        return item
                    }
                })

                return updatedCollection
            })})

            addNewNotifcation("Sub-Organization updated successfully", "success");
        })
        .catch((error) => {
            console.log("error: ", error);
            if (error?.response?.status === 404) {
                addNewNotifcation("Collection not found", "danger");
            } else {
                addNewNotifcation("Something went wrong", "danger");
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    };

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="w-full max-w-lg p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4 overflow-y-scroll'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">
                            Manage Collection
                        </DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">
                            Manage collection configuration
                        </DialogDescription>
                    </DialogHeader>

                    <CollectionForm
                    initialData={selectedCard}
                    form={collectionForm} setForm={setCollectionForm}
                    isMilvusActivated={isMilvusActivated} />


                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button
                            variant="outline"
                            onClick={() => setShow(false)}
                            className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={btnLoading}
                            onClick={handleUpdateCollection}
                            className="px-5 py-3 text-md"
                        >
                            {btnLoading ? 'Updating Collection...' : "Update Collection"}
                        </Button>
                    </DialogFooter>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateCollectionDialog;
