import React, {useEffect, useState } from 'react'
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Slider } from "components/ui/slider";
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import watsonxModels from "../../json/watsonxModels";
import { Button } from 'components/ui/button';
import { Settings2 } from 'lucide-react';

const WatsonXConfiguration = ({ parametersWatsonX, setParametersWatsonX, selectedModel}) => {
    const [tokensLimit, setTokensLimit] = useState({min: 1, max: 2048});
    const [tempLimit] = useState({min: 0, max: 2});
    const [topKLimit] = useState({min: 1, max: 100});
    const [topPLimit] = useState({min: 0, max: 1});

    useEffect(() => {
        if (!selectedModel.trim()) return;
        
        const findModel = watsonxModels.find(model => model.value === selectedModel);
        
        if (!findModel) return;

        if (parametersWatsonX.max_new_tokens > findModel.max_tokens){
            setParametersWatsonX({...parametersWatsonX, max_new_tokens: findModel.max_tokens});
        }
        setTokensLimit({min: 1, max: findModel.max_tokens});
        

    }, [selectedModel]);
    
    const handleModeChange = (value) => {
        if (value === 'greedy') {
            setParametersWatsonX({...parametersWatsonX, temperature: 0, top_k: 50, top_p : 1, decoding_method: value})
        } else {
            // reset to default values if sampling
            setParametersWatsonX({...parametersWatsonX, temperature: parametersWatsonX.temperature, top_k: parametersWatsonX.top_k, top_p : parametersWatsonX.top_p, decoding_method: value})
        }
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <div className=''>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                        <Settings2 className="h-5 w-5" />
                    </Button>
                </div>
            </PopoverTrigger>

            <PopoverContent className="w-80 select-none" align="center">
                <div className="grid gap-4">
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none text-sm">Configure WatsonX Model</h4>
                        <p className="text-xs text-muted-foreground">
                            Set the parameters for selected WatsonX model.
                        </p>
                    </div>
                    <div className="grid gap-4">

                        <RadioGroup defaultValue="greedy" onValueChange={handleModeChange} value={parametersWatsonX?.decoding_method || ""} className="grid grid-cols-2 gap-4">
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="greedy" id="r1"/>
                                <Label htmlFor="r1">Greedy</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="sampling" id="r2"/>
                                <Label htmlFor="r2">Sampling</Label>
                            </div>
                        </RadioGroup>


                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="maxTokens" className="col-span-2">Max Tokens</Label>
                            <Input
                                id="maxTokens"
                                type="number"
                                value={parametersWatsonX.max_new_tokens}
                                step={1}
                                max={tokensLimit.max}
                                min={tokensLimit.min}
                                onChange={(e)=>{setParametersWatsonX({...parametersWatsonX, max_new_tokens: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                            />
                            <Slider
                                id="maxTokens"
                                value={[parametersWatsonX.max_new_tokens]}
                                onValueChange={(value)=>{setParametersWatsonX({...parametersWatsonX, max_new_tokens: Number(value)})}}
                                step={1}
                                max={tokensLimit.max}
                                min={tokensLimit.min}
                                className="col-span-3 h-8"
                            />
                        </div>

                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="temp" className={`col-span-2 ${parametersWatsonX.decoding_method === "greedy" ? "text-gray-400" : ""}`}>Temperature</Label>
                            <Input
                                id="temp"
                                type="number"
                                value={parametersWatsonX.temperature}
                                step={0.1}
                                max={tempLimit.max}
                                min={tempLimit.min}
                                onChange={(e)=>{setParametersWatsonX({...parametersWatsonX, temperature: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                                disabled={parametersWatsonX.decoding_method === "greedy"}
                            />
                            <Slider
                                id="temp"
                                value={[parametersWatsonX.temperature]}
                                onValueChange={(value)=>{setParametersWatsonX({...parametersWatsonX, temperature: Number(value)})}}
                                step={0.1}
                                max={tempLimit.max}
                                min={tempLimit.min}
                                className="col-span-3 h-8"
                                disabled={parametersWatsonX.decoding_method === "greedy"}
                            />
                        </div>

                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="topK" className={`col-span-2 ${parametersWatsonX.decoding_method === "greedy" ? "text-gray-400" : ""}`}>Top K</Label>
                            <Input
                                id="topK"
                                type="number"
                                value={parametersWatsonX.top_k}
                                step={1}
                                max={topKLimit.max}
                                min={topKLimit.min}
                                onChange={(e)=>{setParametersWatsonX({...parametersWatsonX, top_k: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                                disabled={parametersWatsonX.decoding_method === "greedy"}
                            />
                            <Slider
                                id="topK"
                                value={[parametersWatsonX.top_k]}
                                onValueChange={(value)=>{setParametersWatsonX({...parametersWatsonX, top_k: Number(value)})}}
                                step={1}
                                max={topKLimit.max}
                                min={topKLimit.min}
                                className="col-span-3 h-8"
                                disabled={parametersWatsonX.decoding_method === "greedy"}
                            />
                        </div>

                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="topP" className={`col-span-2 ${parametersWatsonX.decoding_method === "greedy" ? "text-gray-400" : ""}`}>Top P</Label>
                            <Input
                                id="topP"
                                type="number"
                                value={parametersWatsonX.top_p}
                                step={0.1}
                                max={topPLimit.max}
                                min={topPLimit.min}
                                onChange={(e)=>{setParametersWatsonX({...parametersWatsonX, top_p: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                                disabled={parametersWatsonX.decoding_method === "greedy"}
                            />
                            <Slider
                                id="topP"
                                value={[parametersWatsonX.top_p]}
                                onValueChange={(value)=>{setParametersWatsonX({...parametersWatsonX, top_p: Number(value)})}}
                                step={0.1}
                                max={topPLimit.max}
                                min={topPLimit.min}
                                className="col-span-3 h-8"
                                disabled={parametersWatsonX.decoding_method === "greedy"}
                            />
                        </div>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default WatsonXConfiguration