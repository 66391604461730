/* eslint-disable max-lines */
import React from 'react'

import { ScrollArea } from 'components/ui/scroll-area';
import WatsonModelSelectionModal from '../Common/WatsonModelSelectionModal';
import WatsonXConfiguration from '../Common/WatsonXConfiguration';
import GroqModelSelectionModal from '../Common/GroqModelSelectionModal';
import GroqConfiguration from '../Common/GroqConfiguration';
import Anatomy from '../Common/Anatomy';
import { Label } from 'components/ui/label';
import InputValidation from 'components/InputValidation';
import labHelper from 'pages/PromptingAndRagTraining/classes/AILabHelper';
import { Textarea } from 'components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';

export default function InstructionForm({ form, setForm, update=false }) {

    const modelTypes = [{name: 'Watson', value: 'IBM'}, {name: 'Groq', value: 'Groq'}]

    const handleParameter = (data) => {
        if (form.model_type === 'IBM') {
            setForm(prev => ({...prev, watsonParameters: data}))
        } else if (form.model_type === 'Groq')  {
            setForm(prev => ({...prev, groqParameters: data}))
        }
    }

    const selectedModel = (data) => {
        setForm(prev => ({...prev, selectedModel: data}))
    }

    const handleSelectedModelType = (value) => {
        setForm(prev => ({...prev, model_type: value, selectedModel: ''}))
    }

    return (
        <div>
            <div className='flex w-full my-6'>
                <div className='space-y-2 w-full'>
                    <Label htmlFor="model-type">Model type</Label>
                    <Select value={form.model_type} onValueChange={handleSelectedModelType} id="model-type" className="w-full">
                        <SelectTrigger>
                            <SelectValue placeholder="Select a Model type" />
                        </SelectTrigger>
                        <SelectContent>
                            {modelTypes.map((item) => (
                                <SelectItem key={item.value} value={item.value}>{item.name}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                </div>
            </div>
            {form.model_type === 'IBM' ? (
                <div className='flex gap-2'>
                    <div className="w-full">
                        <WatsonModelSelectionModal 
                        finalModel={form.selectedModel}
                        onModelSelect={selectedModel}
                        />
                    </div>
                    <WatsonXConfiguration
                    parametersWatsonX={form.watsonParameters} 
                    setParametersWatsonX={handleParameter} 
                    selectedModel={form.selectedModel}
                    />
                </div>
            ) : form.model_type === 'Groq' ? (
                <div className='flex gap-2'>
                    <div className="w-full">
                        <GroqModelSelectionModal 
                        finalModel={form.selectedModel}
                        onModelSelect={selectedModel}
                        />
                    </div>
                
                    <GroqConfiguration
                    parametersGroq={form.groqParameters} 
                    setParametersGroq={handleParameter} 
                    />
                </div>
            
            ) : ''}

            <ScrollArea className="max-h-[360px]">
                <div className="space-y-2 px-1 pt-4">
                    <div>
                        <Label htmlFor="name" className="text-right">
                            Unique Name
                        </Label>
                        <InputValidation
                            id="name"
                            value={form.unique_name}
                            disabled={update}
                            onChange={(e) => setForm(prev => ({...prev, unique_name: e.target.value}))}
                            className="col-span-3"
                            placeholder="Enter a unique name for your instruction"
                            validation={labHelper.validateUniqueName}
                        />  
                    </div>
                    <div>
                        <Label htmlFor="description" className="text-right">
                            Description
                        </Label>
                        <Textarea
                            id="description"
                            value={form.description}
                            onChange={(e) => setForm(prev => ({...prev, description: e.target.value}))}
                            className="col-span-3"
                            placeholder="Enter description for your instruction"
                        />
                    </div>
                </div>
                <Anatomy instructionForm={form} setInstructionForm={setForm} isExecute={false} />
            </ScrollArea>
        </div>
    )
}