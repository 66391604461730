import { useState } from 'react'
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'

export function ActivityFilters({ onFilterChange }) {
    const [userEmail, setUserEmail] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState('all')

    const handleSubmit = (e) => {
        e.preventDefault()
        onFilterChange({ userEmail, startDate, endDate, status })
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4 mb-6">
            <div>
                <Label htmlFor="userEmail">User Email</Label>
                <Input
                id="userEmail"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Enter User Email"
                />
            </div>
            <div>
                <Label htmlFor="status">Status</Label>
                <Select onValueChange={(value) => setStatus(value)} value={status} id="status" className="w-full">
                    <SelectTrigger>
                        <SelectValue placeholder="Select a status code" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value={'all'}> All </SelectItem>
                        <SelectItem value={'200'}> 200 </SelectItem>
                        <SelectItem value={'400'}> 400 </SelectItem>
                        <SelectItem value={'500'}> 500 </SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="startDate">Start Date</Label>
                    <Input
                        id="startDate"
                        type="datetime-local"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div>
                    <Label htmlFor="endDate">End Date</Label>
                    <Input
                        id="endDate"
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
            </div>
            <Button type="submit">Apply Filters</Button>
        </form>
    )
}

