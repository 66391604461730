import React, { useState } from 'react'
import { Popover, PopoverTrigger, PopoverContent } from "components/ui/popover";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Slider } from "components/ui/slider";
import { Button } from 'components/ui/button';
import { Settings2 } from 'lucide-react';

const GroqConfiguration = ({ parametersGroq, setParametersGroq}) => {
    const [tempLimit] = useState({min: 0, max: 2});
    const [topPLimit] = useState({min: 0, max: 1});
    const [frequencyPenaltyLimit] = useState({min: -2.0, max: 2.0});
    const [presencePenaltyLimit] = useState({min: -2.0, max: 2.0});

    return (
        <Popover>
            <PopoverTrigger asChild>
                <div className=''>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                        <Settings2 className="h-5 w-5" />
                    </Button>
                </div>
            </PopoverTrigger>

            <PopoverContent className="w-80 select-none" align="center">
                <div className="grid gap-4">
                    <div className="space-y-2">
                        <h4 className="font-medium leading-none text-sm">Configure Groq Model</h4>
                        <p className="text-xs text-muted-foreground">
                            Set the parameters for selected Groq model.
                        </p>
                    </div>
                    <div className="grid gap-4">
                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="maxTokens" className="col-span-2">Max Tokens</Label>
                            <Input
                                id="maxTokens"
                                type="number"
                                value={parametersGroq.max_tokens}
                                step={1}
                                onChange={(e)=>{setParametersGroq({...parametersGroq, max_tokens: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                            />
                        </div>

                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="temp" className="col-span-2">Temperature</Label>
                            <Input
                                id="temp"
                                type="number"
                                value={parametersGroq.temperature}
                                step={0.1}
                                max={tempLimit.max}
                                min={tempLimit.min}
                                onChange={(e)=>{setParametersGroq({...parametersGroq, temperature: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                            />
                            <Slider
                                id="temp"
                                value={[parametersGroq.temperature]}
                                onValueChange={(value)=>{setParametersGroq({...parametersGroq, temperature: Number(value)})}}
                                step={0.1}
                                max={tempLimit.max}
                                min={tempLimit.min}
                                className="col-span-3 h-8"
                            />
                        </div>

                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="topP" className="col-span-2">Top P</Label>
                            <Input
                                id="topP"
                                type="number"
                                value={parametersGroq.top_p}
                                step={0.1}
                                max={topPLimit.max}
                                min={topPLimit.min}
                                onChange={(e)=>{setParametersGroq({...parametersGroq, top_p: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                            />
                            <Slider
                                id="topP"
                                value={[parametersGroq.top_p]}
                                onValueChange={(value)=>{setParametersGroq({...parametersGroq, top_p: Number(value)})}}
                                step={0.1}
                                max={topPLimit.max}
                                min={topPLimit.min}
                                className="col-span-3 h-8"
                            />
                        </div>
                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="frequencyPenalty" className="col-span-2">Frequency Penalty</Label>
                            <Input
                                id="frequencyPenalty"
                                type="number"
                                value={parametersGroq.frequency_penalty}
                                step={0.1}
                                max={frequencyPenaltyLimit.max}
                                min={frequencyPenaltyLimit.min}
                                onChange={(e)=>{setParametersGroq({...parametersGroq, frequency_penalty: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                            />
                            <Slider
                                id="frequencyPenalty"
                                value={[parametersGroq.frequency_penalty]}
                                onValueChange={(value)=>{setParametersGroq({...parametersGroq, frequency_penalty: Number(value)})}}
                                step={0.1}
                                max={frequencyPenaltyLimit.max}
                                min={frequencyPenaltyLimit.min}
                                className="col-span-3 h-8"
                            />
                        </div>
                        <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                            <Label htmlFor="presencePenalty" className="col-span-2">Presence Penalty</Label>
                            <Input
                                id="presencePenalty"
                                type="number"
                                value={parametersGroq.presence_penalty}
                                step={0.1}
                                max={presencePenaltyLimit.max}
                                min={presencePenaltyLimit.min}
                                onChange={(e)=>{setParametersGroq({...parametersGroq, presence_penalty: Number(e.target.value)})}}
                                className="col-span-1 h-8"
                            />
                            <Slider
                                id="presencePenalty"
                                value={[parametersGroq.presence_penalty]}
                                onValueChange={(value)=>{setParametersGroq({...parametersGroq, presence_penalty: Number(value)})}}
                                step={0.1}
                                max={presencePenaltyLimit.max}
                                min={presencePenaltyLimit.min}
                                className="col-span-3 h-8"
                            />
                        </div>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}

export default GroqConfiguration