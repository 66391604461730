import React, { useContext, useState } from 'react'
import { Context } from '../../../context/GlobalState'
import EditUserForm from './EditUserForm'
import ResetUserPasswordForm from './ResetUserPasswordForm'
import './profile-styles.css'
import { httpRequest } from '../../../utils/httpsRequest'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { Button } from 'components/ui/button'


export default function Profile() {

    const [openModal, setOpenModal] = useState(false);
    const [openPassModal, setOpenPassModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const { user, addNewNotifcation, actions } = useContext(Context);
    const [userInput, setUserInput] = useState({ name: user.name });
    const [userPassInput, setUserPassInput] = useState({ oldPass: '', newPass: '', confirmNewPass: '' });
    

    const handleCreate = () => {
        console.log('hi');
        const nameObj = {
            userId: user.id,
            name: userInput.name
        }
        setBtnLoading(true);

        httpRequest.post('/db/users/update-name', nameObj)
            .then((res) => {
                console.log('Response', res);
                console.log('Response', res.data.user.name);
                actions({ type: 'SET_USER', payload: { ...user, name: userInput.name } })
                addNewNotifcation('User Name updated successfully.', 'success');
                setOpenModal(false);
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 404)
                    addNewNotifcation('User Name could not be updated. User is not registered. Please check all your entries.', 'warning')
                else
                    addNewNotifcation('User name could not be updated. Please check all your entries.', "danger")
            })
            .finally(() => setBtnLoading(false));

    }
    const handleResetPass = () => {
        if (userPassInput.newPass !== userPassInput.confirmNewPass) {
            addNewNotifcation('Password could not be updated. Password does not match. Please check all your entries.', 'warning');
        }
        else {
            const passObj = {
                userId: user.id,
                oldPassword: userPassInput.oldPass,
                newPassword: userPassInput.newPass
            }
            console.log(passObj);
            setBtnLoading(true);

            httpRequest.post('/db/users/update-password', passObj)
            .then((res) => {
                console.log('Response', res);
                addNewNotifcation('Password has been updated successfully.', 'success');
                setOpenModal(false);
                setUserPassInput({ oldPass: '', newPass: '', confirmNewPass: '' })
                setOpenPassModal(false);
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 404)
                    addNewNotifcation('Password could not be updated. User is not registered. Please check all your entries.', 'warning')
                else if (error.response?.status === 400)
                    addNewNotifcation('Password could not be updated. Old password is not correct. Please enter the correct password.', 'danger')
                else
                    addNewNotifcation('Password could not be updated. Please check all your entries.', 'danger')
            })
            .finally(() => setBtnLoading(false));
        }

    };
    

    return (
        <div className='profile-settings-container'>
            {/* <h3 className='profile-label'>Profile Photo</h3> */}
            {/* <img className='user-img' src='https://cdn-icons-png.flaticon.com/512/10307/10307852.png' alt='' /> */}
            {/* <hr className='hr-line' /> */}
            <div className='profile-container'>
                <div >
                    <h3 className='profile-label'>Name</h3>
                    <h3>{user.name}</h3>
                </div>
                <div>
                    <button onClick={() => setOpenModal(true)} className='setting-edit-btn' >Edit</button>
                    <Dialog onOpenChange={setOpenModal} open={openModal} text={'Edit User Name'}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle> Edit User Name </DialogTitle>
                            </DialogHeader>

                            <div>
                                <EditUserForm userInput={userInput} setUserInput={setUserInput} />
                            </div>

                            <DialogFooter className='flex w-full justify-end'>
                                <Button onClick={() => setOpenModal(false)}> Cancel </Button>
                                <Button> Update </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
            <hr className='hr-line' />
            <div className='profile-container'>
                <div >
                    <h3 className='profile-label'>Email</h3>
                    <h3>{user.email}</h3>
                </div>
            </div>
            <hr className='hr-line' />
            <div className='profile-container'>
                <div >
                    <h3 className='profile-label'>Update Password</h3>
                </div>
                <div>
                    <button className='setting-edit-btn'  onClick={() => setOpenPassModal(true)}>Edit</button>
                    <Dialog onOpenChange={setOpenPassModal} open={openPassModal} text={'Edit User Name'}>
                        <DialogContent>

                            <DialogHeader>
                                <DialogTitle> Reset Password </DialogTitle>
                            </DialogHeader>

                            <div>
                                <ResetUserPasswordForm userPassInput={userPassInput} setUserPassInput={setUserPassInput} />
                            </div>

                            <DialogFooter>
                                <Button onClick={() => setOpenPassModal(false)}> Cancel </Button>
                                <Button> Update </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}