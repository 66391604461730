import React, { useState, useEffect, useContext,useRef } from "react";
import "./HrDemo.css";
import { Context } from "../../../context/GlobalState";
import { Button } from "../../../components/ui/button";
import { Card, CardDescription, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { Label } from "@radix-ui/react-dropdown-menu";
import * as ShadeDD from "../../../components/ui/dropdown-menu";
import { Input } from "../../../components/ui/input";
import MatchResult from "./HrPrompt";
import { useParams } from "react-router-dom";
import { ragService } from "api/services/PROJECT-O";
import { RotateCcw, Info1 } from 'lucide-react';
import { NestedPageLayout } from "components/NewDesignComponents/PageLayout";

export default function DemoModeHR() {
  const [docList, setDocList] = useState([]);
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [selectedJD, setSelectedJD] = useState([]); 
  const [selectedCV, setselectedCV] = useState([]);
  const [folders, setFolders] = useState([]);
  const { user, addNewNotifcation } = useContext(Context);
  const FileInputRef = useRef(null);
  const  [uploadedDocsJd, setUploadedDocsJd] = useState([]);
  const [uploadedDocsCv, setUploadedDocsCv] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [isLoadingJd, setIsLoadingJd] = useState(false);
  const [isLoadingCv, setIsLoadingCv] = useState(false);
  const [fileArrStatusJD, setFileArrStatusJD] = useState([]);
  const [fileArrJD, setFileArrJD] = useState([]);
  const [fileArrStatusCV, setFileArrStatusCV] = useState([]);
  const [fileArrCV, setFileArrCV] = useState([]);
  const [fileArrStatus, setFileArrStatus] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const [mode, setMode] = useState("");
  const  [uploadedJD, setUploadedJD] = useState([]);
  const [uploadedCV, setUploadedCV] = useState([]);
  const {subOragID, oragID} = useParams();
  const [deletingDocs, setDeletingDocs] = useState({});
  const formRefCV = useRef(null);
  const formRefJD = useRef(null);


  useEffect(() => {
    const handleGetDocuments = async () => {
      try {
          setIsLoading(true);
          console.log(oragID, process.env.REACT_APP_DEMO_MODE);
          const preDefinedPromise = ragService.listDocuments(process.env.REACT_APP_COMMON_ORGANIZATION_ID, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, {"assistant": "hr-assistant"})
          const userDefinedPromise = ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {uploader: user.email})
          
          const [preDefinedDocs, userDefinedDocs] = await Promise.all([preDefinedPromise, userDefinedPromise]);
        //   join user uploader with predefined
          const allDocs = [...preDefinedDocs.data, ...userDefinedDocs.data]
          const preDefinedDocs2 = preDefinedDocs.data;
          const userDefinedDocs2 = userDefinedDocs.data;

          console.log(allDocs);
          setDocList(allDocs.map((item) => {
            return {
              name: item.file_name,
              value: { item },
              documentRecord: item,
            }
          }));
          console.log(allDocs)
          const jobDescDocs = preDefinedDocs2.filter(doc => doc.folder === "job_descriptions");
        setJobDescriptions(jobDescDocs.map((item) => {
          return {
            name: item.file_name,
            value: { item },
            documentRecord: item,
          }
        }));
  
        const uniqueFolders = preDefinedDocs2
          .filter(doc => doc.folder)
          .map(doc => doc.folder);
          console.log(uniqueFolders);

        const labeledFolders = uniqueFolders
          .filter((folder, index, self) => self.indexOf(folder) === index) 
          .map(folder => {
            switch (folder) {
              case 'CV_FullStackDeveloper':
                return { folder, label: 'All FullStack Developer CVs' };
              case 'CV_DataScientist':
                return { folder, label: 'All Data Scientist CVs' };
                case 'CV_MarketingManager':
                  return { folder, label: 'All Marketing Manager CVs' };
                  case 'CV_SoftwareEng':
                    return { folder, label: 'All Software Engineer CVs' };
                
              default:
                return null;
            }
          })
          .filter(folder => folder !== null);

        setFolders(labeledFolders);

      } catch (err) {
          console.log('err: ', err);
      } finally {
          setIsLoading(false);
      }
  };
   
    handleGetDocuments();
  }, []);

  const handleSelectDocument = (doc) => {
    console.log(doc)
   
    if (selectedJD.some(item => item.documentRecord._id === doc.documentRecord._id)) {
      // If the document is already selected, deselect it
      setSelectedJD([]);
    } else {
      // If another document is selected, replace it with the new one
      setSelectedJD([
        {
          name: doc.documentRecord?.file_name,
          documentRecord: doc.documentRecord,
        },
      ]);
    }
    
  };

  const handleSelectCV = (doc) => {
    const isCVSelected = selectedCV.some(
      (selected) => selected.documentRecord._id === doc.documentRecord._id
    );

    if (isCVSelected) {
      setselectedCV((prev) =>
        prev.filter(
          (selectedDoc) =>
            selectedDoc.documentRecord._id !== doc.documentRecord._id
        )
      );
    } else {
      setselectedCV((prev) => [
        ...prev,
        {
          name: doc.documentRecord?.file_name,
          value: { doc },
      
        },
      ]);
    }
  };


  const handleSelectedJD = (doc) => {
    return selectedJD.some(
      (item) => item._id === doc.documentRecord._id
    );
  };

  const handleSelectedCV = (doc) => {
    return selectedCV.some(
      (document) => document.documentRecord._id === doc.documentRecord._id
    );
  };



  const listSelectedJD = () => {
    return selectedJD.length >0 ? (
        <div className="max-h-40 overflow-y-auto">
            {selectedJD.map((jd) => (
                <Card className="mt-2" key={jd.documentRecord._id}>
                    <CardHeader>
                        <CardTitle>{jd.documentRecord.file_name}</CardTitle>
                    </CardHeader>
                </Card>
        ))}
        </div>
    ) : null;
  };

  const listSelectedCV = () => {
    return (
        <div className="max-h-40 overflow-y-auto">
            {selectedCV.map((jd) => (
                <Card className="mt-2" key={jd.documentRecord._id}>
                    <CardHeader>
                        <CardTitle>{jd.documentRecord.file_name}</CardTitle>
                    </CardHeader>
                </Card>
        ))}
        </div>
    );
  };

  const handleSelectFolder = (folder) => {
    const docsInFolder = docList.filter(
      (doc) => doc.documentRecord.folder === folder
    );
    setselectedCV(docsInFolder);
  };

  useEffect(() => {
    if (selectedJD.length > 0) {
      console.log("Selected JD", selectedJD);
    }
  }, [selectedJD]);

  useEffect(() => {
    console.log(folders);
  }, [folders]);
  useEffect(() => {
    if (selectedCV.length > 0) {
      console.log("Selected CVS", selectedCV);
    }
  }, [selectedCV]);
  //Upload JD files

const handleJDProcessFiles = async () => {
  setIsLoadingJd(true);

  try {
      for await (const fileObj of fileArrJD) {
          const formData = new FormData();
          const file = fileObj.file;

          formData.append('organization_id', oragID);
          formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
          formData.append('files', file);
          formData.append('metadata', JSON.stringify({"assistant": "hr-assistant",folder:"JD_upload", uploader: user.email, filename: file.name }));
          formData.append('chunk_size', 700);
          formData.append('chunk_overlap', 70);
          formData.append('bucket_name', `${oragID}-bex-demo-mode`);
          

          const res = await ragService.processFile(formData)

          console.log('API Response:', res.data);
        
          const { document_ids } = res.data;
          const _id = document_ids[0];

          // Update file status immediately
          setFileArrJD(prevFiles =>
              prevFiles.map(file => {
                  if (file.file.name === fileObj.file.name) {
                      return { ...file, _id, status: "Processed" };
                  }
                  return file;
              })
          );

          setUploadedDocsJd(prevDocs => [
              ...prevDocs,
              { documentRecord: { ...fileObj, _id, status: "Processed", file_name: fileObj.file.name } }
          ]);

          addNewNotifcation('Document added successfully', 'success');
          console.log("THIS IS ID", _id);
      }
      formRefJD.current.reset();
  } catch (error) {
      console.error('Error uploading document:', error);
      addNewNotifcation('Document upload failed. Please try again.', 'danger');
  } finally {
      setIsLoadingJd(false);
  }
};


const handleJDListUploadedFiles = async () => {
  try {
      const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {"assistant": "hr-assistant",folder:"JD_upload", uploader: user.email})
      setUploadedDocsJd(res.data.map(doc=>({documentRecord:doc,name:doc.file_name,value:{documentRecord:doc}})))
   console.log(res)
  } catch (error) {
    console.error('Error showing document:', error);
  } finally {
    setIsLoadingJd(false);
  }
}

const handleJDSetUploadFiles = (e) => {
  console.log(e.target.files);
  // if (uploadedDocsJd.length ===1){
  //   e.preventDefault();
  //     addNewNotifcation("Cannot upload more than 1 files", "danger");
  //     e.target.value = null;
  //     return
  // }
  if (Array.from(e.target.files).length <= 1) {
      setMode("upload");
      const filesWithMetadata = Array.from(e.target.files).map(file => ({
          file,
          status: "Ready to upload",
          progress: 0,
          label: file.name,
      }));

      setFileArrJD(filesWithMetadata);
      setFileArrStatusJD(filesWithMetadata.map(file => ({
          progress: 0,
          status: "Ready to upload",
          label: file.label,
          resps: [],
      })));
  } else {
      e.preventDefault();
      addNewNotifcation("Cannot upload more than 1 files", "danger");
      e.target.value = null;
  }
};

useEffect(() => {
handleJDListUploadedFiles()
}, []);


const handleJDDelete = (_id) => {
  setDeletingDocs((prev) => ({ ...prev, [_id]: true }));
  ragService.deleteDocument(oragID, process.env.REACT_APP_DEMO_MODE, _id, `${oragID}-bex-demo-mode`)
    .then((res) => {
      setUploadedDocsJd(prev => prev.filter((obj) => obj.documentRecord._id !== _id));
      addNewNotifcation('Document deleted successfully', 'success');
      console.log('Document deleted successfully:', res);
    }).catch((err) => {
      console.error('Error deleting document:', err);
      console.log('ID num', _id)
    }).finally(() => {
      setDeletingDocs((prev) => ({ ...prev, [_id]: false }));
    });
}

// Upload CV files 

const handleCVProcessFiles = async () => {
  setIsLoadingCv(true);

  try {
      for await (const fileObj of fileArrCV) {
          const formData = new FormData();
          const file = fileObj.file;

          formData.append('organization_id', oragID);
          formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
          formData.append('files', file);
          formData.append('metadata', JSON.stringify({ "assistant": "hr-assistant",folder:"cv_upload", uploader: user.email, filename: file.name }));
          formData.append('chunk_size', 700);
          formData.append('chunk_overlap', 70);
          formData.append('bucket_name', `${oragID}-bex-demo-mode`);

          const res = await ragService.processFile(formData)

          console.log('API Response:', res.data);

          const { document_ids } = res.data;
          const _id = document_ids[0];

          // Update file status immediately
          setFileArrCV(prevFiles =>
              prevFiles.map(file => {
                  if (file.file.name === fileObj.file.name) {
                      return { ...file, _id, status: "Processed" };
                  }
                  return file;
              })
          );

          setUploadedDocsCv(prevDocs => [
              ...prevDocs,
              { documentRecord: { ...fileObj, _id, status: "Processed", file_name: fileObj.file.name } }
          ]);

          addNewNotifcation('Document added successfully', 'success');
          console.log("THIS IS ID", _id);
      }
      // FileInputRef.current.value;
      formRefCV.current.reset();

  } catch (error) {
      console.error('Error uploading document:', error);
      addNewNotifcation('Document upload failed. Please try again.', 'danger');
  } finally {
      setIsLoadingCv(false);
  }
};



//list of CV  documents 
const handleCVListUploadedFiles = async () => {
  try {
      const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {"assistant": "hr-assistant",folder: "cv_upload", uploader: user.email})
      setUploadedDocsCv(res.data.map(doc=>({documentRecord:doc,name:doc.file_name,value:{documentRecord:doc}})))
   
  } catch (error) {
    console.error('Error showing document:', error);
  } finally {
    setIsLoadingCv(false);
  }
}

const handleCVSetUploadFiles = (e) => {
  if (Array.from(e.target.files).length <= 10) {
      setMode("upload");
      const filesWithMetadata = Array.from(e.target.files).map(file => ({
          file,
          status: "Ready to upload",
          progress: 0,
          label: file.name,
      }));

      setFileArrCV(filesWithMetadata);
      setFileArrStatusCV(filesWithMetadata.map(file => ({
          progress: 0,
          status: "Ready to upload",
          label: file.label,
          resps: [],
      })));
  } else {
      e.preventDefault();
      addNewNotifcation("Cannot upload more than 10 files", "danger");
      // e.target.value = null;
  }

};

useEffect(() => {
handleCVListUploadedFiles()
}, []);

const handleCVDelete = (_id) => {
  setDeletingDocs((prev) => ({ ...prev, [_id]: true }));
  ragService.deleteDocument(oragID, process.env.REACT_APP_DEMO_MODE, _id, `${oragID}-bex-demo-mode`)
    .then((res) => {
      setUploadedDocsCv(prev => prev.filter((obj) => obj.documentRecord._id !== _id));
      addNewNotifcation('Document deleted successfully', 'success');
      console.log('Document deleted successfully:', res);
    }).catch((err) => {
      console.error('Error deleting document:', err);
      console.log('ID num', _id)
    }).finally(() => {
      setDeletingDocs((prev) => ({ ...prev, [_id]: false }));
    });
}
useEffect(() => {
  if (uploadedJD.length > 0) {
    console.log("Uploded JD", uploadedJD);
  }
}, [uploadedJD]);

useEffect(() => {
  console.log(folders);
}, [folders]);
useEffect(() => {
  if (uploadedCV.length > 0) {
    console.log("uploaded CVS", uploadedCV);
  }
}, [uploadedCV]);

// Define state variables
const [UploadSelectedCV, setUploadSelectedCV] = useState([]);


// Function to handle checkbox selection
const handleCVCheckboxChange = (doc) => {
  setUploadSelectedCV((prevSelected) => {
    // Check if the document is already selected
    const isSelected = prevSelected.some(
      (item) => item.documentRecord?._id === doc.documentRecord?._id
    );

    // If selected, remove it from the selected list
    if (isSelected) {
      return prevSelected.filter(
        (item) => item.documentRecord?._id !== doc.documentRecord?._id
      );
    }

    // If not selected, add it to the selected list
    return [...prevSelected, doc];
  });
};


const loadedCV = selectedCV.length > 0 ? selectedCV : UploadSelectedCV;
const loadedJD = selectedJD.length > 0 ? selectedJD : uploadedDocsJd;


  return (
      <NestedPageLayout>
          <h2 className="text-2xl font-bold">Job Matching</h2>
          <p className="text-muted-foreground mb-3">Experience the future of recruitment with our AI-powered job matching and 
              candidate interview function. Enhance your HR capabilities, save time, and ensure you hire the best talent.</p>
          <section className="grid md:grid-cols-2 gap-8">
              <Card>
                  <CardHeader>
                      <CardTitle>Upload Job Descriptions</CardTitle>
                      <CardDescription>Add your job descriptions to find the best matching candidates.</CardDescription>
                  </CardHeader>
                  <CardContent>
                      <form className="space-y-4" ref={formRefJD}>
                          <div className="grid gap-2">
                              <Label htmlFor="folder">Saved Files</Label>
                              <label>
                                  <div className="flex items-center space-x-2">
                                      <ShadeDD.DropdownMenu>
                                          <ShadeDD.DropdownMenuTrigger asChild>
                                              <Button className="w-fit block mt-1 w-[420px]">Select Job Description</Button>
                                          </ShadeDD.DropdownMenuTrigger>
                                          <ShadeDD.DropdownMenuContent>
                                              <ShadeDD.DropdownMenuLabel>Documents</ShadeDD.DropdownMenuLabel>
                                              <ShadeDD.DropdownMenuSeparator />
                                              {jobDescriptions.map((doc) => (
                                                  <ShadeDD.DropdownMenuCheckboxItem
                                                      key={doc.documentRecord._id}
                                                      checked={handleSelectedJD(doc)}
                                                      onSelect={() => handleSelectDocument(doc)}
                                                  >
                                                      {doc.documentRecord.file_name}
                                                  </ShadeDD.DropdownMenuCheckboxItem>
                                              ))}
                                          </ShadeDD.DropdownMenuContent>
                                      </ShadeDD.DropdownMenu>
                                      <Button type="button" variant="secondary" className="w-fit mt-1" onClick={() => { setSelectedJD([]); }}>
                                          <RotateCcw className="h-5 w-5" />
                                      </Button>
                                  </div>
                              </label>

                              {listSelectedJD()}
                              <span className="text-sm block mt-2 ">Or</span>
                          </div>
                          <div className="grid gap-2">
                              <Label htmlFor="job-file">Upload Job Description</Label>
                              <Input
                                type="file"
                                onChange={handleJDSetUploadFiles}
                                multiple
                                ref={FileInputRef}
                                accept=".pdf,.doc,.docx,.pptx,.xlsx"
                                className="file-input-JD"
                              />
                              <p className="text-sm text-muted-foreground">The maximum number of files that can be uploaded per user at a time is 1.</p>
                          </div>
                          <Button
                          type='button'
                          onClick={handleJDProcessFiles} 
                          className="black-500 text-white"
                          disabled={fileArrJD.length === 0 || isLoadingJd || uploadedDocsJd.length >0}
                          >
                              {isLoadingJd ? "Processing..." : "Process files"}
                          </Button>

                          <div>
                              <h3>List of Uploaded JDs</h3>
                              <div className="overflow-y-auto max-h-72 mt-2">
                                  <div className="flex flex-col gap-2">
                                      {uploadedDocsJd.map((doc) => (
                                          <div
                                              className="flex items-center justify-between p-2 border-b"
                                              key={doc.documentRecord._id}
                                          >
                                              <div>
                                                  <h5 className="text-sm font-medium">
                                                      {doc.documentRecord.file_name}
                                                  </h5>
                                              </div>                                            
                                              <Button
                                                      type="button"
                                                      onClick={() => handleJDDelete(doc.documentRecord._id)}
                                                      className={`bg-gray-500 text-white flex items-center ${
                                                          deletingDocs[doc.documentRecord._id] ? "opacity-50 cursor-not-allowed" : ""
                                                      }`}
                                                      disabled={deletingDocs[doc.documentRecord._id]} 
                                                  >
                                                  {deletingDocs[doc.documentRecord._id] ? (
                                                      <>
                                                          <svg
                                                              className="animate-spin h-5 w-5 mr-2"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              fill="none"
                                                              viewBox="0 0 24 24"
                                                          >
                                                              <circle
                                                                  className="opacity-25"
                                                                  cx="12"
                                                                  cy="12"
                                                                  r="10"
                                                                  stroke="currentColor"
                                                                  strokeWidth="4"
                                                              ></circle>
                                                              <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8v8H4z"
                                                                ></path>
                                                          </svg>
                                                          Deleting...
                                                      </>
                                                          ) : (
                                                              "Delete"
                                                          )}
                                              </Button>
                                          </div>
                          ))}
                                  </div>
                              </div>
                          </div>
                      </form>
                  </CardContent>
              </Card>
              <Card>
                  <CardHeader>
                      <CardTitle>Upload Candidate CVs</CardTitle>
                      <CardDescription>Add your candidate CVs to find the best matching jobs.</CardDescription>
                  </CardHeader>
                  <CardContent>
                      <form className="space-y-4" ref={formRefCV}>
                          <div className="grid gap-2">
                              <Label htmlFor="folder">Folder</Label>
                              <label>
                                  <div className="flex items-center space-x-2">
                                      <ShadeDD.DropdownMenu>
                                          <ShadeDD.DropdownMenuTrigger asChild>
                                              <Button className="w-fit block mt-1 w-[420px]">Select CV Folder</Button>
                                          </ShadeDD.DropdownMenuTrigger>
                                          <Button type="button" variant="secondary" className="w-fit block mt-1" onClick={()=>{setselectedCV([]);}}> <RotateCcw className="h-5 w-5" /></Button>
                                          <ShadeDD.DropdownMenuContent>
                                              <ShadeDD.DropdownMenuLabel>Folders</ShadeDD.DropdownMenuLabel>
                                              <ShadeDD.DropdownMenuSeparator />
                                              {folders.map(({ folder, label }) => (
                                                  <ShadeDD.DropdownMenuItem
                                                    key={folder}
                                                    onSelect={() => handleSelectFolder(folder)}
                                                  >
                                                      {label}
                                                  </ShadeDD.DropdownMenuItem>
                                              ))}
                                          </ShadeDD.DropdownMenuContent>
                                      </ShadeDD.DropdownMenu>
                                  </div>
                              </label>
                              {listSelectedCV()}
                          </div><span className="text-sm block">Or</span>
                          <div className="grid gap-2">
                              <Label htmlFor="candidate-file">Upload CV</Label>
                              <Input
                      type="file"
                      onChange={handleCVSetUploadFiles}
                      multiple
                      ref={FileInputRef}
                      accept=".pdf,.doc,.docx,.pptx,.xlsx"
                      className="file-input-CV"
                  />
                              <p>The maximum number of files that can be uploaded per user at a time is 10.</p>
                          
                          </div>
                          <Button
                          type='button'
                      onClick={handleCVProcessFiles}
                      className="black-500 text-white"
                      disabled={fileArrCV.length === 0 || isLoadingCv}
                  >
                              {isLoadingCv ? "Processing..." : "Process files"}
                          </Button>
                                           
                        
                          <div>
                              <h3>List of Uploaded CVs</h3>
                              <div className="overflow-y-auto max-h-72 mt-2">
                                  <div className="flex flex-col gap-2">
                                      {Array.isArray(uploadedDocsCv) && uploadedDocsCv.length > 0 ? (
            uploadedDocsCv.map((doc) => (
                <div
                className="flex items-center justify-between p-2 border-b"
                key={doc.documentRecord?._id || doc._id}
              >
                    <div className="flex items-center">
                        <input
                    type="checkbox"
                    checked={UploadSelectedCV.some(
                      (item) =>
                        item.documentRecord?._id === doc.documentRecord?._id
                    )}
                    onChange={() => handleCVCheckboxChange(doc)}
                    className="mr-2"
                  />
                        <h5 className="text-sm font-medium">
                            {doc.documentRecord?.file_name || "Unnamed Document"}
                        </h5>
                    </div>
                    <Button
                  type="button"
                  onClick={() => handleCVDelete(doc.documentRecord?._id)}
                  className={`bg-gray-500 text-white flex items-center ${
                    deletingDocs[doc.documentRecord?._id]
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={deletingDocs[doc.documentRecord?._id]}
                >
                        {deletingDocs[doc.documentRecord?._id] ? (
                            <>
                                <svg
                        className="animate-spin h-5 w-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                                    <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                                    <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                                </svg>
                                Deleting...
                            </>
                  ) : (
                    "Delete"
                  )}
                    </Button>
                </div>
            ))
          ) : (
              <p>No documents uploaded yet.</p>
          )}
                                  </div>
                              </div>
                          </div>
                     
                      </form>
                  </CardContent>
              </Card>
          </section>
          <section>
              <MatchResult selectedCV={loadedCV} orgID={oragID} subOrgID={subOragID} selectedJD={loadedJD}></MatchResult>
          </section>
      </NestedPageLayout>
  );
}