import { assistantInstanceService, channelService } from 'api'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { Context } from 'context/GlobalState'
import { MailIcon } from 'lucide-react'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { server } from 'utils/httpsRequest'
import WebChatSetup from './WebChatSetup'
import { Combobox } from 'components/ui/combobox'

export default function AuthenticateEmail({ isOpen, setOpenEmailModal, setEmailChannels }) {

    const [newChannel, setNewChannel] = useState('')
    const [newChannelObj, setNewChannelObj] = useState({topic: '', assistantId: '', address: ''})
    const [btnLoading, setBtnLoading] = useState(false)
    const [disableSelect, setDisableSelect] = useState(false)
    const channelTypes = ['Email', 'Web Chat']

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("email-auth-success");

    const { addNewNotifcation } = useContext(Context)

    useEffect(() => {
        if (success === 'true') {
            setDisableSelect(true)
            setNewChannel('Email')
        }
    }, [])


    const handleOnSubmit = () => {

        delete newChannelObj['isAssistantDisabled']
        setBtnLoading(true)
        channelService.addChannel(newChannelObj)
        .then((res) => {
            console.log('res: ', res);

            addNewNotifcation('Channel added successfully', 'success')
            setOpenEmailModal(false)
            setEmailChannels(prev => ([...prev, res.data]))
            // clean the url form queries (email address, etc...)
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response.status === 409) {
                addNewNotifcation('Channel already exists', 'danger')
            } else {
                addNewNotifcation('Something went wrong', 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    // Check if any field is empty
    const isButtonDisabled = () => {
        return (
        newChannelObj.topic === '' ||
        // newChannelObj.assistantId === '' ||
        newChannelObj.address === ''
        );
    };


    const getType = () => {
        switch (newChannel) {
            case 'Email':
              return <EmailSetup newChannelObj={newChannelObj} setNewChannelObj={setNewChannelObj} />;
            case 'Web Chat':
                return <WebChatSetup setOpenEmailModal={setOpenEmailModal} setEmailChannels={setEmailChannels} />;
            // case 'WhatsApp':
            //   return <WhatsAppSetup />;
            // case 'Facebook':
            //   return <FacebookSetup />;
            // case 'Voice':
            //   return <VoiceSetup />;
            default:
              return <NewChannelInput newChannelObj={newChannelObj} setNewChannelObj={setNewChannelObj} channelType={null} />

        }
    }


    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Email Handling Setup</DialogTitle>
                    <DialogDescription>Let's get started by selecting channel type.</DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="flex flex-col gap-4">
                        <Label htmlFor="channel-type">
                            Channel Type
                        </Label>
                        <Select disabled={disableSelect} onValueChange={(value) => setNewChannel(value)}>
                            <SelectTrigger className="col-span-3">
                                <SelectValue placeholder="Select channel type" />
                            </SelectTrigger>
                            <SelectContent>
                                {channelTypes.map((type) => (
                                    <SelectItem key={type} value={type}>{type}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                
                {/* display email setup component dynamically */}
                {getType()}
                
                {newChannel === 'Email' && (
                    <DialogFooter className='mt-3'>
                        <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleOnSubmit} disabled={btnLoading || isButtonDisabled()}> Submit </Button>
                    </DialogFooter>
                )}

            </DialogContent>
        </Dialog>
    )
}

export const NewChannelInput = ({ newChannelObj, setNewChannelObj, channelType }) => {

    const [assistants, setAssistants] = useState([{_id: '', name: 'None'}])
    const [assistantsLoading, setAssistantsLoading] = useState(true)

    const { oragID, subOragID } = useParams()


    const handleChange = (value, type) => {
        setNewChannelObj(prev => {
            return {...prev, [type]: value}
        })
    }

      
    useEffect(() => {
        setAssistantsLoading(true)
        assistantInstanceService.getSubOrgAssistantInstance(oragID, subOragID)
        .then((res) => {
            console.log('res: ', res);
            setAssistants(prev => ([...prev, ...res.data.map(item => ({...item, name: item.title}))]))
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setAssistantsLoading(false)
        })
    }, [])

 

    return (
        <div className='flex flex-col gap-4'>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Address
                </Label>
                <Input 
                value={newChannelObj.address} 
                disabled={newChannelObj.isAssistantDisabled || !channelType} onChange={(e) => handleChange(e.target.value, 'address')} />
            </div>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Topic
                </Label>
                <Input disabled={!channelType} value={newChannelObj.topic} onChange={(e) => handleChange(e.target.value, 'topic')} placeholder='Enquires, Reservation, ...' />
            </div>
            <div className="flex flex-col gap-4">
                <Label htmlFor="channel-type">
                    Assistant 
                </Label>
                <Combobox comboboxWidth='w-full' items={assistants} setter={(value) => handleChange(value, 'assistantId')} selectedValue={newChannelObj.assistant} disabled={assistantsLoading || !channelType} />
            </div>
        </div>
    )
}

const EmailSetup = ({ newChannelObj, setNewChannelObj }) => {

    const [setupStep, setSetupStep] = useState(1)
    const [fetchEmailLoading, setFetchEmailLoading] = useState(false)
    const { oragID, subOragID } = useParams()



    const [searchParams] = useSearchParams();
    const success = searchParams.get("email-auth-success");
    const email = searchParams.get("email");

    useEffect(() => {
        if (success === 'true') {
            
            setFetchEmailLoading(true)
            channelService.getTempEmail(oragID, subOragID, email.toLowerCase())
            .then((res) => {
                console.log('res: ', res);
                // eslint-disable-next-line no-unused-vars
                const {email_auth, created, updated, organization_id, sub_organization_id, _id, __v, ...rest} = res.data
                const emailChannelObj = {
                    organizationId: organization_id,
                    subOrganizationId: sub_organization_id,
                    address: email_auth,
                    isAssistantDisabled: true,
                    type: 'Email',
                    ...rest
                }
                setNewChannelObj(prev => ({...prev, ...emailChannelObj}))
                setSetupStep(prev => prev+1)
            }).catch((error) => {
                console.log('error: ', error);
            }).finally(() => {
                setFetchEmailLoading(false)
            })
        }
    }, [])


    if (fetchEmailLoading) {
        return <h1>loading</h1>
    }

    return (
        <div className="space-y-4">
            <Label className='text-muted-foreground'>
                Let's get started by authenticating your Outlook email.
            </Label>
            <Button disabled={setupStep !== 1} onClick={() => window.location = `${server}/outlook/auth/microsoft?organizationId=${oragID}&subOrganizationId=${subOragID}`} 
            variant="outline" className="w-full">
                <MailIcon className="mr-2 h-5 w-5" />
                Authenticate with Outlook
            </Button>

            <NewChannelInput newChannelObj={newChannelObj} setNewChannelObj={setNewChannelObj} channelType={setupStep === 2 ? 'email' : null} />
        </div>
    )
}
