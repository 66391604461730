/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import { initialInputGroups, OpCodeBuilderContext, STEP_TYPES } from './OpCodeBuilderHAndC/OpCodeBuilderContext'
import { Context } from 'context/GlobalState'
import handler from './OpCodeBuilderHAndC/OpCodeBuilderHandler'
import { Label } from 'components/ui/label'
import InputValidation from 'components/InputValidation'
import { Textarea } from 'components/ui/textarea'
import InputGroups from './InputGroups'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from 'components/ui/dialog'
import { AppLoader } from 'components/LoaderSpinner'
import { ScrollArea } from 'components/ui/scroll-area'
import { Step, StepsContainer } from 'components/NewDesignComponents/VerticalSteps'
import { Button } from 'components/ui/button'
import { Card, CardContent } from 'components/ui/card'
import { opcodeService } from 'api/services/BEX/opcode.service'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'

const OpCodeBuilderUpdate = ({ openModal, setOpenModal, opCode}) => {
    const [opCodeName, setOpCodeName] = useState("");
    const [opCodeDescription, setOpCodeDescription] = useState("");
    const [inputGroups, setInputGroups] = useState(initialInputGroups);
    const [stepTypes, setStepTypes] = useState([STEP_TYPES[0].name]);
    const [opType, setOpType] = useState("");
    const [userInput, setuserInput] = useState("")
    const [fetchDetailsLoading, setFetchDetailsLoading] = useState(true)
    const [opCodeDetails, setOpCodeDetails] = useState(null)
    const [isUpdating, setIsUpdating] = useState(false);
    const { orgId, subOrgId, validateSteps, constantStepTypes, actions } = useContext(OpCodeBuilderContext)
    const { addNewNotifcation } = useContext(Context);
    const parentId = window.crypto.randomUUID()
    console.log(opCode)

    useEffect(() => {
        handleFetchDetails()
    }, [opCode])

    useEffect(() => {
        if(opCodeDetails) {
            //     const updatedInputGroups = opCodeDetails?.steps?.slice(0)?.map(step => {
            //         if (step.type === "LLM"){
            //             return {
            //                 step_id: window.crypto.randomUUID(),
            //                 step_type: step.type,
            //                 unique_name: step.params.unique_name,
            //                 model_type: step.params.model_type,
            //                 registry_type: null,
            //                 registry_key: null,
            //                 fixed_inputs: {},
            //                 dynamic_inputs: extractKeys(step.params.query),  // This will hold any dynamic inputs to be connected
            //                 steps:null,
            //                 output: { Output: "", Output_map: null },
            //                 next_step: {branch_name:[]},
            //                 accumulate_output: true,
            //                 is_first_step: false 
            //             }
            //         }
        
            //         if (step.type === "Non-LLM"){
            //             return {
            //                 step_id: window.crypto.randomUUID(),
            //                 step_type: step.type,
            //                 registry_type: "api",
            //                 registry_key: "RAG_API",
            //                 fixed_inputs: {
            //                     "organization_id": step.params.api_details.payload.organization_id,
            //                     "sub_organization_id": step.params.api_details.payload.sub_organization_id,
            //                     "collection_id": step.params.api_details.payload.collection_id,
            //                     "use_rerank": step.params.api_details.payload.use_rerank,
            //                     "top_k": step.params.api_details.payload.top_k,
            //                     "top_n": step.params.api_details.payload.top_n,
            //                     "offset": step.params.api_details.payload.offset || 0,
            //                     "filters": step.params.api_details.payload.filters
            //                 },
            //                 dynamic_inputs: {
            //                     "query_text": extractKeys(step.params.api_details.payload.query_text)[0]
            //                 },
            //                 output_var: step.output.results,
            //                 next_step: null, // Will be assigned later when connecting steps
            //                 accumulate_output: true
            //             }
            //         }
        
            //         if (step.type === "Non-LLM - BeX Insights"){
            //             console.log(step)
            //             return {
            //                 step_id: window?.crypto?.randomUUID(),
            //                 step_type: step.type,
            //                 registry_type: "function",
            //                 registry_key: "BeXInsights_Search",
            //                 fixed_inputs: {},
            //                 dynamic_inputs: extractKeys(step?.params?.api_details?.payload),
            //                 output_var: step.output.results,
            //                 next_step: null, // Will be assigned later when connecting steps
            //                 accumulate_output: true
            //             }
            //         }
                    
            // });
            console.log("run")
            handleTypeChange(opCodeDetails.type)
            if(opCodeDetails.identification_step){
                const newInputGroups = [opCodeDetails.identification_step, ...opCodeDetails.steps]
                setInputGroups([{...opCodeDetails.identification_step, step_id:window.crypto.randomUUID()},
                     ...opCodeDetails.steps])
                setStepTypes(newInputGroups?.map(step => step.step_type))
            }else {
                setInputGroups(opCodeDetails.steps)
                setStepTypes(opCodeDetails?.map(step => step.step_type))
            }
        }
    }, [opCodeDetails])

    // const extractKeys = (str) => {
    //     const regex = /output\['(\w+)'\]/g;
    //     let match;
    //     const keys = [];
    
    //     while ((match = regex.exec(str)) !== null) {
    //         keys.push(match[1]);
    //     }
    
    //     return keys;
    // }

    const handleFetchDetails = async () => {
        setFetchDetailsLoading(true);
        try {
            const res = await opcodeService.getOpCodeById(orgId, subOrgId, opCode?.opcode_id);
            console.log(res)
            if (res?.data) {
                setOpCodeDetails(res?.data);
                setOpCodeName(res.data?.opcode_id)
                setOpCodeDescription(res.data?.description)
                setOpType(res?.data?.type)
            }
        } catch (error) {
            console.error("Error getting opcode by unique names:", error);
            addNewNotifcation("Error getting Opcode details. Please try again.", "danger");
        } finally {
            setFetchDetailsLoading(false);
        }
    };

    const handleUpdate = async () => {
        // Validate the opCodeName first
        let { isValid: isOpCodeValid, message: opCodeMessage } = handler.validateOpCodeName(opCodeName.trim());
        if (!isOpCodeValid) {
            addNewNotifcation(opCodeMessage, "warning");
            return;
        }

        if (inputGroups.length < 2){
            addNewNotifcation("Please add at least one step to the opcode other than Identification.", "warning");
            return;
        }

        if(opType === "ChatOp" && inputGroups[inputGroups.length - 1].step_type !== "LLM-Stream"){
            addNewNotifcation("In type chat opcode, the final step must be LLM-Stream.", "warning");
            return;
        }

        let { isValid: areStepsValid, message: stepsMessage } = validateSteps(inputGroups);
        if (!areStepsValid) {
            addNewNotifcation(stepsMessage, "warning");
            return;
        }

        const opCodeData = {
            org_id: orgId,
            sub_org_id: subOrgId,
            opcode_id: opCodeName,
            description: opCodeDescription,
            identification_step:inputGroups[0],
            steps:inputGroups.slice(1)
        };

        console.log(opCodeData)
        
        try {
            setIsUpdating(true)
            const res = await opcodeService.updateOpCode(opCodeData);
            console.log(res);
            addNewNotifcation("Opcode updated successfully.", "success");
            setOpenModal(false)
           
    
        } catch (error) {
            console.error("Error updating opcode:", error);
            addNewNotifcation("Opcode cannot be updated. Please try again.", "danger");
        } finally {
            setIsUpdating(false)
        }
    }

    const checkOpcodeName = () => {
        return (!handler.validateOpCodeName(opCodeName.trim())?.isValid || !opType || !opCodeDescription.trim())
    }

    const handleTypeChange = (value) => {
        setOpType(value)
        actions({type:"SET_CONSTANT_TYPES", 
            payload:value === 'AutoOp'? [...STEP_TYPES.slice(0, 2), ...STEP_TYPES.slice(3)] : [...STEP_TYPES.slice(0, 1), ...STEP_TYPES.slice(2)]})
        setInputGroups(value === 'AutoOp'? inputGroups.filter(step=> step.step_type !== "LLM-Stream") : inputGroups.filter(step=> step.step_type !== "LLM"))
        setStepTypes(value === 'AutoOp'? stepTypes.filter(type => type !== "LLM-Stream") : stepTypes.filter(type => type !== "LLM"))
    }

    const handleMoveStep = (dragIndex, hoverIndex, steps, parentIdContainer) => {
        try{
            if(parentId === parentIdContainer){
                let newInputGroups = []
                let newTypes = []
                // let i = 0
                steps?.map(step => {
                    if(step.props.indicator !== "S" && step.props.indicator !== "1" && step.props.indicator !== "E"){
                        const foundInput = inputGroups.find(inputGroup => inputGroup.step_id === step.props.step_id)
        
                        // const newDynamicInputs = {
                        //     ...foundInput, dynamic_inputs: foundInput.registry_type === 'api'? 
                        //     {"query_text":i === 0? "real_time_data" : ""} : i === 0? ["real_time_data"] : [],
                        // }
                        // const newInputGroup = foundInput.step_type === 'LLM' || foundInput.registry_type === 'api' 
                        // || foundInput.registry_type === 'function'? newDynamicInputs : foundInput
        
                        newInputGroups.push(foundInput)
        
                        newTypes.push(foundInput.step_type)
                        // i++
                    }
                })
                setInputGroups(newInputGroups)
                setStepTypes(newTypes)
            }
        }catch(err) {
            console.log(err)
        }
    }

    return (
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="max-w-[1100px] w-full p-0 bg-white rounded-lg shadow-lg">
                <ScrollArea className='max-h-[650px] p-4'>
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Update Opcode</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Update an existing opcode.</DialogDescription>
                    </DialogHeader>
                    
                    {fetchDetailsLoading && <AppLoader/>}

                    {!fetchDetailsLoading && ( 
                        opCodeDetails?(
                            <StepsContainer onMoveStep={handleMoveStep} className={'w-full'}>
                                <Step
                                indicator="S"
                                canAddBefore={false}
                                canAddAfter={false}
                                isDraggable={false}
                                canDelete={false}
                                isDisabled={false}
                                itemId={parentId}
                                title="Opcode Form"
                                >
                                    <div className="space-y-4 w-full">
                                        <div>
                                            <Label>Opcode ID <span className='text-gray-400'>(Required)</span></Label>
                                            <InputValidation
                                                value={opCodeName}
                                                onChange={(e) => setOpCodeName(e.target.value)}
                                                placeholder="Enter a unique name for this opcode"
                                                validation={handler.validateOpCodeName}
                                                disabled
                                            />
                                        </div>
                                        <div>
                                            <Label>Opcode Type <span className='text-gray-400'>(Required)</span></Label>
                                            <Select onValueChange={handleTypeChange} 
                                            id="opcode_type" 
                                            className="w-full" value={opType}
                                            disabled
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select opcode type" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={"AutoOp"}>{"Auto Opcode"}</SelectItem>
                                                    <SelectItem value={"ChatOp"}>{"Chat Opcode"}</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        <div>
                                            <Label>Opcode Description <span className='text-gray-400'>(Required)</span></Label>
                                            <Textarea 
                                            value={opCodeDescription} 
                                            className="min-h-[80px]"
                                            onChange={(e) => setOpCodeDescription(e.target.value)} 
                                            placeholder="Enter a description for this opcode" 
                                            />
                                        </div>
                                    </div>
                                </Step>
                                <InputGroups 
                                 inputGroupsState={{inputGroups, setInputGroups}} 
                                 stepTypesState={{stepTypes, setStepTypes}} 
                                 userInputState={{userInput, setuserInput}}
                                 otherProps={{parentId, constantStepTypes, orgId, subOrgId, opType, checkOpcodeName}}
                                />   
                                <Step
                                indicator="E"
                                canAddBefore={true}
                                canAddAfter={false}
                                isDraggable={false}
                                canDelete={false}
                                itemId={parentId}
                                isDisabled={checkOpcodeName()}
                                title="Opcode Summary and Update"
                                >
                                    <div className='flex flex-col w-full'>
                                        <Card className="mb-4 w-full">
                                            <CardContent className="pt-6">
                                                <h3 className="text-lg font-semibold mb-2">OpCode Summary</h3>
                                                <p><strong>Name:</strong> {opCodeName}</p>
                                                <p><strong>Description:</strong> {opCodeDescription || 'No description provided'}</p>
                                                <p><strong>Number of Steps:</strong> {inputGroups.length}</p>
                                                <h4 className="font-semibold mt-2 mb-1">Step Types:</h4>
                                                <ul className="list-disc pl-5 flex gap-6">
                                                    {stepTypes.map((type, index) => (
                                                        <li key={index}>{type}</li>
                                                    ))}
                                                </ul>
                                            </CardContent>
                                        </Card>
                                        <Button onClick={handleUpdate} disabled={isUpdating}>
                                            {isUpdating? "Updating..." : "Update"}
                                        </Button>
                                    </div>
                                   
                                </Step>
                            </StepsContainer>
                        ) : (
                            <p className="text-gray-500 text-center">
                                No opcode found with this id "{opCode}"
                            </p>
                        ))
                    }
                </ScrollArea>
            </DialogContent>
        </Dialog>
    )
}
export default OpCodeBuilderUpdate