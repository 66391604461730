import { Label } from 'components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import React from 'react'

const NextStep = ({inputGroup, index, inputGroups, setInputGroups, getNextSteps}) => {

    const handleNextStepChange = (value) => {
        const newInputGroups = inputGroups.map((group) => 
            group.step_id === inputGroup.step_id? {...group, next_step: value !== "-"? [value]: []} : group) 
        setInputGroups(newInputGroups)
    } 

    return (
        <>
            <Label htmlFor={`param-${index}`}>Next Step</Label>
            <Select
                onValueChange={(value) => handleNextStepChange(value)}
                value={inputGroup?.next_step[0] || ""}
            >
                <SelectTrigger className="w-full bg-white">
                    <SelectValue placeholder="Select input" />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem key={"default"} value={"-"}>
                        None
                    </SelectItem>
                    {getNextSteps(index).map((item) => (
                        <SelectItem key={item.nextStep.label} value={item.nextStep.value}>
                            {item.nextStep.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </>
    )
}

export default NextStep